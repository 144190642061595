import { Component, OnInit, ChangeDetectorRef, HostListener } from "@angular/core";
import { AppState } from "../app.service";
import { MatDialog } from "@angular/material/dialog";
import { RoutineService } from "../daily-content-v2/routine.service";
import { AlertEndRoutineComponent } from "../alert-end-routine/alert-end-routine.component";
import { ToastrService } from "ngx-toastr";
import { AlertCalendarOpenComponent } from "../alert-calendar-open/alert-calendar-open.component";
import { CordovaService } from "../cordova.service";
import * as moment from 'moment'
import { AlertWebactionCalendarComponent } from "../alert-webaction-calendar/alert-webaction-calendar.component";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { AlertWebCalendarComponent } from "../alert-web-calendar/alert-web-calendar.component";
import { SpinnerService } from "../spinner.service";
import { ToasterService } from "../toaster.service";
import { CalendarService } from "../calendar.service";

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime : Date,
  frequency: string
}


@Component({
  selector: "app-end-routine",
  templateUrl: "./end-routine.component.html",
  styleUrls: ["./end-routine.component.scss"],
})
export class EndRoutineComponent implements OnInit {

  

  currentPath: any;
  listOfRoutines: any[];
  currentUser: any;
  hasNativeCalendar: boolean
  recurrenceInterval:string
  calendarParams: CalendarParams
  checklist:any[]
  isMobile: boolean

  constructor(
    private appstate: AppState,
    private dialogRef: MatDialog,
    private routineService: RoutineService,
    private cdRef: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private toastrService: ToasterService,
    private homeService: HomeV5Service,
    private cordovaService: CordovaService,
    private calendarService: CalendarService,
  ) {
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
  }

  ngOnInit() {
    this.currentPath = this.appstate.get("currentPath");
    this.currentUser = this.appstate.get("currentUser");
    this.isMobile = this.appstate.get('isMobile')
   
    this.getRoutineList();
    console.log(this.listOfRoutines);
  }

  checkBoxChange(completed: boolean, routine: any) {

    console.log('completed 1',completed);

    if (!routine.completed_routine && routine.isConvertedToRoutine && !completed) {
      const dialog = this.dialogRef.open(AlertEndRoutineComponent);
      dialog.afterClosed().subscribe((result) => {
        console.log('routine 1',routine);
        console.log('routine 1',routine.routine_id);

        if (result == "confirm") {
          this.spinnerService.on();
          this.routineService
            .endRoutine(routine.routine_id, this.currentPath.id, this.currentUser.id, routine.id)
            .subscribe(
              (response) => {
                console.log("Response for routine ", response);
                this.getRoutineList();
                this.appstate.set("isUpdatedCommitment",true)
                this.appstate.set("isUpdatedMyCommitments",true)
              },
              
              (error) => {
                console.log("Error for feedback ", error);
              }
            );
        }
        else{
         this.ngOnInit()
        }
      });
    }else if(completed) {
      const dialog = this.dialogRef.open(AlertCalendarOpenComponent);
      dialog.afterClosed().subscribe((result) => {
        if(result == 'confirm'){
          this.spinnerService.on();
          this.routineService
            .createNewRoutine(routine.id,this.currentPath.id,this.currentUser.id)
            .subscribe(
              (response) => {
                console.log("Response for routine 2", response);
                let status = response.status
                if(status===true){
                  this.getRoutineList();
                  this.appstate.set("isUpdatedCommitment",true)
                  this.appstate.set("isUpdatedMyCommitments",true)
                  
                  if(this.hasNativeCalendar===false){
                    this.prepareCalendarParams("Comet Pro Habit : "+routine.title,this.innerHtmlToRoutinesData(routine.text),routine.routinelen,routine.eventlen)
                    console.log('routineClendarParams',this.calendarParams)

                    this.homeService.setCalendarData(this.calendarParams)
                    const dialogRef = this.dialogRef.open(AlertWebCalendarComponent)
                    dialogRef.afterClosed().subscribe((result) => {
                    })
                  }  

                }
              },
              (error) => {
                console.log("Error for feedback ", error);
              }
            );
          if(this.hasNativeCalendar){
            this.prepareCalendarParams("Comet Pro Habit : "+routine.title,this.innerHtmlToRoutinesData(routine.text),routine.routinelen)
            this.createRoutineEvent(routine)
            
          }else{
            this.ngOnInit()
          }
        }else {
          this.ngOnInit()
        }
      })
      

    } 
  }

  getRoutineList() {
    const requestData = {
      user_id: this.currentUser.id,
      path_id: this.currentPath.id,
    };

    if (requestData.user_id == 0 && requestData.path_id == 0) {
      return true;
    } else {
      this.routineService.getListRoutines(requestData).subscribe(
        (response) => {
          this.listOfRoutines = response.data;
          console.log("routines", this.listOfRoutines);
          this.appstate.set("listOfRoutines", this.listOfRoutines);
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log("Error for routines ", error);
        }
      );
    }
  }

  prepareCalendarParams(title:any,msg: String,routinelen:any = null,eventlen:string =null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, 
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }

  createRoutineEvent(habit) {
    const success = function(message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function(message) { console.error('Error: ' + message) }
    this.recurrenceInterval=''
    try{
      if(typeof habit.eventlen === 'string'){
        this.recurrenceInterval = habit.eventlen
      }
     }catch(o_0){

     }
    

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success,error);

    console.log("Calendar List ",calendarList);
    
    const daysCount = habit.routinelen  // LUMBA-1399
    calOptions.recurrence = this.recurrenceInterval.toLowerCase(); // supported are: daily, weekly, monthly, yearly
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
       this.calendarParams.endDate,
       calOptions,
       success,
       error
      );
  }

  innerHtmlToRoutinesData(habit:string){

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    console.log("String of links ",cleanText+"\n\n"+arrayOfUrls);
    return cleanText+"\n\n After you have completed this action, mark it complete in the Comet Pro app by clicking here: https://cometpro.page.link/rM3L";
  }


  setCheckedCalendar(id){

    var listOfRoutines = this.appstate.get("listOfRoutines")
    var temp = this.appstate.get("listOfRoutines").find(item => item.routine_id === id)
    temp['checked'] = true;
    this.appstate.set("listOfRoutines", listOfRoutines)
    console.log('checked', listOfRoutines);
    return listOfRoutines
  }


  @HostListener('click', ['$event'])
  onLinkClick(e) {
    console.log("Link data ",e);

    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
     
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
     
        window.open(e.target.href, '_blank')
      }
    }
  }
  
}
