import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { heartBeatAnimation } from 'angular-animations';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';
import { I18n } from '../i18n.service';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { SessionService } from '../session/session.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'app-completed-review-modal',
  templateUrl: './completed-review-modal.component.html',
  styleUrls: ['./completed-review-modal.component.scss'],
  animations:[
    heartBeatAnimation({duration: 20000, delay: 0})
  ]
})
export class CompletedReviewModalComponent implements OnInit {

  currentUser: User
  currentDay: number
  currentPath: Path
  postText:any = ''
  userIds: any = []
  complexItems: any
  animation = 'heartBeat';
  animationState = false;
  @Input() currentHabit: any
  isTextAdded: boolean
  isMobile;
  constructor(
    public modalCtrl: ModalController,
    private appState: AppState,
    public route: ActivatedRoute,
    private homeService: HomeV5Service,
    private tostrService: ToasterService,
    private cdRef: ChangeDetectorRef,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private homeUIService: HomeUiService,
    private i18n: I18n,
  ) { 
    this.currentUser = this.appState.get('currentUser')
    this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get('currentDay')
    this.userIds = this.appState.get("tags_ids")
    this.complexItems = this.appState.get("userlist")
    this.isMobile = this.appState.get('isMobile');
    // if (this.currentDay != 0) {
    //   this.currentHabit = this.findLastHabit(this.currentPath.habits, this.currentDay)
    // }

  }

  ngOnInit() {
    this.animate()

  }

  post(){
    mixpanel.track('habit complited share thoughts', {'post': this.postText})
    try {
      // let todaysHabit
      

      var list_of_tags_ids = []
      list_of_tags_ids = this.sessionService.getUserTAGs(this.postText,this.complexItems)    
      this.appState.set("tags_ids",list_of_tags_ids)

      const requestData = {
        'user_id': this.currentUser.id,
        'habit_id': this.currentHabit?.id,
        'rcomment': this.postText,
        'userIds': this.appState.get("tags_ids"),
        'rating': 0,
      }
      this.appState.set("tags_ids", list_of_tags_ids);
      localStorage.setItem("postText", this.postText);
      //this.spinnerService.on();
      if(requestData.rcomment==''){
        //this.spinnerService.off();
      }else{
        this.homeService.giveFeedback(requestData).subscribe(
          (response) => {
            this.tostrService.success(this.i18n.t('Action Completed!'))
            this.appState.set("isCommented",true)
            this.close()
            this.cdRef.detectChanges()
          },
          (error) => {
            this.tostrService.error(this.i18n.t('Something went wrong..! Try Again'))
          }
        )
      }
    } catch (error) {
      console.log('error Action', error);
    }
  }

  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtrl.dismiss(closeModal);
  }

  animate() {
    this.animationState = false;
    setTimeout(() => {
      this.animationState = true;
    }, 1);
  }

  scrollTo(event) {
   // this.isTextAdded = true
  }
  updateTextState(event: Event) {
    const target = event.target as HTMLTextAreaElement;
    this.isTextAdded = target.value.trim().length > 0;
  }
}
