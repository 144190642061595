import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgModel, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { AlertProfilePictureComponent } from 'src/app/alert-profile-picture/alert-profile-picture.component';
import { AppState } from 'src/app/app.service';
import { I18n } from 'src/app/i18n.service';
import { NativeService } from 'src/app/services/native.service';
import { SessionService } from 'src/app/session/session.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToasterService } from 'src/app/toaster.service';
import { User } from 'src/app/user/user.model';
import { UserService } from 'src/app/user/user.service';
import { CustomValidators } from './custom-validators';
import { HomeUiService } from '../../new-design/home-ui.service';

export interface UpdateUser {
  [updateUser: string]: any
}
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  public frmSignup: FormGroup;
  @ViewChild('lName') lName: NgModel
  @ViewChild('confirmPassword') confirmPassword: NgModel
  user: User
  dataRefresher: any
  isNewPhoto: boolean | true
  isPhotoUploaded: boolean;
  label: any;
  userUpdate: UpdateUser
  email: any
  password: any
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  isMobile: boolean;
  confirmPasswordLabel: string
  passwordLabel: string
  isMobileWebIos = false;
  isMobileWeb=false;
  firstName: string
  lastName: string
  userEmail: string
  pathRegistration

  constructor(private fb: FormBuilder,
    private appState: AppState,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private modalController: ModalController,
    private userService: UserService,
    private sessionService: SessionService,
    private toastrService: ToasterService,
    public platform: Platform,
    private spinnerService: SpinnerService,
    private native: NativeService,
    public homeUiService: HomeUiService,
    private i18n: I18n,) {
      this.confirmPasswordLabel = this.i18n.t('Confirm password')
      this.passwordLabel = this.i18n.t('Password')
      this.frmSignup = this.createSignupForm();
      this.user = this.appState.get('currentUser')
      this.isMobile = appState.get("isMobile");
      this.pathRegistration = this.appState.get("pathRegistration");
      this.firstName = this.user.firstName
      this.lastName = this.user.lastName
      this.userEmail = this.user.email
      
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
   }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(7)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  async chooseOption() {
    this.stopInterval()
    const fileResult = await this.native.showActionSheetForFiles();
    this.spinnerService.on();
    if (fileResult) {
      this.refresh();
      this.uploadPhoto(fileResult);
    }else{
      this.spinnerService.off();
    }
   
  }
  fileChangeEvent(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.spinnerService.on();
    console.log('file event', files[0]);
    this.uploadPhoto(files[0]);
  }
  
  uploadPhoto(file: any) {
    const myFormData = new FormData();
    //myFormData.append('file', file.blob, file.blob.name);
    if (this.isMobile) {
      myFormData.append('file', file.blob, file.blob.name);
    } else {
      myFormData.append('file', file, file.name);
    }
    this.userService.postProfilePic(myFormData).subscribe((result) => {
      this.spinnerService.off();
      // this.toastrService.success(this.i18n.t('Your profile has been saved successfully.', 'bottom'))
      console.log('result', result);
      this.sessionService.url = result['data']['url']
      this.user.imageUrl = this.sessionService.url
      this.uploadImage()
    }, error=>{
      this.spinnerService.off();
      this.toastrService.success(this.i18n.t(error))
    })
  }

  stopInterval() {
    if (this.dataRefresher) {
      clearInterval(this.dataRefresher)
    }
  }

  refresh() {
    this.dataRefresher =
      setInterval(() => {
        this.user.imageUrl = this.sessionService.url
      }, 500);
  }

  uploadImage() {
    let profileUploadDay;
    if (this.user.imageUrl) {
      this.spinnerService.on();
      profileUploadDay = this.user.profileUploadedDay;
      delete this.user.profileUploadedDay;
      delete this.user.userBgColor;
      this.userService.save(this.user).subscribe((response) => {
        this.userUpdate = response.data
        const updatedUser = new User(this.userUpdate.updateUser);
        this.user = updatedUser;
         //this.user.profileUploadedDay = profileUploadDay;
        this.label = "";
        this.appState.set("currentUser", updatedUser);
        localStorage.setItem('userid', updatedUser.id.toString());
        this.spinnerService.off();
        this.isPhotoUploaded = true;
        this.toastrService.success(this.i18n.t("Profile Picture Added!"));
        console.log("update response", response);
      });
    
    }
  }
  gotoNextField(nextElement, field=null) {
    if(field === 'password' && this.frmSignup.controls.password.status === "INVALID"){
      console.log('password not validated');
      this.toastrService.warning(this.i18n.t('Please enter a valid password'))
    }
    nextElement.setFocus();
  }
  updateProfile() {
    let profileUploadDay;
    if ((this.user.password?.length>0 &&this.user.password?.trim()?.length>0)&& !this.sessionService.validatePassword(this.user.password)) {
     if(this.frmSignup.controls.password.status === "INVALID") {
        this.toastrService.warning(this.i18n.t('Please enter a valid password'))
        return
      }
    }
    if (this.user.password == this.user.passwordConfirmation) {
      this.spinnerService.on()
      profileUploadDay = this.user.profileUploadedDay
      delete this.user.profileUploadedDay;
      delete this.user.userBgColor;
      this.userService.save(this.user).subscribe(response => {
        this.userUpdate = response.data
        const updatedUser = new User(this.userUpdate.updateUser);
        this.user = updatedUser
       // this.user.profileUploadedDay = profileUploadDay;
        this.label = ''
        this.appState.set('currentUser', updatedUser)
        this.spinnerService.off()
        this.isPhotoUploaded = true
        this.toastrService.success(this.i18n.t('Profile updated!'))
        this.modalController.dismiss();
        // window.location.reload();
      }, error=>{
        this.spinnerService.off();
        this.toastrService.error(this.i18n.t(error))
      })

    } else {
      this.toastrService.warning(this.i18n.t('Password is not matched'))
    }
  }

  close() {
    this.modalController.dismiss();
    const userData = {
      firstName: this.firstName,
      lastName: this.lastName,
      userEmail: this.userEmail
    }
    this.appState.set('userData',userData)
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  refreshComponent() {
    
  }
}
