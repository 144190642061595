import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StreamingAudioOptions, StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { environment } from 'src/environments/environment';
import { AlertEditNoteComponent } from '../alert-edit-note/alert-edit-note.component';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { LibraryService } from '../my-library/library.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { MatDialog } from '@angular/material/dialog';
import { MediaObject } from '@ionic-native/media/ngx';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { AlertController, AlertInput, ModalController, Platform } from '@ionic/angular';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { I18n } from '../i18n.service';

type AudioFiles = {
  title: string;
  url: string;
  cover: string;
};

@Component({
  selector: 'app-full-screen-audio',
  templateUrl: './full-screen-audio.component.html',
  styleUrls: ['./full-screen-audio.component.scss'],
})
export class FullScreenAudioComponent implements OnInit {
  @Input() data: any;
  presignedUrl: any
  isMobile: any
  singleDocument;
  pathName: any;
  player = new Audio;
  duration: any = -1;
  position: any = 30;
  curr_playing_file: MediaObject;
  audioList: AudioFiles[] = [];
  libraryPosts: any
  journalNote: string
  isEditNote: boolean
  stepNumber: number
  isCasualDocument: boolean
  journalPosts;
  pdfSrc: any;

  play() {
    console.log("play");
  }
  constructor(private socialSharing: SocialSharing,
    private appState: AppState,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef,
    private cordovaService: CordovaService, 
    private dialog: MatDialog, 
    private alertController:AlertController,
    private i18n: I18n,
    private toasterService: ToasterService,
    private libraryService: LibraryService, 
    private modalController: ModalController,
    private router: Router, 
    private streamingMedia: StreamingMedia,
    public platform: Platform,
    private transfer: FileTransfer, 
    private file: File,) {
    this.libraryPosts = this.appState.get('journalPost')
    this.isCasualDocument = this.appState.get('isCasualDocument')
    this.journalPosts = this.data;
    this.isMobile = this.appState.get('isMobile');
    this.singleDocument = this.appState.get('singleDocument');
    this.presignedUrl = environment.preSignedUrl
    console.log("presignedt", this.presignedUrl);
    this.pathName = this.appState.get('journalPathName')
  
    if (this.libraryPosts) {
      if (this.libraryPosts?.questions[0]) {
        this.stepNumber = this.libraryPosts?.questions[0].startDay
      } else if(this.libraryPosts?.documents[0]){
        this.stepNumber = this.libraryPosts?.documents[0].startDay
      } else if(this.libraryPosts?.contentSurveys[0]){
        this.stepNumber = this.libraryPosts?.contentSurveys[0].startDay
      } else if(this.libraryPosts?.habits[0]){
        this.stepNumber = this.libraryPosts?.habits[0].startDay
      } else if(this.libraryPosts?.inoutScreens[0]){
        this.stepNumber = this.libraryPosts?.inoutScreens[0].startDay
      }
    }
  }

  ngOnInit() {
    this.journalPosts = this.data;
    this.player.src = this.journalPosts.link;
    this.duration = this.player.duration;
    const a1: AudioFiles = { url: this.journalPosts.link, title: this.journalPosts.title, cover: this.journalPosts.imageUrl };
    this.audioList.push(a1);
  }

  playRemoteAudio() {
    this.player.getAttribute.length
    let options: StreamingAudioOptions = {
      successCallback: () => { console.log('Audio played') },
      errorCallback: (e) => { console.log(e) },
      bgImage: this.journalPosts.imageUrl
    };
    this.streamingMedia.playAudio(this.journalPosts.link, options);
  }

  close() {
    this.appState.set('singleDocNote', undefined)
    localStorage.removeItem('journalNote')
    console.log('this.isCasualDocument :', this.isCasualDocument);
    this.appState.set('isDocumentClosed',true);
    if (this.isCasualDocument) {
      this.appState.set('isCasualDocument', undefined) 
    }
    this.modalController.dismiss();
  }
  getNoteContent(post){
    if (post) {
      let note = post
      var b = JSON.stringify(note);
      let testNote = b.replace(/\\/g, '');
      let testNote1 = testNote.replace(/[\[\]']+/g, '')
      this.journalNote = testNote1.replace(/"/g, '');
      console.log('journalNote',this.journalNote);
    }
  }

  async openNotesModal() {
    if (localStorage.getItem('journalNote')) {      
      this.appState.set('singleDocNote', localStorage.getItem('journalNote'))
    } else {
      this.appState.set('singleDocNote', this.journalPosts?.notes)
    }

    let note = this.appState.get('singleDocNote')
    this.getNoteContent(note);  
    const alert =await this.alertController.create({
      header: this.i18n.t("Notes"),
      inputs:[
        {
          type:'textarea',
          name:'textareaValue',
          value:this.journalNote,
          cssClass:'alert-textarea'
        },
      
      ],
      buttons: [
        {
          text:this.i18n.t('Save'),
          cssClass:'custom-alert',
          handler:(alertData: AlertInput)=>{
            let noteText;
            noteText = alertData['textareaValue'];

            localStorage.setItem('journalNote', alertData['textareaValue']);
            this.appState.set('singleDocNote', noteText)
            this.journalNote = noteText
            this.saveNote()
          }
        }
      ]
    })
    await alert.present();
  }

  share(event, document) {
    event.stopPropagation()
    let isPresignedURL = document.link.includes(this.presignedUrl)
    if(this.isMobile){
      try {
        this.downloadFile(document.link)
      } catch (error) {
        console.log('error :', error);
      }
    }else{
      this.openMailTo(isPresignedURL, document.title, document.link)
    }
  }

  downloadFile(url) {
    if (url.includes(".pdf")) {
      const transfer = this.transfer.create();
      let path = null;
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];
      if (this.platform.is("ios")) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.dataDirectory;
      }
      transfer.download(url, this.file.dataDirectory + fileName).then((entry) => {
        this.pdfSrc = entry.toURL()
        console.log('this.pdfSrc :', this.pdfSrc);
        if (this.pdfSrc) {
          this.socialSharing.share("", "", this.pdfSrc, "")
            .then((entries) => {
              console.log('success ' + JSON.stringify(entries));
            })
            .catch((error) => {
              console.log('error ' + JSON.stringify(error));
            });
        }
      }, (error) => {
        console.log('download failed: ', error);
      });
    } else {
      this.socialSharing.share("", "", "", url)
        .then((entries) => {
          console.log('success ' + JSON.stringify(entries));
        })
        .catch((error) => {
          console.log('error ' + JSON.stringify(error));
        });
    }
  }

  openMailTo(isPresignedURL, subject, body) {
    if (isPresignedURL) {
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.')

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.'))

      return true
    } else {
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body)

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body))

      return true
    }
  }

  editNote(){
    this.isEditNote = true
  }

  saveNote(){
    this.isEditNote = false
    let postId
    if (this.isCasualDocument) {
      if(this.journalPosts || this.libraryPosts){
        postId = this.journalPosts?this.journalPosts?.id: this.libraryPosts.id

      }
      else {
        postId = this.singleDocument?.id
      }
      const requestData = {
        'text': [this.journalNote],
        'pathId': null,
        'contentSurveyId': null,
        'habitId': null,
        'pollId': null,
        'documentId': postId,
        'questionId': null,
        'inoutScreenId': null,
        'routineInfoId': null,
        'screenId': null
      }
      this.libraryService.save(requestData).subscribe((response) => {
        console.log('response :', response);
        this.toasterService.success(this.i18n.t('Note saved.'))
      },
        (error) => {
          console.log('error :', error);
        })
    } else {
      postId = this.libraryPosts.id
      const requestData = {
        id: postId,
        text: this.journalNote
      }
      this.libraryService.editNote(requestData).subscribe(
        (response) => {
          console.log('response :', response);
          this.toasterService.success(this.i18n.t('Note saved.'))
        },
        (error) => {
          console.log('Error for feedback ', error)
        }
      )
    }
    
  }
  
}
