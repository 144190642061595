import { Injectable, NgZone } from "@angular/core";
import { Subject, BehaviorSubject, Observable, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import { AppState } from "./app.service";
import { SpinnerService } from "./spinner.service";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

declare const navigator: any;

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable()
export class CordovaService {
  private resume: BehaviorSubject<boolean>;
  inAppBrowserRef;
  constructor(private zone: NgZone, private appState: AppState, private spinnerService: SpinnerService,
    private inAppBrowser: InAppBrowser) {
    this.resume = new BehaviorSubject<boolean>(null);
    fromEvent(document, "resume").subscribe((event) => {
      this.zone.run(() => {
        this.onResume();
      });
    });


  }

  get cordova(): any {
    return _window().cordova;
  }

  public setNameToGA(screenName: String) {
    if (this.cordova) {
      console.log("In a GA cordova ");

      try {
        console.log("Completed a GA cordova 1");
        _window().cordova.ga.trackView(screenName);
      } catch (error) { }

      console.log("Completed a GA cordova ");
    }
  }

  get onCordova(): boolean {
    return !!_window().cordova;
  }

  public hideSplashScreen() {
    if (this.onCordova && navigator && navigator.splashscreen) {
      navigator.splashscreen.hide();
    }
  }

  public isIOS(): boolean {
    return this.onCordova && this.cordova.platformId === "ios";
  }

  public isAndroid(): boolean {
    return this.onCordova && this.cordova.platformId === "android";
  }

  public returnPlatform() {
    if (this.onCordova) {
      return this.cordova.platform;
    } else {
      return "Null platform";
    }
  }

  public onResume(): void {
    this.resume.next(true);
  }

  public openMail(url: string) {
    if (this.onCordova)
      this.inAppBrowser.create(url, "_system", "hidden=yes,location=yes");
  }

  public openBrowserLinkVideo(url: string) {
    var path = this.appState.get("currentPath");

    try {
      if (path.enabledAttributes.secure_links) {
        console.log("openBrowserLinkVideo secure_links true");
        console.log(
          "openBrowserLinkVideo Includes",
          url.includes("1st90-api-images")
        );

        if (this.cordova && url.includes("1st90-api-images")) {
          this.InAppBrowserVideoSystem(url);
        } else {
          this.inAppBrowser.create(url, "_system");
        }
      } else {
        console.log("openBrowserLinkVideo secure_links false");
        this.InAppBrowserVideoSystem(url);
      }
    } catch (error) {
      console.log("openBrowserLinkVideo secure_links ", error);
      this.InAppBrowserVideoSystem(url);
    }
  }

  public openLinkInBrowser(url: string) {
    var path = this.appState.get("currentPath");

    console.log("Path cordova url", url);

    try {
      if (path.enabledAttributes.secure_links) {
        console.log("secure_links true");
        console.log("Includes", url.includes("1st90-api-images"));

        if (this.cordova && url.includes("1st90-api-images")) {
          this.InAppBrowserLinks(url);
        } else {
          this.inAppBrowser.create(url, "_system");
        }
      } else {
        console.log("secure_links false");
        this.InAppBrowserLinks(url);
      }
    } catch (error) {
      console.log("secure_links ", error);
      this.InAppBrowserLinks(url);
    }
  }

  InAppBrowserLinks(url) {
    if (this.cordova && this.cordova.platformId === "android") {
      if (url.match('.pdf')) {
        try {
         let urlLink = encodeURIComponent(url)
         this.inAppBrowserRef= this.cordova.InAppBrowser.open('https://docs.google.com/viewer?url=' +urlLink)

        }
        catch(e) {
         console.log("error",e)
        }
     } 
    else if (/mp4$/.test(url)) {
        this.inAppBrowser.create(url, "_system");
      } else {
        this.inAppBrowser.create(url, "_blank", "location=yes");
      }
    } else if (this.cordova && this.cordova.platformId === "ios") {
      this.inAppBrowser.create(
        url,
        "_blank",
        "location=no,footer=yes,footercolor=#CC000000,closebuttoncaption=Done,closebuttoncolor=#2196f3,toolbarposition=top,lefttoright=yes"
      );
    } else if (this.cordova) {
      this.inAppBrowser.create(url, "_blank", "location=yes");
    }
  }



  InAppBrowserVideoSystem(url: string) {
    if (this.cordova && this.cordova.platformId === "android") {
      let openBrowser = this.inAppBrowser.create(
        url,
        "_blank",
        "location=yes,enableViewportScale=false"
      );
      openBrowser.on('exit').subscribe(event => {
        // your action here when close inAppBrowser
        console.log("inAppBrowser is closed now");
        openBrowser.close();
      });
    } else if (this.cordova && this.cordova.platformId === "ios") {
      let openBrowser = this.inAppBrowser.create(
        url,
        "_blank",
        "location=no,footer=yes,footercolor=#CC000000,closebuttoncaption=Done,closebuttoncolor=#2196f3,toolbarposition=top,lefttoright=yes"
      );
      openBrowser.on('exit').subscribe(event => {
        // your action here when close inAppBrowser
        console.log("inAppBrowser is closed now");
        openBrowser.close();
      });
    } else {
      this.inAppBrowser.create(
        url,
        "_blank",
        "location=yes,enableViewportScale=true"
      );
    }
  }
}
