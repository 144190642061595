import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppState } from '../app.service';

@Injectable({
  providedIn: "root",
})
export class FooziAiConciergeService {
  API_URL: string = environment.fooziBaseUrl
  CREATE_SESSION = '/chatHandler/generateSession'
  currentUser: any;
  path: any;
  SEND_MESSAGE = '/chatHandler/<session_id>/message'
  GET_SESSION = '/chatHandler/<session_id>'
  APP_API_URL = environment.apiBaseUrl
  LIKE_RESPONSE = '/like_response'
  DISLIKE_RESPONSE = '/dislike_response'

  private isVisitedFooziComp = new BehaviorSubject<any>(null);
  private isHamburgerExpanded = new BehaviorSubject<any>(null);

  get expandHamburger() {
    return this.isHamburgerExpanded.asObservable();
  }
  get FooziCompStatus() {
    return this.isVisitedFooziComp.asObservable();
  }
  constructor(
    private http: HttpClient,
    private appState: AppState
  ) {}

  public createChatSession() : Observable<any> {
    this.currentUser = this.appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().set('userId', this.currentUser.id).set('pathId', this.path.id);
    return this.http.post(this.API_URL + this.CREATE_SESSION, params, {headers: headers});
  }

  public checkSessionValidity(session_id) : Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.http.get(this.API_URL + this.GET_SESSION.replace('<session_id>', session_id), {headers: headers});
  }

  public sendMessage(session_id, message) : Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(this.API_URL + this.SEND_MESSAGE.replace('<session_id>', session_id) + '?message=' + message, {}, {headers: headers}); 
  }

  public sendLike(response, prompt, feedback = '', like = 'true') {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().append('response', response)
                                   .append('prompt', prompt)
                                   .append('feedback', feedback)
                                   .append('like', like);
    return this.http.post(this.APP_API_URL + this.LIKE_RESPONSE, params, {headers: headers});
  }

  setFooziCompStatus(val) {
    this.isVisitedFooziComp.next(val);
  }
  setExpandHamburger(val) {
    this.isHamburgerExpanded.next(val);
  }
}
