import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  ChangeDetectorRef, // LUMBA-1308
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core'

import { AppState } from '../app.service'
import { Option } from '../option/option.model'
import { Path } from '../path/path.model'
import { Poll, PollStats } from '../poll/poll.model'
import { PollService } from '../poll/poll.service'
import { CordovaService } from '../cordova.service'
import { SpinnerService } from '../spinner.service'
import mixpanel from 'mixpanel-browser'
import { stringify } from 'querystring'
import { expandOnEnterAnimation, slideInDownAnimation } from 'angular-animations'
import { PopoverController } from '@ionic/angular'
import { PopoverComponent } from '../shared/popover/popover.component'
import { I18n } from '../i18n.service'
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'first90-poll-v2',
  templateUrl: './poll-v2.component.html',
  animations:[
    expandOnEnterAnimation({ duration: 400 }),
    slideInDownAnimation({duration:300})
  ],
  styleUrls: ['./poll-v2.component.scss']
})
export class PollV2Component implements OnInit, AfterViewInit, OnChanges{
  @Input() poll: Poll
  @Input() isPreview: boolean
  @Input() inverted = false
  @Output() blurCard= new EventEmitter<any>();

  // Isdispaly:boolean=true;

  enabled = true // TODO: Get it from the path
  nameTQ: string // TODO: Get it from the path
  nameHYPA: string // TODO: Get it from the path
  path: Path
  stats: PollStats
  status: string
  chart = []
  loading: boolean = false;
  isMobileWebIos = false;
  isMobileWeb=false;
  statsPer: number[]
  isMobile: boolean
  selectedOption
  answeredOptionData;
 @Input() enable= false;
 showActionpoints=false;
 showFinalize=false;
  constructor(
    private pollService: PollService,
    private appState: AppState,
    private spinnerService: SpinnerService,
    private popover:PopoverController,
    private cordovaService: CordovaService,
    private cdRef: ChangeDetectorRef, // LUMBA-1308
    private i18n: I18n,
    public homeUIService: HomeUiService,
  ) {
    this.path = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
    this.isEnabled()
    this.initNames()
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.loading = false;
    if (this.poll && this.poll.isCompleted) {
      this.answeredOptionData = this.poll.answer
      this.stats = this.poll.answer.stats
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngAfterViewInit() {
    if (this.poll.isCompleted) this.setStats()
  }
enableBlur(val) {
  this.enable=val;
 // if(this.enable)
    this.blurCard?.emit({
      enable: this.enable,
      poll: this.poll
    })
    
}
  ngOnChanges(changes: SimpleChanges) {
    if (!changes['poll'] || changes['poll'].firstChange) { return false }
    if (this.poll && this.poll.isCompleted) {
      this.stats = this.poll.answer.stats
      this.answeredOptionData = this.poll.answer;
      window.requestAnimationFrame(() => {
        this.setStats()
      })
    }
  }


  onOptionClicked(option: Option) {
    mixpanel.track('Step' + JSON.stringify(this.poll.startDay), { 'question': this.poll.title, 'selectedOption': option.text })
    if (this.poll.isCompleted) { return false }
    if (this.isPreview) { return false }
    this.poll?.options?.forEach((opt) => {
      if (opt.id == option.id) {
        option.isSelected = true;
        // opt.isSelected = true;
      } else {
        opt.isSelected = false;
      }
    });
    this.enable = true;
    this.showFinalize=true;
    setTimeout(()=>{
      this.showFinalize=false;
    },600);
    this.selectedOption = option
          console.log("this.this.selectedOption in in-step", this.selectedOption, this.poll);

  }
   onAnimationDone(event: any) {
    const element = event.element as HTMLElement;
    console.log("El",element)
    let primaryColor;
      
    primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--highlight-color');
    element.style.borderColor = primaryColor ;
  }
  finalizeMcq() {
    this.poll.isCompleted = true;
    this.loading = true;
    this.cdRef.detectChanges()
      this.pollService.answerPoll(this.poll, this.selectedOption.id).subscribe(response => {
        this.stats = response.answer.stats
        this.answeredOptionData = response.answer;
      this.setStats()
      this.loading = false
      this.showActionpoints=true;
      setTimeout(()=>{
        this.showActionpoints=false;
      },3000);
      setTimeout(()=>{
      this.enableBlur(this.enable);
      },500);
      this.cdRef.detectChanges()
    }, error => {
      this.selectedOption.isSelected = false;
      this.loading = false
      this.cdRef.detectChanges() // LUMBA-1308
    })  
  }

  setStats() {
    if (!this.stats) {
      return
    }
    if (!this.stats.data) {
      return
    }
    if (!this.stats.data.length) {
      return
    }

    const total = this.stats.data.reduce((sum, n) => sum + n)
    if (!total) {
      return
    }

    this.statsPer = this.stats.data.map(n => Math.round(n * 100 / total))
  }

  private isEnabled() {
    this.enabled = this.path.enabledAttributes['polls']
  }

  private initNames() {
    this.nameTQ = this.path.getCustomText('p_tq')
    this.nameHYPA = this.path.getCustomText('p_hypa')
  }
  async presentPopover(ev) {
    const dynamicText = this.i18n.t('These charts show the breakdown of answers among all your colleagues in this path.');
    const popover = await this.popover.create({
      component: PopoverComponent,
      translucent: true,
      event: ev,
      mode:'ios',
      showBackdrop: true,
      cssClass: 'custom-popover',
      componentProps: {
        dynamicText: dynamicText,
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }
  // ngOnDestroy(): void {
  //   this.showFinalize=false;
  // }
}
