import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatedHomeComponent } from './updated-home/updated-home.component';
import { HomeSheetModalComponent } from './home-sheet-modal/home-sheet-modal.component';
import { IonicModule, ModalController } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { BrowserModule } from '@angular/platform-browser';
import { mainModule } from 'process';
import { MainComponent } from '../main/main.component';
import { HomeV6Component } from '../pages/home-v6/home-v6.component';
import { I18n } from '../i18n.service';
import { I18nPipe } from '../i18n.pipe';
import { SafeHtml } from '../safehtml.pipe';



@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  //declarations: [UpdatedHomeComponent, HomeSheetModalComponent,MainComponent,HomeV6Component,I18nPipe,
  //  SafeHtml],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    HttpLinkModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    
  ],
  providers:[ModalController]
})
export class NewDesignModule { }
