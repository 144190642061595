import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input,ViewEncapsulation } from '@angular/core'
import { Chart } from 'chart.js'
import { hexToRgb } from '../utils.service'

import { AppState } from '../app.service'
import { StatService } from './stat.service'
import { Client } from '../client/client.model'
import { Path } from '../path/path.model'
import { User } from '../user/user.model'
import { ContentSurveyStats } from "../content-survey/content-survey.model";
import { ChartSettings } from '../daily-participation-chart/daily-participation-chart.component'
import { SpinnerService } from '../spinner.service'
import { MatDialog } from '@angular/material/dialog'
import { AlertSupportComponent } from '../alert-support/alert-support.component'
import { PopoverController } from '@ionic/angular'
import { UserPublicProfileComponent } from '../components/user-public-profile/user-public-profile.component'
import { I18n } from '../i18n.service'
import { ToasterService } from '../toaster.service'
import { Router } from '@angular/router'
import { slideOutRightAnimation } from 'angular-animations'
import { HomeUiService } from '../new-design/home-ui.service'
import * as mixpanel from 'mixpanel-browser'

@Component({
  selector: 'first90-stat-list',
  templateUrl: './stat-list.component.html',
  styleUrls: ['./stat-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    slideOutRightAnimation({duration: 500}),
  ]
})
export class StatListComponent implements OnInit, AfterViewInit {
  @Input() isHomeRoute: boolean;
  stats: object
  showing = 'leaderboard'
  client: Client
  path: Path
  currentUser: User
  loadingCounter = 0
  chartSettings: ChartSettings

  chartLabel : any;
  chartData : any;
  greenDay : any;

  scheduleDay: number
  realDay: number
  daysCompleted: number
  daysMissed: number
  daysRemining: number
  completions: any
  firstSurveyStats: ContentSurveyStats;
  leaderboardPosition: any
  statsTotal:any

  leaderboardEnabled: boolean
  checklistsEnabled: boolean
  pollsEnabled: boolean
  questionsEnabled: boolean
  documentsEnabled: boolean

  nameDP: string
  nameS: string
  nameL: string
  nameYD: string
  nameYDST: string
  nameT: string
  nameA: string
  nameAST1: string
  nameAST2: string
  namePQ: string
  nameAWP: string
  nameAWPST: string
  nameSP: string
  nameSPST: string
  nameR: string
  nameRST: string
  isMobile: boolean
  statList: any
  statByWeek: any

  thisPathUser: any
  thisPathUserRank: any
  thisWeekUser: any
  thisWeekUserRank: any
  allTimeUser: any
  allTImeUserRank: any

  thisPathFirst: any
  thisPathSecond: any
  thisPathThird: any

  thisWeekFirst: any
  thisWeekSecond: any
  thisWeekThird: any

  allTimeFirst: any
  allTimeSecond: any
  allTimeThird: any
  thisPathUserList: any
  isLoading=false;
  isMobileWebIos = false;
  isMobileWeb=false;
  thisPathLabel: string
  thisWeekLabel: string
  allTimeLabel: string
  currentTab = 0;
  startTime: any
  endTime: any
  leaderboardallTimeUsers;
  leaderboardPathUsers
  pathRegistration;
  options: [
    {
      label: 'Sliding Entrances',
      animations: ['slideOutRight']
    },
  ]
  animationState = false;

  constructor(
    private appState: AppState,
    private statService: StatService,
    private spinnerService: SpinnerService,
    private matDialog: MatDialog,
    private popoverController:PopoverController,
    private i18n: I18n,
    private toasterService: ToasterService,
    private router:Router,
    public homeUiService: HomeUiService,
  ) {
    this.thisPathLabel = this.i18n.t('THIS PATH')
    this.thisWeekLabel = this.i18n.t('THIS WEEK')
    this.allTimeLabel = this.i18n.t('ALL TIME')
    this.client = this.appState.get('currentClient')
    this.path = this.appState.get('currentPath')
    this.currentUser = this.appState.get('currentUser')
    this.pathRegistration = this.appState.get('pathRegistration')
    this.isMobile = this.appState.get('isMobile')
    this.loadingCounter = 0
    this.areEnabled()
    this.initNames()
    this.getStats(this.path.id)
    
  }



  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
  }
  async openUserInfo(ev, userData): Promise<void> {
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  ngAfterViewInit() {
    this.homeUiService.expandOnEnterAnimation()
  }

  toggleSettingDisplay(setting) {
    this.showing = setting
  }

  getArray(value: number): Array<any> {
    return new Array(value)
  }

  onView(event) {
    return false
  }
  private areEnabled() {
    this.leaderboardEnabled = this.path.enabledAttributes['leaderboard']
    this.checklistsEnabled = this.path.enabledAttributes['checklists']
    this.pollsEnabled = this.path.enabledAttributes['polls']
    this.questionsEnabled = this.path.enabledAttributes['questions']
    this.documentsEnabled = this.path.enabledAttributes['documents']
  }

  private initNames() {
    this.nameDP = this.path.getCustomText('h_dp')
    this.nameS = this.path.getCustomText('s_s')
    this.nameL = this.path.getCustomText('s_l')
    this.nameYD = this.path.getCustomText('s_yd')
    this.nameYDST = this.path.getCustomText('s_yd_st')
    this.nameT = this.path.getCustomText('s_t')
    this.nameA = this.path.getCustomText('s_a')
    this.nameAST1 = this.path.getCustomText('s_a_st_1')
    this.nameAST2 = this.path.getCustomText('s_a_st_2')
    this.namePQ = this.path.getCustomText('s_pq')
    this.nameAWP = this.path.getCustomText('s_awp')
    this.nameAWPST = this.path.getCustomText('s_awp_st')
    this.nameSP = this.path.getCustomText('s_sp')
    this.nameSPST = this.path.getCustomText('s_sp_st')
    this.nameR = this.path.getCustomText('s_r')
    this.nameRST = this.path.getCustomText('s_r_st')
  }

  getStats(pathId){
    if (this.router.url === '/main/stats') {
      this.spinnerService.on();
    }
    this.statService.getList(pathId)
    .then(response => {
      this.stats = response
      this.statsTotal = response.activities.total
      if (this.router.url === '/main/stats') {
        this.spinnerService.off();
      }

      this.getStatsByWeek(pathId,this.stats)
    })
    .catch(error => {
      if (this.router.url === '/main/stats') {
        this.spinnerService.off();
      }
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
      this.stats = error
    })

  this.statService.getDailyParticipation().subscribe(
    response => {
      // this.spinnerOff()
      this.leaderboardPosition = response.leaderboardPosition
      this.scheduleDay = response.scheduleDay
      const firstSurvey = this.statService.findFirstSurvey(this.path.contentSurveys, this.scheduleDay);
      if (firstSurvey) {
        this.firstSurveyStats = firstSurvey.getStats();
      }
      // this.completions = response.completions
      this.realDay = response.realDay
      const duration = response.data.length
      const lastCompletedDay = response.greenDays[response.greenDays.length - 1]
      this.daysCompleted = response.scheduleDay - (lastCompletedDay === this.realDay ? 0 : 1)
      this.daysMissed = this.realDay - this.scheduleDay
      // this.daysRemining = this.path.daysOfContent - response.scheduleDay
      this.daysRemining = this.path.daysOfContent - this.daysCompleted
      const cutResponse = this.statService.cutParticipationData(response);
      this.chartSettings = {
        colour: this.path.colours['theme'],
        labels: cutResponse.labels,
        data: cutResponse.data,
        greenDays: response.greenDays,
        realDay: this.realDay
      }
      
    }
  )
  }
  // thisPathClick($event) {
  //   mixpanel.track("Leaderboard Tab Changed", {value: 'Yes'})
  //   console.log("mixpanel", this.thisPathClick($event) )
  // }
  getStatsByWeek(pathId:number, stats:any=null){ 
    const requestData = {
      'path_id': pathId
    }

    if(!requestData){
      this.spinnerService.off();
    }else{
      this.statService.getListStatsByWeek(requestData).subscribe(
        (response) => {
          this.leaderboardPathUsers = stats.leaderboard.current_path_user
          this.statByWeek = response.data
          this.leaderboardallTimeUsers = stats.leaderboard.users
          this.getCurrentUser(stats, this.statByWeek)
        },
        (error) => {
          console.log('error for stats by week', error);
        }
      )
    }
  }

  goToQuestion(){
    var supportDialog = this.matDialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe()
  }

  getCurrentUser(stats: any, statsByWeek: any){
    var current_path_user=[]
    current_path_user = stats.leaderboard.current_path_user

    try {
      this.thisPathUserList = stats.leaderboard.current_path_user
      this.isLoading=true;
      this.thisPathUserRank = current_path_user.findIndex(element=> element.isMe)
      let thisUser = current_path_user.filter(element=> element.isMe)
      this.thisPathUser = thisUser[0]

      this.thisWeekUserRank = statsByWeek.findIndex(element=> element.id === this.currentUser.id)
      let thiWeekUser = statsByWeek.filter(element=> element.id === this.currentUser.id)
      this.thisWeekUser = thiWeekUser[0]

      this.allTImeUserRank = stats.leaderboard.users.findIndex(element=> element.isMe === true)
      let allTimeUser = stats.leaderboard.users.filter(element=> element.isMe === true)
      this.allTimeUser = allTimeUser[0]

      this.thisPathFirst = stats.leaderboard.current_path_user[0]
      this.thisPathSecond = stats.leaderboard.current_path_user[1]
      this.thisPathThird = stats.leaderboard.current_path_user[2]

      this.thisWeekFirst = statsByWeek[0]
      this.thisWeekSecond = statsByWeek[1]
      this.thisWeekThird = statsByWeek[2]

      this.allTimeFirst = stats.leaderboard.users[0]
      this.allTimeSecond = stats.leaderboard.users[1]
      this.allTimeThird = stats.leaderboard.users[2]
    } catch (error) {
      console.log('error catch', error);
      
    }
  }
  leaderboardTabChanged($event) {
    this.currentTab = $event.index;
    this.endTime = new Date();
    let timeDiff = this.endTime - this.startTime; // time difference in milliseconds
    let seconds = Math.floor(timeDiff / 1000); // convert to seconds
    let minutes = Math.floor(seconds / 60); // convert to minutes
    let hours = Math.floor(minutes / 60); // convert to hours
    let timeSpent = hours + ":" + minutes + ":" + seconds; // format the time spent
    mixpanel.track('Leaderboard- Tab change', {'Tabs active': this.currentTab == 0 ? 'THIS PATH' : this.currentTab == 1 ? 'THIS WEEK': this.currentTab == 2 ? 'ALL TIME' : '', 'time spent': timeSpent})
    localStorage.setItem('leaderboardTab', this.currentTab.toString());
   //this.onChange();
  }
  receiveState(event: any){
    setTimeout(() => {
      this.animationState = event
    }, 10);
  }

}
