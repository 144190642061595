import { Injectable } from '@angular/core';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { ToasterService } from './toaster.service';
import { AlertController, Platform } from '@ionic/angular';
import { AppState } from './app.service';
import { I18n } from './i18n.service';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private calendar: Calendar, 
    private alertController: AlertController,
    private platform: Platform,
    private appState: AppState,
    private i18n: I18n,
    private toaster: ToasterService) {
  }

  openCalendar(){
    this.calendar.openCalendar(new Date()).then(
        (msg) => { console.log(msg); },
        (err) => { console.log(err); }
    );
}

  addEvent(title,eventLocation,notes,startDate,endDate,calOptions,success,error){
   return this.calendar.createEventInteractivelyWithOptions(title,eventLocation,notes,startDate,endDate,calOptions);
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      header: this.i18n.t('Enable calendar'),
      subHeader: 'To add events to your calendar, allow calendar permissions for the Comet Pro app',
      message: 'Open Settings > Comet Pro > Calendars',
      buttons: [
        
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
          },
        },
      ],
    });

    await alert.present();
  }

  scheduleEvents(title,eventLocation,notes,startDate,endDate,calOptions,success,error,showAlertPrompt?){
    this.calendar.hasReadWritePermission().then((result)=>{
      console.log("result calendar permission", result);
    if(!result){
    
        this.calendar.requestReadWritePermission().then((v)=>{
            this.addEvent(title,eventLocation,notes,startDate,endDate,calOptions,success,error);
            this.appState.set('enableCalander',true);

        },(r)=>{
          if(this.platform.is('ios') && showAlertPrompt)  {
            this.presentAlert();
           }
            console.log("Rejected");
        })
    }
    else
    {
        this.addEvent(title,eventLocation,notes,startDate,endDate,calOptions,success,error);
    }
  })     

}

}
