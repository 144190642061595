import { AfterViewInit, Component, DoCheck, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, UrlTree } from '@angular/router';
import { AppState } from '../app.service';
import { UtilityService } from '../common-services/utility.service';
import { CordovaService } from '../cordova.service';
import { DailyStepsComponent } from '../daily-steps/daily-steps.component';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { Document } from '../document/document.model';
import { Habit } from '../habit/habit.model';
import { Path } from '../path/path.model';
import { SessionService } from '../session/session.service';
import { SpinnerService } from '../spinner.service';
import { StatService } from '../stat/stat.service';
import * as Mixpanel from 'mixpanel-browser';
import { slideInLeftOnEnterAnimation } from 'angular-animations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from '../toaster.service';
import { I18n } from '../i18n.service';


declare var $: any
@Component({
  selector: 'first90-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [
    slideInLeftOnEnterAnimation({ duration: 150 }),
  ]
})
export class MainNavComponent implements OnInit, AfterViewInit, DoCheck  {
  @ViewChild('moreModal') moreModal: ElementRef
  @ViewChild('moreBtn') moreBtn: ElementRef
  @ViewChild('dailyStepsComponent') dailyStepsComponent: DailyStepsComponent
  $moreModal: any
  onMobile = false
  onIE: boolean // LUMBA-1325
  activeIcon ='Home';
  chatRoute = '/main/questions';
  exploreRoute = '/main/documents';
  commitmentsRoute = '/habit';
  leaderboard = '/main/stats';
  library = '/main/library'
  sanitizedSvg: SafeHtml;
  lengthOfSnoozed: number;
  dailyStepsService: DailyStepsService
  routineVersion: boolean
  enabledAttributes: any
  isMobile: boolean;

  percentage: number
  documentData: Document[]
  pollData: any[]
  hadbitData: Habit[]
  path: Path
  thisPathUserRank = 0
  thisPathUser: any
  stats: object
  isAllPaths: boolean = false
  activeCloseoutWelcomeScreen: any
  showTracker: boolean = true
  hasSvgLoaded = false;
  dontShowAgain=false;
  options = [
    {
      label: 'Sliding',
      animations: ['slideInLeftOutRight', 'slideInRightOutLeft']
    }
  ]

  constructor(private router: Router, 
    private cordovaService: CordovaService,
    private appState: AppState, 
    private dialogRef: MatDialog,
    private utilService: UtilityService,
    private statService: StatService,
    public sessionService: SessionService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer, 
    private http: HttpClient,
    private toasterService: ToasterService,
    private i18n: I18n,
    private spinnerService: SpinnerService) 
    
  {
    this.onMobile = this.cordovaService.onCordova
    this.onIE = this.appState.get('ieVersion') !== false
    this.isMobile = this.appState.get('isMobile')
    this.path = this.appState.get('currentPath')
    this.lengthOfSnoozed = this.appState.get('snoozedSteps')
    if (!this.lengthOfSnoozed) {
      this.lengthOfSnoozed = parseInt(localStorage.getItem("snoozedSteps"))
    }

    this.enabledAttributes = this.appState.get("routineVersion")
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion
    }else{
      this.routineVersion = false
    }
    this.hadbitData = this.path.habits
    this.documentData = this.path.gridDocuments
    this.pollData = this.path.polls
    this.getStatsList(this.path.id)
    this.dontShowAgain = JSON.parse(localStorage.getItem("dontShowAgain"));
   }

  ngDoCheck() {
    this.isAllPaths = this.appState.get('isAllPaths')
    this.dontShowAgain = JSON.parse(localStorage.getItem("dontShowAgain"));
    this.activeCloseoutWelcomeScreen = this.appState.get('activeCloseoutWelcomeScreen')
    if ((this.router.url == '/main' && !this.isAllPaths && !this.activeCloseoutWelcomeScreen)) {
      this.showTracker = true
    }
    else {
      this.showTracker = false
    }
  }
  showToaster() {
    this.toasterService.warning(this.i18n.t("The Leaderboard has been disabled for this path."))
  }

   ngAfterViewInit(){
    if(!this.lengthOfSnoozed){
      try{
        if (this.dailyStepsComponent && this.dailyStepsComponent.getSnoozedSteps()){
          this.lengthOfSnoozed = this.dailyStepsComponent.getSnoozedSteps().length
        }        
      }catch(error){
        console.log('lengthOfSnoozed error',error)
      }
    }
    this.$moreModal = $(this.moreModal.nativeElement)

    this.$moreModal.on('shown.bs.modal', () => {
      $('.modal-backdrop').click(() => { this.$moreModal.modal('hide') })
    }) 
  }

  ngOnInit() {
    this.sessionService.getLibraryPosts();
    if (this.onIE) {
      $('body').addClass('onIE')
    }

    if(this.router.url==this.chatRoute){
      this.activeIcon='Chat'
      this.hasSvgLoaded =false;
    }else if(this.router.url==this.exploreRoute){
      this.activeIcon='explore'
      this.hasSvgLoaded =false;
    } else if(this.router.url==this.commitmentsRoute){
      this.activeIcon='Commitments'
      this.hasSvgLoaded =false;
    } else if(this.router.url==this.leaderboard){
      this.activeIcon='Leaderboard'
      this.hasSvgLoaded =false;
    } else if(this.router.url==this.library){
      this.activeIcon='Library'
      this.hasSvgLoaded =false;
    }
    // this.getStatsList(this.path.id)
  }

  closeModal() {
    this.$moreModal.modal('hide')
  }

  showMore() {
    //this.moreBtn.nativeElement.classList.toggle('active')
    this.$moreModal.modal('toggle')
  }

  isActive(url: string | UrlTree, exact: boolean = false): boolean {
    return this.router.isActive(url, exact)
  }

  setClass(icon){
    this.activeIcon = icon;
    this.hasSvgLoaded =false;
    if(icon !== 'Commitments'){
      localStorage.removeItem('commitmentTab');
      //localStorage.removeItem('actionTab');
      localStorage.setItem('actionTab', '0');
    }else if(icon !== 'Chat'){
      localStorage.setItem('communityTab', '0');
    }
  }
  loadSvg(svg,classSel, querySel){
    if(!this.hasSvgLoaded) {

      this.loadSvgFile(svg,classSel,querySel)
      }
  }
  getClass(icon){
    const homeSvgElement =document.getElementsByClassName('home-icon')[0];
    const leaderboardSvgElement =document.getElementsByClassName('leaderboard-icon')[0];
    const librarySvgElement =document.getElementsByClassName('library-icon')[0];
    //const exploreSvgElement =document.getElementsByClassName('home-icon')[0];
    const chatSvgElement =document.getElementsByClassName('chat-icon')[0];
    const commitmentsSvgElement =document.getElementsByClassName('commitments-icon')[0];

     this.activeIcon=='Commitments'?this.loadSvg("../../assets/NewUI/commitments.svg",'commitments-icon-active','polygon'):(commitmentsSvgElement?this.renderer?.removeStyle(commitmentsSvgElement, 'background-image'):'');
     this.activeIcon=='Leaderboard'?this.loadSvg("../../assets/NewUI/Leaderboard.svg",'leaderboard-icon-active','path'):(leaderboardSvgElement?this.renderer.removeStyle(leaderboardSvgElement, 'background-image'):'');
     this.activeIcon=='Library'?this.loadSvg("../../assets/NewUI/library-icon.svg",'library-icon-active','path'):(librarySvgElement?this.renderer.removeStyle(librarySvgElement, 'background-image'):'');
   // this.activeIcon=='explore'?this.loadSvg('../../assets/NewUI/home.svg'):this.renderer.removeStyle(exploreSvgElement, 'background-image');
    this.activeIcon=='Home'?this.loadSvg('../../assets/NewUI/home.svg','home-icon-active','path'):(homeSvgElement?this.renderer.removeStyle(homeSvgElement, 'background-image'):'');
    this.activeIcon=='Chat'?this.loadSvg("../../assets/NewUI/Community.svg",'chat-icon-active','path'):(chatSvgElement?this.renderer.removeStyle(chatSvgElement, 'background-image'):'');
    
    return  this.activeIcon == icon;
  }
  loadSvgFile(path,classSel,querySel) {
    
    
    this.http.get(path, { responseType: 'text' })
      .subscribe(svgData => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgData, 'image/svg+xml');
        const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');
        let pathElements = svgDoc.querySelectorAll(querySel);
        // console.log("pathElements",pathElements) // Modify the selector as per your SVG structure
       pathElements?.forEach(pathElement => {
        pathElement.style.fill = primaryColor; // Use the var(--color) notation to set the fill color dynamically
      });        
      const serializer = new XMLSerializer();
      const modifiedSvgData = serializer.serializeToString(svgDoc);
      const encodedData = encodeURIComponent(modifiedSvgData);
      const backgroundImageUrl = `url('data:image/svg+xml,${encodedData}')`;
      const svgElement =document.getElementsByClassName(classSel)[0];
      // if(svgElement){
      //   this.renderer.setStyle(svgElement, 'background-image', `${backgroundImageUrl}`);

      // }
    
      svgElement?.setAttribute('style', `background-image: ${backgroundImageUrl};`);
      });

      this.hasSvgLoaded = true;
    
  }
  closeDialog() {
    if(this.dialogRef.open)
    this.dialogRef.closeAll();
  }

  isSnoozedDotPresent(): boolean {
    if (this.lengthOfSnoozed > 0) {
      return true
    } else {
      return false
    }
  }

  getSnoozedSteps() {
    return this.dailyStepsService.getSnoozedSteps()
  }
//   update() {
//     this.getStatsList(this.path.id);
//     // do code
// }
  getStatsList(pathId) {
    this.statService.getList(pathId)
      .then(response => {
        this.stats = response
        this.thisPathUserRank = this.utilService.getCurrentUserRank(this.stats)
        this.thisPathUser = this.utilService.getCurrentPathUser(this.stats)
      })
      .catch(error => {
        this.stats = error
      })
  }

  returnPercentageAllTask() {
    try {
      // (this.messageDailySteps / this.messageDailyStepsTotal) * 100;
      const totalCount = this.getTotalTasksCount()
      const completedTaskCount = this.getCompletedTaskCount()
      this.percentage = (completedTaskCount / totalCount) * 100
      //Math.ceil(this.percentage) --> round up
      return Math.ceil(this.percentage)
    } catch (error) {

    }

  }

  // total task count for user - graph percentage
  getTotalTasksCount(): number {
    return (
      this.pollData.length +
      this.documentData.length +
      // this.questionData.length +
      this.hadbitData.length
    )
  }

  // completed task count for user - graph percentage
  getCompletedTaskCount(): number {
    let total: number = 0
    try {
      this.pollData.forEach((eachPath) => {
        if (eachPath.isCompleted) {
          total++
        }
      })
    } catch (o_O) { }
    try {
      this.documentData.forEach((eachDoc) => {
        if (eachDoc.isCompleted) {
          total++
        }
      })
    } catch (o_O) { }

    try {
      this.hadbitData.forEach((eachHabit) => {
        if (eachHabit.isCompleted) {
          total++
        }
      })
    } catch (o_O) { }
    return total
  }
  trackMenuClick(value: string, event: any) {
    Mixpanel.track('Home-Menu Click', { 'pageName':value, 'url': event.target.href });
  }

  ngOnDestroy(){
    this.appState.set('isAllPaths', undefined)
  }

  
}
