import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppState } from '../../app.service';
import { RoutineInfo } from '../routine-info.model';
import { RoutineLevel } from '../routine-level.model';

@Component({
  selector: 'day-time',
  templateUrl: './day-time.component.html',
  styleUrls: ['./day-time.component.scss']
})
export class DayTimeComponent implements OnInit, DoCheck {

  @Input() routine: RoutineInfo
  @Input() level: RoutineLevel
  @Input() routineType: any
  @Input() inverted = false
  
  @Output() dayData = new EventEmitter<any>();
  @Output() timeData = new EventEmitter<any>();
  @Output() typeData = new EventEmitter<any>();

  data: string
  type: string
  typevalue: string
  makeCommitment: any
  isEdit: boolean
  activityCueTime: any
  inputData: any

  days = new FormControl();
  dayList: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  timings: string[] = ['12:00 AM', '12:30 AM', '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM',
                      '03:30 AM', '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM',
                      '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM', '10:00 AM',
                      '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM',
                      '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM',
                      '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', 
                      '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];

  constructor(private appState: AppState, private cdRef: ChangeDetectorRef) {
   }

  ngOnInit() {
  }

  ngAfterViewInit(){
  }

  ngDoCheck(){
    this.getLocalData()
    if (this.data) {
      var arr = this.data.split(',')
      this.type = arr[1]
      this.typevalue = arr[2]
    }
    this.cdRef.detectChanges()
  }

  changeDay(days) {
    this.dayData.emit(days)
  }

  changeDate(time) {
    this.timeData.emit(time)
  }

  editData(){
    this.isEdit = true
  }

  getValue(type){
    this.inputData = type
    this.typeData.emit(this.inputData)
  }

  getLocalData(){
    var localCommitData = localStorage.getItem('commitData')
    var appStateCommitData = this.appState.get('commitData')
    if (localCommitData) {
      this.data = localStorage.getItem('commitData')
    }else{
      this.data = appStateCommitData
    }

    var localMakeCommitment = localStorage.getItem('makeCommitment')
    var localActivityCueTime = localStorage.getItem('activityCueTime')
    var appStateMakeCommitment = this.appState.get('makeCommitment')
    var appStateActivityCueTime = this.appState.get('activityCueTime')
    if (localMakeCommitment && localActivityCueTime) {
      this.makeCommitment = localMakeCommitment
      this.activityCueTime = localActivityCueTime
      if (this.activityCueTime == null) {
        this.activityCueTime = 'before'
      }
    }else{
      this.makeCommitment = appStateMakeCommitment
      this.activityCueTime = appStateActivityCueTime
      if (this.activityCueTime == null) {
        this.activityCueTime = 'before'
      }
    }
    
  }

}
