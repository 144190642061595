import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef,EventEmitter, HostListener, Input, OnInit, Output, QueryList, Renderer2, ViewChild } from '@angular/core'
import { TextScreen } from './text-screen.model'
import { AlertController, AlertInput, IonContent } from '@ionic/angular';
import { CordovaService } from '../cordova.service'
import { AppState } from '../app.service'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AlertAddNotesComponent } from '../alert-add-notes/alert-add-notes.component';
import { LibraryService } from '../my-library/library.service';
import { ToasterService } from '../toaster.service';
import { SpinnerService } from '../spinner.service';
import { AlertDeletePostComponent } from '../alert-delete-post/alert-delete-post.component';
import { SessionService } from '../session/session.service';
import { HomeUiService } from '../new-design/home-ui.service';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx';
import { IonFab, ModalController, Platform } from '@ionic/angular';
import mixpanel from 'mixpanel-browser';
import { UtilityService } from '../common-services/utility.service';
import { YourPathComponent } from '../new-design/your-path/your-path.component';
import { ActivatedRoute, Router } from '@angular/router'
import { I18n } from '../i18n.service';

export interface JournalPostItem {
  [journalPostItem: string]: any;
}
@Component({
  selector: 'first90-text-screen',
  templateUrl: './text-screen.component.html',
  styleUrls: ['./text-screen.component.scss'],
})
export class TextScreenComponent implements OnInit, DoCheck {
  @Input() progressItems = []
  @Input() currentIndex = 0
  @Input() currentDay = 0
  @Input() inoutScreen: any
  @Input() screenId: any
  @ViewChild("stepTitle") stepTitle: ElementRef;
  @Input() screen: TextScreen
  @Input() back = true
  isMobileWebIos = false;
  isMobileWeb = false;
  // @Input() close = true
  allowScreenCount: boolean
  @Input() inverted = false

  @Output('onClick') onClick = new EventEmitter<null>()
  @Output('onBack') onBack = new EventEmitter<null>()
  @Output('onClose') onClose = new EventEmitter<null>()
  isMobile: boolean;
  innerTextHtml: any

  @Input() habit: any
  @Input() poll: any
  @Input() question: any
  @Input() document: any
  @Input() survey: any
  @Input() onboardingSettingScreen: any;
  currentPath: any
  savedLibraryPosts: any
  postToJournal: JournalPostItem
  recentSavedPost: any
  savedScreenId: any
  @Input() isAlreadyPresent: boolean
  @Input() isDocumentOnly: boolean
  @Input() reflection: any
  flag= false;
  backTo: string
  screensIds = []
  currentScreenIndex = 0
  showBackdrop;
  // @ViewChildren('focusableElement') focusableElements: QueryList<ElementRef>;
  @ViewChild(IonContent) content: HTMLIonContentElement;
  @ViewChild('fabComponent') fabComponent: IonFab; 
  // isMobileWeb=false;
  constructor(private cordovaService: CordovaService,
    private appState: AppState,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private libraryService: LibraryService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private i18n: I18n,
    private render:Renderer2,
    private sessionService: SessionService,
    private cdRef: ChangeDetectorRef,
    private youtubePlayer: YoutubeVideoPlayer,
    public homeUIService: HomeUiService,
    public utilService: UtilityService,
    private router: Router,
    private platform: Platform,
    private modalController: ModalController,
    private alertController: AlertController,
    private elRef: ElementRef) {
    this.isMobile = this.appState.get('isMobile')
    this.currentPath = this.appState.get('currentPath')
    
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    this.innerTextHtml = this._sanitizer.bypassSecurityTrustHtml(this.screen?.text);
    this.checkLibraryStatus(this.poll,this.survey,this.document,this.question,this.habit)
  }

  
  ngDoCheck() {
    this.savedLibraryPosts = this.appState.get('savedPosts')
    this.recentSavedPost = this.appState.get('recentSavedStep')
    this.savedScreenId = this.appState.get('savedScreenId')
  }
  // changeSubTextFont() {
  //   if(this.stepTitle) {
  //    const element = this.stepTitle?.nativeElement;
  //    console.log("test for admin text", element)
  //    const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2') || element?.querySelector('span');
  //    if(specificElement && this.homeUIService.isDark() ) {
  //      this.render.setStyle(specificElement, 'color ', '#fff');
  //      }
  //  return true;
  //   }
  //  }

  ngAfterViewInit(){
    var hElement: HTMLElement = this.elRef.nativeElement;
    var allDivs = hElement.getElementsByClassName('ql-video');
    if (allDivs?.length >=1) {
      // let data = allDivs[0]['contentWindow']
      this.appState.set('disableVideo', true)
      this.appState.set('iframeObject', allDivs[0])
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  onClickHandler() {
    this.onClick.emit()
    return false
  }
  toggleOpen(){
    
    if(!this.fabComponent?.activated){
      this.showBackdrop = true;
      this.appState.set('isFabClicked',true)
    }
    }
    
    contentClick(event: Event) {
          if (this.appState.get('isFabClicked')==false && this.showBackdrop) {
            this.showBackdrop = false;
            if(this.fabComponent?.activated){
              this.fabComponent.close();
            }
          }   
          if(this.appState.get('isFabClicked')) {
            this.appState.set('isFabClicked',false)

          }
    }

  onBackHandler(event) {
    event.stopPropagation()
    this.onBack.emit()
    return false
  }

  onCloseHandler(event) {
    event.stopPropagation()
    this.onClose.emit()
    return false
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    this.contentClick(e);
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        if (this.sessionService.confirmYoutubeUrl(e.target.href)) {
          this.playRemoteVideo(e.target.href)
          mixpanel.track('Library-clicked on link', {'link': this.playRemoteVideo(e.target.href)})
        }else{
          this.cordovaService.openLinkInBrowser(e.target.href)
          mixpanel.track('Library-clicked on link', {'link': this.cordovaService.openLinkInBrowser(e.target.href)})
        }
      } else {
        window.open(e.target.href, '_blank')    
        console.log('clicked on a link :', );
        mixpanel.track('Library-clicked on link', {'link': e.target.href})
      }
    }
  }
  playRemoteVideo(url: string) {
    if (this.sessionService.confirmYoutubeUrl(url)) {
      console.log('Youtube video :');
      if (this.platform.is('ios')) {
        try {
          // Try to run this code 
          console.log("in try block");
          this.youtubePlayer.openVideo(this.sessionService.getYouTubeVideoIdFromUrl(url));
        }
        catch(err) {
          // if any error, Code throws the error
          console.log("catch_block", err);
        }
      }  else if(this.platform.is('android')){
        this.cordovaService.openBrowserLinkVideo(url);
      } else {
        this.cordovaService.openBrowserLinkVideo(url);
      }
    }
  }

  nextScreen() {
    this.appState.set('allowNext', true)
    this.checkLibraryStatus(this.poll,this.survey,this.document,this.question,this.habit)
    this.cdRef.detectChanges()
  }

  checkLibraryStatus(poll,contentSurvey,document,question,habit){
    let savedDocumentId, habitId, surveyId, pollId, questionId, flag =false;
    let savedLibraryPosts = this.appState.get('savedPosts')
    savedLibraryPosts?.forEach(element => {
      savedDocumentId = element.documents[0]?.id
      habitId = element.habits[0]?.id
      surveyId = element.contentSurveys[0]?.id
      pollId = element.polls[0]?.id
      questionId = element.questions[0]?.id
      
      if (savedDocumentId && savedDocumentId === document?.id || habitId && habitId === habit?.id || 
        pollId && pollId === poll?.id || surveyId && surveyId === contentSurvey?.id || questionId && questionId === question?.id) {
          if (element.habits?.length > 0 || element.contentSurveys?.length > 0 ||
            element.polls?.length > 0 || element.questions?.length > 0) {
              this.isAlreadyPresent = true
              flag =true;
          }
          else if(!flag) {
            this.isAlreadyPresent =undefined
          }
      }
      else if(!flag) {
        this.isAlreadyPresent =undefined
      }
    });

    let recentSavedPost = this.appState.get('recentSavedStep')
    let recentSavedDocumentId = recentSavedPost?.documents[0]?.id
    let recentSavedHabitId = recentSavedPost?.habits[0]?.id
    let recentSavedPollId = recentSavedPost?.polls[0]?.id
    let recentSavedQuestionId = recentSavedPost?.questions[0]?.id
    let recentSavedSurveyId = recentSavedPost?.contentSurveys[0]?.id
    if (recentSavedDocumentId || recentSavedHabitId || recentSavedPollId || recentSavedSurveyId || recentSavedQuestionId) {
      if (recentSavedPost.habits?.length > 0 || recentSavedPost.contentSurveys?.length > 0 ||
        recentSavedPost.polls?.length > 0 || recentSavedPost.questions?.length > 0) {
          this.isAlreadyPresent = true
      }
    }

    if (this.appState.get('isNewStep')) {
      this.isAlreadyPresent = undefined
    }

    this.cdRef.detectChanges()
  }

  getCurrentScreenData() {
    let savedDocumentId
    let habitId, surveyId, pollId, questionId;
    let isAlreadyCreated, presentPost;

    console.log('this.savedLibraryPosts :', this.savedLibraryPosts);
    this.savedLibraryPosts?.forEach(element => {
      savedDocumentId = element.documents[0]?.id
      habitId = element.habits[0]?.id
      surveyId = element.contentSurveys[0]?.id
      pollId = element.polls[0]?.id
      questionId = element.questions[0]?.id

      if (savedDocumentId && savedDocumentId === this.document?.id || habitId && habitId === this.habit?.id || 
        pollId && pollId === this.poll?.id || surveyId && surveyId === this.survey?.id || questionId && questionId === this.question?.id) {
          if (element.habits?.length > 0 || element.contentSurveys?.length > 0 ||
            element.polls?.length > 0 || element.questions?.length > 0) {
              console.log('step is there');
              this.isAlreadyPresent = true
              presentPost = element
          }
      }
    });


    this.recentSavedPost = this.appState.get('recentSavedStep')
    console.log('recentSavedPost :', this.recentSavedPost);
    let recentSavedDocumentId = this.recentSavedPost?.documents[0]?.id
    let recentSavedHabitId = this.recentSavedPost?.habits[0]?.id
    let recentSavedPollId = this.recentSavedPost?.polls[0]?.id
    let recentSavedQuestionId = this.recentSavedPost?.questions[0]?.id
    let recentSavedSurveyId = this.recentSavedPost?.contentSurveys[0]?.id

    if (this.isAlreadyPresent) {
      if(this.isMobile) {
        const dialogRef = this.dialog.open(AlertDeletePostComponent)
        dialogRef.afterClosed().subscribe((result) => {
          if(result === 'confirm'){
            this.deletePost(presentPost)
          }
        })  
      }
      else {
        this.deletePostAlert(presentPost)
      }
    } else if(recentSavedDocumentId||recentSavedHabitId||recentSavedPollId||recentSavedSurveyId||recentSavedQuestionId){
      if (recentSavedHabitId === this.habit?.id || recentSavedPollId === this.poll?.id || recentSavedSurveyId === this.survey?.id || recentSavedQuestionId === this.question?.id) {
        if(this.isMobile) {
          const dialogRef = this.dialog.open(AlertDeletePostComponent)
          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirm') {
              this.deletePost(this.recentSavedPost)
            }
          })
        }
        else {
          this.deletePostAlert(this.recentSavedPost)
        }
      }else{
        if(!this.isMobile) {
          this.savePostAlert()
        }
        else {
          this.savePost()
        }
      }
    } else {
      if(!this.isMobile) {
        this.savePostAlert()
      }
      else {
        this.savePost()
      }
    }

  }
  async deletePostAlert(doc){
    const alert = await this.alertController.create({
      
      subHeader: this.i18n.t("Delete Post"),
      message: this.i18n.t("Do you want to delete this post?"),
      cssClass:this.homeUIService.isDark()?'dark-theme alertBackdrop ':' light-theme alertBackdrop',
      buttons: [
        {
          text: this.i18n.t("Cancel"),
          handler:()=>{
           
          },
          role: 'cancel',
        },
        {
          text: this.i18n.t("Confirm"),
          cssClass: 'custom-alert',
          handler: async () => {
            this.deletePost(doc)
          },
        },
      ]  
     });
  
    await alert.present();
  }

  ngOnDestroy() {
    this.appState.set('screenStatus', null);
    this.appState.set('hidePrevScreen', false)
    if(!this.flag) {
      this.sessionService.removeAppStateOnContentClose()
      let prevPathData = this.appState.get('prevPathData')
      if (prevPathData) {
        this.sessionService.changePathToLibrary(prevPathData)
        this.appState.set('prevPathData',undefined)
      }
    }

  }
  fetchStepSatus(){
    let dailySteps = this.appState.get("DailyStepsData");
    dailySteps.forEach((step)=>{
      if(step?.stepStatus =='Review' && step?.step<dailySteps?.length )
      {
          this.appState.set('currentScheduleDay', step?.step+1);
          this.appState.set('scheduleDayForCurrentStep',  step?.step+1);
      }
    })
  }
  close() {
    let scheduledStep = this.appState.get("scheduleDayForCurrentStep");
    let dailyStepsArray= this.appState.get("DailyStepsData");
    if(this.appState.get('isLevelCommited')!==false) {
    this.flag=true;
    if (this.allowScreenCount && this.currentDay == scheduledStep && dailyStepsArray[scheduledStep-1]?.stepStatus!='Review') {
      this.utilService.setScreenData(this.currentScreenIndex, this.currentDay, this.screensIds)
      this.utilService.setScreenCount()
    }
    if (this.appState.get('routine_schedule_day')) {
      this.appState.set('routine_schedule_day', null)
    }
    this.sessionService.removeAppStateOnContentClose()
    let prevPathData = this.appState.get('prevPathData')
    if (prevPathData) {
      this.sessionService.changePathToLibrary(prevPathData)
      this.appState.set('prevPathData',undefined)
    }
    if (this.backTo) {
      this.router.navigate([this.backTo])
      this.fetchStepSatus();
      this.appState.set('isHomeScreen', true)
    } else {
      if(this.isMobile) {
       this.router.navigate(['/main'])
      }
      else {
        this.modalController.dismiss();
      }
      this.fetchStepSatus();
      this.appState.set('isHomeScreen', true)
    }
    if (this.appState.get('isRouteToLibrary')) {
      if(this.isMobile) {
        this.router.navigate(['/main'])
       }
       else {
         this.modalController.dismiss();
       }      this.fetchStepSatus();
      // this.router.navigate(['/main'])
    //  this.router.navigate(['/main/library'])
      //this.navCtrl.navigateBack('/detail-reflection');
      this.appState.set('isRouteToLibrary', undefined)
    }
    if(this.appState.get('isYourPathPrevious')) {
       this.appState.set('isYourPathPrevious',false);
       //this.appState.set('isHamburgerOpen', true);
       this.fetchStepSatus()
       setTimeout(()=>{
        this.homeUIService.openNextComponent(YourPathComponent);
       },600);
    }
  }
  else {
    this.toasterService.warning(this.i18n.t('Please commit habit level before closing step'));
  }
   
  }
  async savePostAlert(){
    const alert = await this.alertController.create({
      subHeader: this.i18n.t('Save to Library'),
      cssClass:this.homeUIService.isDark()?'dark-theme alertBackdrop':' light-theme alertBackdrop',
       inputs: [
        
         {
           name:'textarea',
           type:'textarea',
           cssClass:'web-textarea',
           placeholder: this.i18n.t('My notes on this step'),
         },
        
       ],
       buttons: [
        {
          text: this.i18n.t('Cancel'),
          handler:()=>{

          },
          role:'Cancel'
        },
         {
           text: this.i18n.t("Save"),
           cssClass: 'custom-alert', 
             handler: (alertData: AlertInput) => {
              console.log("alertData in saved step",alertData['textarea'])
              localStorage.setItem('journalNote',alertData['textarea']);
              if (alertData['textarea']) {
                let text = localStorage.getItem('journalNote')
                const requestData = {
                  'text': [text],
                  'pathId': this.currentPath.id,
                  'contentSurveyId': this.survey?.id,
                  'habitId': this.habit?.id,
                  'pollId': this.poll?.id,
                  'documentId': this.document?.id,
                  'questionId': this.question?.id,
                  'inoutScreenId': this.inoutScreen?.id,
                  'screenId': this.savedScreenId,
                  'reflectionId': this.reflection?.id
                }
      
                this.spinnerService.on()
                this.libraryService.save(requestData).subscribe((response) => {
                  this.savedLibraryPosts = this.sessionService.getLibraryPosts();
                this.isAlreadyPresent =true;
                if(this.appState.get('FirstTimeTabCheck')){
                  this.appState.set('tabLabel', 'saved')
                }
                  console.log('response :', response);
                  this.postToJournal = response;
                  this.appState.set("recentSavedStep",this.postToJournal.postToJournal.journalPost)
                  this.toasterService.success(this.i18n.t('Step saved.'))
                  this.cdRef.detectChanges()
                },
                  (error) => {
                    this.spinnerService.off()
                    console.log('error :', error);
                  })
              }
             else {
                this.toasterService.warning(this.i18n.t("Please enter valid notes"))
              } 
        }
        
         },
        ],
     });
     await alert.present();
  }
  savePost(){
    const dialogRef = this.dialog.open(AlertAddNotesComponent)
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          let text = localStorage.getItem('journalNote')
          const requestData = {
            'text': [text],
            'pathId': this.currentPath.id,
            'contentSurveyId': this.survey?.id,
            'habitId': this.habit?.id,
            'pollId': this.poll?.id,
            'documentId': this.document?.id,
            'questionId': this.question?.id,
            'inoutScreenId': this.inoutScreen?.id,
            'screenId': this.savedScreenId,
            'reflectionId': this.reflection?.id
          }

          this.spinnerService.on()
          this.libraryService.save(requestData).subscribe((response) => {
            this.savedLibraryPosts = this.sessionService.getLibraryPosts();
          this.isAlreadyPresent =true;
          if(this.appState.get('FirstTimeTabCheck')){
            this.appState.set('tabLabel', 'saved')
          }
            console.log('response :', response);
            this.postToJournal = response;
            this.appState.set("recentSavedStep",this.postToJournal.postToJournal.journalPost)
            this.toasterService.success(this.i18n.t('Step saved.'))
            this.cdRef.detectChanges()
          },
            (error) => {
              this.spinnerService.off()
              console.log('error :', error);
            })
        }
      })
  }

  deletePost(post) {
    console.log('deleted', post);
    const requestData = {
      id: post?.id
    }
    console.log('requestData :', requestData);
    this.spinnerService.on();
    this.libraryService.deletePost(post?.id).subscribe(
      (response) => {
        this.savedLibraryPosts = this.sessionService.getLibraryPosts()
        this.isAlreadyPresent = undefined
        this.appState.set('recentSavedStep', undefined)
        this.cdRef.detectChanges()
        console.log('response :', response);
        this.toasterService.success(this.i18n.t('Post Deleted!'))        
      },
      (error) => {
        this.spinnerService.off();
        console.log('Error for feedback ', error)
      }
    )
  }

  changeSubTextFont() {
    if(this.stepTitle && this.homeUIService?.isDark()) {
  
    const mainElement = this.stepTitle.nativeElement;
    // Apply styles to all inner tags
    this.applyStylesToAllInnerTags(mainElement, 'color', '#adadad');
   
    }
    else if(this.stepTitle) {
      const mainElement = this.stepTitle.nativeElement;

      // Apply styles to all inner tags
      this.applyStylesToAllInnerTags(mainElement, 'color', '#000');
    }
    
   }
   applyStylesToAllInnerTags(element, styleProperty: string, styleValue: string) {
    const childElements = Array.from(element.children);

    childElements.forEach(childElement => {
      this.render.setStyle(childElement, styleProperty, styleValue);
      // Recursively apply styles to child elements
      this.applyStylesToAllInnerTags(childElement, styleProperty, styleValue);
    });
    //  return true;
  }
}
