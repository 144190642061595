import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { Filter } from '../shared/filter'
import * as query from './question.query'
import { replyFields } from '../reply/reply.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { PathQuestion } from '../path/path-question.model'
import { Question } from './question.model'
import { Reply } from '../reply/reply.model'
import { HomePageService } from '../pages/home/home-page.service'
import { AppState } from '../app.service'
import { WalletService } from '../wallet/wallet.service'
import { invalidateCache } from '../utils.service';

@Injectable()
export class QuestionService {

  constructor(
    private appState: AppState,
    private apollo: Apollo,
    private homePageService: HomePageService,
    public walletService: WalletService
  ) { }

  getPathList(filter: Filter = null): Observable<PathQuestion[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listPathQuestions, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: PathQuestion[] = []
          response.data.pathQuestions.forEach(element => { list.push(new PathQuestion(element)) })
          return list
        })
      )
  }

  getList(filter: Filter = null): Observable<Question[]> {
    return this.apollo
      .watchQuery<any>({ query: query.listQuestions, variables: { filter: filter } })
      .valueChanges
      .pipe(
        map(response => {
          let list: Question[] = []
          response.data.questions.forEach(element => { list.push(new Question(element)) })
          return list
        })
      )
  }

  getQuestion(id: number): Observable<Question> {
    return this.apollo
      .watchQuery<any>({ query: query.findQuestion, variables: { id: id } })
      .valueChanges
      .pipe(
        map(response => {
          return new Question(response.data.question)
        })
      )
  }

  save(question: Question) {
    return this.apollo.mutate({
      mutation: query.updateQuestion,
      variables: {
        question: question.input()
      }
    })
  }
 
  replyQuestion(question: any, text: string, attachmentUrl: string = null,userIds: any,mode: string = 'question') {
    let user = this.appState.get('currentUser')
    let optimisticId = 1000000000 + Math.round(Math.random() * 1000000000)
    return this.apollo.mutate({
      mutation: query.replyQuestion,
      variables: {
        questionId: question.id,
        text: text,
        attachmentUrl: attachmentUrl,
        mode: mode,
        userIds: userIds
      },
      optimisticResponse: {
        __typename: 'Mutation',
        replyQuestion: {
          __typename: 'ReplyQuestionPayload',
          optimistic: true,
          status: true,
          actionPoints: this.walletService.actionPoints.value,
          reply: {
            level: 1,
            linkThumbnails: null,
            attachmentUrl: null,
            votesCount: 0,
            id: optimisticId,
            text: text,
            repliableId: null,
            user: {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              imageUrl: user.imageUrl,
              advanceColour: '',
              userBgColor: user.userBgColor,
              __typename: "User",
            },
            createdAt: '',
            updatedAt: '',
            enabled: true,
            __typename: 'Reply'
          }
        },
      },
      update: (proxy, { data: { replyQuestion } }) => {
        let reply = replyQuestion.reply
        if (question.id) {
          // Questions
          this.homePageService.updateCompletions(proxy, `Question:${question.id}`)
          proxy.writeData({
            id: `Question:${question.id}`,
            data: {isCompleted: true, 
              //replies: [reply, ...question._data['replies']]
            }
          })
          let object = this.appState.get('currentPath').update(this.appState.get("mode"), question.id, {isCompleted: true})  
          if (replyQuestion.optimistic) {
            //object.insertReply(new Reply(reply), true)
          } else {
            // object.replaceReply(optimisticId, new Reply(reply))
          }
        } else {
          // Chatboard
          let object = this.appState.get('currentChatBoard')
          if (replyQuestion.optimistic) {
            object.insertReply(new Reply(reply), true)
          } else {
            object.replaceReply(optimisticId, new Reply(reply))
          }
        }
        this.walletService.update(replyQuestion.actionPoints)
        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    })
  }


  replyRoutineAnswer(question: any, text: string, attachmentUrl: string = null,userIds: any,mode: string = 'question') {
    let user = this.appState.get('currentUser')
    let optimisticId = 1000000000 + Math.round(Math.random() * 1000000000)
    return this.apollo.mutate({
      mutation: query.replyQuestion,
      variables: {
        questionId: question.routine_info_id,
        text: text,
        attachmentUrl: attachmentUrl,
        mode: mode,
        userIds: userIds,
        routineAnswerId: question.id
      },
      optimisticResponse: {
        __typename: 'Mutation',
        replyQuestion: {
          __typename: 'ReplyQuestionPayload',
          optimistic: true,
          status: true,
          actionPoints: this.walletService.actionPoints.value,
          reply: {
            level: 1,
            linkThumbnails: null,
            attachmentUrl: null,
            votesCount: 0,
            id: optimisticId,
            text: text,
            repliableId: null,
            user: {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              imageUrl: user.imageUrl,
              advanceColour: '',
              userBgColor: user.userBgColor,
              __typename: "User",
            },
            createdAt: '',
            updatedAt: '',
            enabled: true,
            __typename: 'Reply'
          }
        },
      },
      update: (proxy, { data: { replyQuestion } }) => {
        let reply = replyQuestion.reply
        if (question.routine_info_id) {
          // Questions
          this.homePageService.updateCompletions(proxy, `Question:${question.routine_info_id}`)
          console.log('proxy',proxy);
          
          proxy.writeData({
            id: `Question:${question.routine_info_id}`,
            data: {isCompleted: true, replies: [reply, ...question._data['replies']]}
          })
          
          
          let routineInfo = this.appState.get('currentPath').update('routineInfo', question.routine_info_id) 
          let object = routineInfo.routineAnswerList.find(q => q.id === question.id)
          console.log('object 1', object);
          
          if (replyQuestion.optimistic) {
            object.insertReply(new Reply(reply), true)
          } else {
            object.replaceReply(optimisticId, new Reply(reply))
          }
        } else {
          // Chatboard
          let object = this.appState.get('currentChatBoard')
          if (replyQuestion.optimistic) {
            object.insertReply(new Reply(reply), true)
          } else {
            object.replaceReply(optimisticId, new Reply(reply))
          }
        }
        this.walletService.update(replyQuestion.actionPoints)
        invalidateCache(proxy, 'ROOT_QUERY.dailyParticipation')
      }
    })
  }
}
