import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalController, NavParams, PickerColumn, PickerController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { AlertRepLogComponent } from 'src/app/alert-rep-log/alert-rep-log.component';
import { AppState } from 'src/app/app.service';
import { HabitService } from 'src/app/habit/habit.service';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { Path } from 'src/app/path/path.model';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToasterService } from 'src/app/toaster.service';
import { User } from 'src/app/user/user.model';
import * as moment from 'moment';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import mixpanel from 'mixpanel-browser';
import { I18n } from 'src/app/i18n.service';
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class FeedbackFormComponent implements OnInit {
  // @Input() mainlist;
  // @Input() item;
  @Input() IsHomescreen;
  @Input() fromToDoListScreen;
  currentPath: Path;
  currentUser: User;
  currentDay: number
  routineList: any
  isCheckedAll: boolean = false
  routineLevels: any
  isExpanded: boolean = false
  routineId: number
  scheduledDay: number

  currentRoutine: any
  isNextScreen: boolean = false
  makeHabit: boolean
  levelId: any
  levelNumber: number
  routineDay: number
  isMobile: boolean
  message: string =''
  mainItem: any;
  item: any;
  index: any;
  selectedDate: any = moment().format('MMMM DD, YYYY');;
  //private date: any = moment().toISOString();
  isKeyBoardOpen=false;

  @ViewChild('datePicker') datePicker;
  constructor(
    private modalController: ModalController,
    private cdRef: ChangeDetectorRef,
    private appState: AppState,
    private tostrService: ToasterService,
    private i18n: I18n,
    private navParams: NavParams,
    private pickerController: PickerController,
  ) {
    this.currentPath = this.appState.get("currentPath");
    this.currentUser = this.appState.get("currentUser");
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
    this.currentDay = this.appState.get('currentScheduleDay')
    this.levelNumber = this.appState.get('levelId')
    this.isMobile = this.appState.get('isMobile')
    this.appState.set('page', 1)
  }

  ngOnInit() {
    this.mainItem = this.navParams.get('mainItem');
    this.item = this.navParams.get('subItem');
    this.index = this.navParams.get('index');
    this.keyBoardEvent();
    this.selectedDate = moment().format('MMMM DD, YYYY');
  }
  
  keyBoardEvent() {
    window.addEventListener('keyboardWillShow', (event: any) => {
      this.isKeyBoardOpen=true;
      console.log('is changed done')
      this.cdRef.detectChanges();
    });
    window.addEventListener('keyboardWillHide', (event: any) => {
      this.isKeyBoardOpen=false;
      console.log('is changed keyboardWillHide')
      this.cdRef.detectChanges();
    });
  }
  dateChanged(event) {
    const newDate = event.detail.value;
    if (newDate) {
      this.selectedDate = newDate;
      mixpanel.track('Date Change Value', { date: this.selectedDate });
    }
  }

  async openDatePicker() {
    const currentDate = moment(this.selectedDate, 'MMMM DD, YYYY');
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: this.i18n.t('Cancel'),
          role: 'cancel'
        },
        {
          text: this.i18n.t('Confirm'),
          handler: (value) => {
            if (value && value.day && value.month && value.year) {
              const selectedDay = value.day.value;
              const selectedMonth = value.month.value;
              const selectedYear = value.year.value;
  
              this.selectedDate = `${this.getMonthName(
                parseInt(selectedMonth)
              )} ${selectedDay}, ${selectedYear}`;
              // mixpanel.track('Date Change Value', { date: this.selectedDate });
            }
          }
        }
      ],
      columns: this.generateDateOptions(currentDate.month() + 1, currentDate.date(), currentDate.year())
    });
    await picker.present();
  }

  generateDateOptions(selectedMonth: number, selectedDay: number, selectedYear: number) {
    const dateOptions = Array.from({ length: 31 }, (_, i) => {
      return {
        text: (i + 1).toString().padStart(2, '0'),
        value: (i + 1).toString().padStart(2, '0')
      };
    });
  
    const monthOptions = Array.from({ length: 12 }, (_, i) => {
      return {
        text: this.getMonthName(i + 1),
        value: (i + 1).toString().padStart(2, '0')
      };
    });
  
    const currentYear = new Date().getFullYear();
    const maxYearsAhead = 10; // You can modify this range as needed
    const yearOptions = Array.from({ length: maxYearsAhead + 1 }, (_, i) => {
      return {
        text: (currentYear + i).toString(),
        value: (currentYear + i).toString()
      };
    });
  
    const columns: PickerColumn[] = [
      { name: 'month', options: monthOptions, selectedIndex: selectedMonth - 1 },
      { name: 'day', options: dateOptions, selectedIndex: selectedDay - 1 },
      { name: 'year', options: yearOptions, selectedIndex: currentYear - selectedYear }
    ];
    return columns;
  }

  getMonthName(monthNumber) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];  
        return monthNames[monthNumber - 1];
  }

  close() {
    this.modalController.dismiss();
  }

  getReps(item) {
    return item.reps_commit
  }
  logaRep(routine: any, level: any, index: any) {
    mixpanel.track('Commitment-log review', {reviewMessage: this.message})
    if (level.is_unlocked === false) {
      this.tostrService.error(this.i18n.t('Please complete previous level.'))
    } else {
      localStorage.setItem('repLogText', this.message ? this.message : "");
      this.modalController.dismiss({
        routine: routine,
        level: level,
        index: index
      });
    }

  }

}
