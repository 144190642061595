import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { data } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { Option } from '../option/option.model';
import { Path } from '../path/path.model';
import { Poll, PollStats } from '../poll/poll.model';
import { PollService } from '../poll/poll.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../shared/popover/popover.component';
import { I18n } from '../i18n.service';
import { HomeUiService } from '../new-design/home-ui.service';

@Component({
  selector: 'first90-force-rank',
  templateUrl: './force-rank.component.html',
  styleUrls: ['./force-rank.component.scss']
})
export class ForceRankComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() poll: Poll
  @Input() isPreview: boolean
  @Input() inverted = false

  // @Output() dataEvent = new EventEmitter<any>();
  
  enabled = true // TODO: Get it from the path
  nameTQ: string // TODO: Get it from the path
  nameHYPA: string // TODO: Get it from the path
  path: Path
  // stats: PollStats
  stats: any
  status: string
  chart = []
  loading: boolean = true;
  isMobileWebIos = false;
  isMobileWeb=false;
  statsPer: number[]
  currentUser: User

  optionIds: number[]
  answerList: any
  listItems: any;
  finalizedOptionIds: string
  enableFinalizeButton: boolean

  constructor(
    private pollService: PollService,
    private appState: AppState,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    private tostrService: ToasterService,
    private cdRef: ChangeDetectorRef, // LUMBA-1308
    private popover:PopoverController,
    private i18n: I18n,
    public homeUIService: HomeUiService,
  ) {
    this.currentUser = this.appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    this.isEnabled()
    this.initNames()
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.loading = false;
    if (this.poll && this.poll.isCompleted) {
      this.enableFinalizeButton = false
    }else{
      this.enableFinalizeButton = true
    }
    
  }

  ngAfterViewInit() {
    if (this.poll.isCompleted) {
      // this.spinnerService.on()
      this.getRankAnswers(this.currentUser.id,this.path.id,this.poll.id)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['poll'] || changes['poll'].firstChange) { return false }
  }

  setStats() {
    if (!this.stats) {
      return
    }

    const total = this.stats.reduce((sum, n) => sum + n)
    if (!total) {
      return
    }

    this.statsPer = this.stats.map(n => Math.floor(n * 100 / total))
  }

  private isEnabled() {
    this.enabled = this.path?.enabledAttributes['polls']
  }

  private initNames() {
    this.nameTQ = this.path.getCustomText('p_tq')
    this.nameHYPA = this.path.getCustomText('p_hypa')
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  getRankAnswers(userId: number, pathId: number, questionId: number){
    const requestData = {
      'user_id': userId,
      'path_id': pathId,
      'force_question_id': questionId
    }

    this.pollService.getForceRankAnswers(requestData).subscribe(
      (response) => {
        this.enableFinalizeButton = false
        this.answerList = response.data.options
        // this.spinnerService.off()
        this.stats = response.data.stats
        this.setStats()
      },
      (error) => {
        // this.spinnerService.off()
        console.log('error :', error);
      }
    )
  }

  onRenderItems(event) {        
    this.listItems = this.poll.options
    let draggedItem = this.listItems.splice(event.detail.from,1)[0];
    this.listItems.splice(event.detail.to,0,draggedItem)
    event.detail.complete();

    let idsArray = []
    this.listItems.forEach(element => {
      idsArray.push(element)
    });

    this.optionIds = idsArray.map(item => item.id)
    this.finalizedOptionIds = '{' + this.optionIds + '}'
  }

  finalizeAnswer(){
    if(!this.finalizedOptionIds && !this.optionIds){
      this.optionIds = this.poll.options.map(item => item.id);
      this.finalizedOptionIds = '{' + this.optionIds + '}'
    }
    this.setRank(this.poll)
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  setRank(poll) {
    const requestData = {
      'path_id': this.path.id,
      'user_id': this.currentUser.id,
      'option_ids': this.finalizedOptionIds,
      'schedule_day': this.appState.get('currentScheduleDay'),
      'is_mandatory': poll.isMandatory,
      'force_question_id': poll.id,
      'enabled': poll.enabled,
    }
    if (!requestData.option_ids) {
      this.spinnerService.off()
    } else {
      this.spinnerService.on()
      this.pollService.setForceRank(requestData).subscribe(
        (response) => {
          this.appState.get('currentPath').update('polls', poll.id, { isCompleted: true })
          this.enableFinalizeButton = false
          this.spinnerService.off()
          this.getRankAnswers(this.currentUser.id, this.path.id, this.poll.id)
          this.cdRef.detectChanges()

        },
        (error) => {
          this.spinnerService.off()
          this.tostrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
        }
      )
    }
  }

  async presentPopover(ev) {
    const dynamicText = this.i18n.t('These numbers show the percentage of your colleagues who ranked each option #1.');
    const popover = await this.popover.create({
      component: PopoverComponent,
      translucent: true,
      event: ev,
      mode:'ios',
      showBackdrop: true,
      cssClass: 'force-rank-popover',
      componentProps: {
        dynamicText: dynamicText,
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }


}
