import gql from 'graphql-tag'
import { replyFields } from '../reply/reply.query'

export const questionFields = gql`
fragment questionFields on Question {
    id
    text
    preScreens
    postScreens
    startDay
    endDay
    screenOrder
    isMandatory
    enabled
    isPinned
    isLinked
    repliesEnabled
    actionPoints
    createdAt
    time
    colleaguesCount
    votesCounts
    
}
`

export const updateQuestion = gql`
mutation updateQuestion($question: QuestionInput!) {
  updateQuestion(questionInput: $question) {
    ...questionFields
  }
}
${questionFields}
`

export const replyQuestion = gql`
mutation replyQuestion($questionId: Int, $text: String!, $attachmentUrl: String, $mode: String,$userIds: [Int!],$routineAnswerId: Int) {
  replyQuestion(questionId: $questionId, text: $text, attachmentUrl: $attachmentUrl, mode: $mode,userIds:$userIds, routineAnswerId: $routineAnswerId) {
    status
    actionPoints
    reply {
      ...replyFields
    }
  }
}
${replyFields}
`


export const findQuestion = gql`
query findQuestion($id: Int!) {
  question(id: $id) {
    ...questionFields
  }
}
${questionFields}
`

export const listQuestions = gql`
query listQuestions($filter: FilterInput) {
  questions(filter: $filter) {
    ...questionFields
    createdAt
  }
}
${questionFields}
`

export const listPathQuestions = gql`
query listPathQuestions($filter: FilterInput) {
  pathQuestions(filter: $filter) {
    id
    pathId
    starts
    expires
    enabled
    question {
      ...questionFields
    }
    createdAt
  }
}
${questionFields}
`
