import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AlertUserLikeListComponent } from 'src/app/alert-user-like-list/alert-user-like-list.component';
import { AppState } from 'src/app/app.service';
import { CordovaService } from 'src/app/cordova.service';
import { RoutineService } from 'src/app/daily-content-v2/routine.service';
import { I18n } from 'src/app/i18n.service';
import { MobileChatServiceService } from 'src/app/pages/mobile-chat-board/mobile-chat-service.service';
import { Path } from 'src/app/path/path.model';
import { QuestionService } from 'src/app/question/question.service';
import { ReplyComponent } from 'src/app/reply/reply.component';
import { Reply } from 'src/app/reply/reply.model';
import { ReplyService } from 'src/app/reply/reply.service';
import { RoutineInfo } from 'src/app/routine-v2/routine-info.model';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { AnalyticsServiceService } from 'src/app/services/analytics-service.service';
import { SessionService } from 'src/app/session/session.service';
import { AttachmentUploaderComponent } from 'src/app/shared/attachment-uploader/attachment-uploader.component';
import { SpinnerService } from 'src/app/spinner.service';
import { TranslationService } from 'src/app/translation/translation.service';
import { WalletService } from 'src/app/wallet/wallet.service';
import { WINDOW } from 'src/app/window.service';
import { UserPublicProfileComponent } from '../../user-public-profile/user-public-profile.component';
import { expandOnEnterAnimation, fadeOutAnimation } from 'angular-animations';
import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
const DEFAULT_DURATION=120;
import mixpanel from 'mixpanel-browser';
import { HomeUiService } from 'src/app/new-design/home-ui.service';
import { MobileChatBoardComponent } from 'src/app/pages/mobile-chat-board/mobile-chat-board.component';

@Component({
  selector: 'app-habit-comment-list-web',
  templateUrl: './habit-comment-list-web.component.html',
  styleUrls: ['./habit-comment-list-web.component.scss'],
  animations: [
    expandOnEnterAnimation({ duration: 100 }),
    fadeOutAnimation({duration:100}),
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class HabitCommentListWebComponent implements OnInit {

  @Input() mode: string = "routine";
  @Input() isPreview: boolean;
  @Input() inverted: boolean = false;
  @Input() small = false;
  @Output() closed: EventEmitter<any>;
  @Output() itemSelected: EventEmitter<any>;
  @Output() onShowCommentList = new EventEmitter<any>();
  @Output() onTextClick = new EventEmitter<any>();
  @Output() onToggleLike = new EventEmitter<any>();
  @Output() onShowLikeList = new EventEmitter<any>();
  @Output() onComment = new EventEmitter<any>();
  @ViewChild('btn') btn: ElementRef;
  @ViewChild("uploader") uploader: AttachmentUploaderComponent;
  @ViewChild("replyBlock") replyBlock: ElementRef;
  @ViewChild("repliesContainer") repliesContainer: ElementRef;
  @ViewChild("textareaForFeeds") txtarea_post: ElementRef;
  @ViewChild('postContainer') postContainer;
  @ViewChild('replyComponent') replyComponent: ReplyComponent;
  @Input() data: any;
  @Input() focusSpecificCommentPost;
  @Input() isHomeRoute: boolean;
  @ViewChild('h1')
  h1: ElementRef;
  isTranslated;
  @ViewChild('textContainer') textContainer: ElementRef;
  flag;
  showComment=false;
  path: Path;
  rightState= false
  ishideComment: boolean = false;
  sortBy: string = "timestamp";
  replyText: string = "";
  cachedReplyText: string = ""; // LUMBA-1383
  attachmentUrl: string = null;
  errors: { answerError: false };
  replyTo: Reply = null;
  replyToComponent: ReplyComponent;
  replyToId: number;
  isLoading: boolean = false;
  replyOffset: number;
  selected: boolean = false;
  voteLoading: boolean = false;
  user: any;
  routine: RoutineInfo;
  tagName_id: number
  showReplies=false;
  dataOfUser: Observable<any>;
  namesData: any[]
  tempData: any
  state: boolean = false;
  animation = ['bounceIn'];
  isScrolledBottom: boolean = false
  textareaContentLength=0;
  color: string
  singleRoutineAnswer: any
  signleRoutine: any
  userDetails;
  public replies: Reply[]
  public repliesTree: any[] =[]
  public repliesIdsMap: Map<number, number>
  public masterRoutineAnswer: any
  isMobile: boolean
  check=false;
  isLoaded =true;
  recordsToShow =2;
  hideRecords
  fadeState
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;
  page;
  options = [
    {
      label: 'Other',
      animations: ['expandCollapse']
    }
  ]
  replyCount = 0;
  apiReplyCount = 0;
  pathRegistration
  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private toastrService: ToastrService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private spinnerService: SpinnerService,
    private cordovaService: CordovaService,
    public sessionService: SessionService,
    private mobileService: MobileChatServiceService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToastrService,
    private walletService: WalletService,
    private routineV2Service: RoutineV2Service,
    private nav: NavController,
    private routineService: RoutineService,
    private _bottomSheet: MatBottomSheet,
    public platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private keyboard: Keyboard,
    private popoverController:PopoverController,
    public render: Renderer2,
    private homeUiService: HomeUiService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.path = this.appState.get("currentPath");
    this.user = this.appState.get("currentUser");
    this.pathRegistration = this.appState.get("pathRegistration");
    this.page = 1;
    this.isMobile = this.appState.get('isMobile')
    this.appState.set('questionsLoaded', false)

  }

  ngOnInit() {
  
    this.isLoaded =false;

  if (this.data) {
    const data = {
      singleRoutine: this.data,
      mode: this.mode,
    };
    this.appState.set("singleRoutineFeed", data)
  }
    var dataOfRoutineFeeds = this.appState.get('singleRoutineFeed')
    this.singleRoutineAnswer = dataOfRoutineFeeds.singleRoutine
   
    this.mode = dataOfRoutineFeeds["mode"];
    this.appState.set("mode", this.mode);
    this.color = this.appState.get("routine_color")
    this.appState.set('oldActionPoints', this.walletService.actionPoints.value)
    this.routine = this.data
    this.data = this.data;
    this.singleRoutineAnswer =this.data;
    this.analyticsService.setScreen(AnalyticsServiceService.FULLROUTINE)
    this.appState.set('communityNotification', undefined)
    this.appState.set('notificationData', undefined)
    if (this.focusSpecificCommentPost?.id == this.data?.id && this.focusSpecificCommentPost?.type == this.data?.type && !this.isHomeRoute) {
    setTimeout(() => {
      this.postContainer?.nativeElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });;
      // this.postContainer.nativeElement.scrollTop = 130;

      this.onCommentClick();
    }, 500)
    }
  }
showCommentData(data) {
  let user ={
    email: data?.email,
   first_name: data?.first_name,
   id: data?.user_id,
   imageUrl: data?.image_url,
   last_name: data?.last_name
  }
  this.userDetails=user;
  var level1 = data.replies

  if (level1) {

    var subReply = level1.filter(item => item.repliableId)
    if(subReply) {
      subReply?.forEach(element => {
       level1?.forEach(reply => {
          let flag=0
          reply?.replies?.forEach((item)=>{
            if(item.id===element?.repliableId)
            {
               flag ++;
            }
          })
          if (element?.repliableId === reply.id && flag==0) {
            reply?.replies?.push(element)
            reply.replies = [...new Set( reply.replies)];
          }
          reply.timestamp = new Date(reply.createdAt)
        });
      });
    }
    this.replyCount = data?.reply_count;
    this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
    this.isLoaded =true;
    this.appState.set('repliesLength', this.repliesTree?.length)
    let isCommentDeleted = this.appState.get('commentDeleted')
    if (isCommentDeleted) {
      this.appState.set('commentDeleted', false)
    }
  }
  if(this.repliesTree?.length>1 && !this.isHomeRoute) {
    this.recordsToShow =2;

    this.render.addClass(this.repliesContainer?.nativeElement, 'hide_comment');
  }
  if(this.repliesTree?.length>1 && this.isHomeRoute) {
    this.recordsToShow =1;

    this.render.addClass(this.repliesContainer?.nativeElement, 'hide_comment');
  }
  this.spinnerService.off()

}
  async ngDoCheck() {
    let isCommentDeleted = this.appState.get('commentDeleted')    
    if (isCommentDeleted) {
      //this.page =1
      this.appState.set('commentDeleted', undefined)
      let id = this.appState.get('deletedPostId')
      if (id) {
        this.removeDeletedComment(id);   
      }
           
      //await this.getRoutineDetails(null,true)
    }
  }
removeDeletedComment(id) {
  if (id) {
      let deletedReply;
      console.log("replies tree before deleting", this.repliesTree, this.appState.get('repliesTreeObject'));
    this.repliesTree = this.appState.get('repliesTreeObject')
    if (this.replyCount == 0) {
      this.replyCount = this.data?.reply_count;
    }
    deletedReply = this.repliesTree?.findIndex((reply) => reply?.id === id);
    let deletedIdCount = this.repliesTree[deletedReply]?.replies?.length;
      if (deletedReply != -1 && deletedReply != undefined) {
        this.repliesTree.splice(deletedReply, 1);
        this.replyCount = this.replyCount - (deletedIdCount + 1);
         if (this.apiReplyCount > 0) {
          this.apiReplyCount -= (deletedIdCount + 1);
        } 
        //this.getRoutineDetails((deletedPost/10)+1);
      } else {
        for (const reply of this.repliesTree) {
          deletedReply = reply?.replies?.findIndex((reply) => reply?.id === id);
          if (deletedReply != -1 && deletedReply != undefined) {
            reply?.replies?.splice(deletedReply, 1);
            this.replyCount = this.replyCount - 1;
            if (this.apiReplyCount > 0) {
              this.apiReplyCount -= 1;
            }
          }
        }
      }
    this.singleRoutineAnswer.replies = this.repliesTree;
    this.data.replies = this.repliesTree;
    this.data.reply_count = this.replyCount;
    this.singleRoutineAnswer.reply_count = this.replyCount;
    //  if (this.apiReplyCount<=2) {
    //     this.select(true);
    //   }
      console.log("deleted post", deletedReply, this.repliesTree);
      this.appState.set('deletedPostId',null)
    }
  }
  ngAfterViewInit() {
    this.focusComment()
    window.scroll(0, 0);
    if(this.isMobile){
      if(this.platform.is('android')) {
       let id =document.getElementsByClassName('list-group')[0];
       id.setAttribute('style', 'margin-top:60px;')
        this.keyboard.onKeyboardDidShow().subscribe((info) => {
          this.replyBlock?.nativeElement?.style.setProperty(
            'bottom',
            `${info.keyboardHeight-20}px`
          );
          this.replyBlock?.nativeElement?.style.setProperty(
            'padding-bottom',
            `${276-(info.keyboardHeight-20)}px`
          );
        });
    
        this.keyboard.onKeyboardDidHide().subscribe(() => {
          this.replyBlock?.nativeElement?.style.setProperty(
            'bottom',
            `${0}px`
          );
          this.replyBlock?.nativeElement?.style.setProperty(
            'padding-bottom',
            `${0}px`
          );
        });
      }
    }
    if (!this.isMobile && !this.isHomeRoute) {
      if (this.focusSpecificCommentPost?.isFocus == false && this.focusSpecificCommentPost?.post?.id == this.data?.id) {
        setTimeout(() => {
          this.postContainer?.nativeElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });
          // this.postContainer.nativeElement.scrollTop = 130;
        },500)
        
      }
    }
  if(this.isHomeRoute) {
    setTimeout(() => {
      const container = this.textContainer?.nativeElement;
      const lineHeight = parseFloat(window.getComputedStyle(container).lineHeight);
      const containerHeight = container.clientHeight;  
      const lineCount = Math.floor(containerHeight / lineHeight);
         if (lineCount > 5) {
            this.isCollapsed = true;
            this.isCollapsable = true;
            let el = document.getElementsByClassName('text')[0]
            this.render.addClass(this.textContainer?.nativeElement, 'expand-texts');
         }
    },500)
  }
    console.log("called showCommetData");
    this.showCommentData(this.data)
  }
  onCommentClickInHome(event,singleRoutineAnswer) {
    event.stopPropagation();
    this.homeUiService.openNextComponent(MobileChatBoardComponent, {data:singleRoutineAnswer},'desktop-custom-modal')
  }
  onCommentClick(ev?,singleRoutineAnswer?) {
    if (!this.isHomeRoute) {   
      this.showReplies = false;
      this.replyTo = null;
      this.replyToId = null;
      this.showComment = !this.showComment;
      if (this.showComment) {
        if (!this.isMobile) {
          this.replyService.setPropertyValue(true);

        }
        setTimeout(() => {
          this.txtarea_post?.nativeElement?.focus();
        }, 500);
      }
      else {
        if (!this.isMobile) {
          this.replyService.setPropertyValue(null);

        }
      }
    }   
    else {
         this.onCommentClickInHome(ev, singleRoutineAnswer)
    }
  }
  showRecords() {
    let count = this.repliesTree.length-this.recordsToShow;
    this.hideRecords =this.recordsToShow;
    if(count>=10) {
     this.recordsToShow = this.recordsToShow+10;
    }
    else {
     this.recordsToShow = this.recordsToShow+count;
    }
   }
   select(val) {
    if(!val && this.recordsToShow ==2) {
      if(this.repliesTree.length>10){
        this.recordsToShow =10;
        this.hideRecords =0;
      }
      else {
        this.recordsToShow = this.repliesTree.length;
        this.hideRecords =0;
      }
    
      if (this.replyCount <= 2 && this.repliesTree.length>1 &&this.repliesTree.length<=2) {
          this.check = false;
        } else {
          this.check = true;
      }
      this.cdRef.detectChanges()
    } 
    else if (!val){
      if (this.replyCount > 1 && this.repliesTree.length <= 2) {
        this.recordsToShow = this.repliesTree.length;
        this.hideRecords = 0;
        if (this.replyCount <= 2 && this.repliesTree.length>1 &&this.repliesTree.length<=2) {
          this.check = false;
        } else {
          this.check = true;
     }
        this.cdRef.detectChanges()
      }
    }
    // if(this.recordsToShow>=10 && val) {
    
    //  this.recordsToShow= this.recordsToShow-10;
    //  this.hideRecords =this.recordsToShow-10;
    //  if(this.recordsToShow<10) {
    //    this.recordsToShow =2;
    //    this.hideRecords =0;
    //    this.check=false;
    //  }
    //  else {
    //    this.check=true;
    //  }
 
    // }
   else if(val){
     if(this.repliesTree.length <2){
      this.recordsToShow = this.repliesTree.length;
     }
     else {
       this.recordsToShow = 2;
     }
      this.hideRecords = 0;
      this.page = 1;
      this.apiReplyCount = 0;
     this.check=false;
     this.cdRef.detectChanges()
     this.postContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
     this.postContainer.nativeElement.scrollTop = 0;
    }
  if(!val && (this.repliesTree?.length>=1 && this.replyCount>1)) {
     this.render.removeClass(this.repliesContainer?.nativeElement, 'hide_comment');
  }
  if(val && !this.check) {
   
    this.render.addClass(this.repliesContainer?.nativeElement, 'hide_comment');
  }
     this.cdRef.detectChanges()
   }
  itemMentioned(tag) {
    console.log("selected ", tag);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.routine || changes.routine.firstChange) {
      return false;
    }
    this.sortRepliesBy(this.sortBy)
    this.cdRef.detectChanges();
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by;
    this.sortRepliesBy(this.sortBy)
  }
  orderingSubReplies(reply) {
    var level1 = [reply, ...this.repliesTree];
    let replySort;
    this.data.reply_count = this.data.reply_count + 1;
    if (level1) {
      var subReply = level1.filter(item => item.repliableId)
      level1?.forEach(reply => {
        subReply?.forEach(element => {
          if (element?.repliableId === reply.id) {
            reply.replies =[element, ...reply.replies]
            // reply.replies.push(element)
            //replySort =reply;
          }
          reply.timestamp = new Date(reply.createdAt)
        });
      });
      this.isLoaded = true;
    //   if (replySort) {
    //     replySort.timestamp = new Date(reply.createdAt);
    //   }
    //  let index =level1?.findIndex((reply) => reply.id == replySort.id)
    //   level1.splice(index,1)
      let repliesTree = level1.filter(reply => reply.id && reply.level === 1)
      this.repliesTree = repliesTree;
      this.data.replies = this.repliesTree;
    }
  }
  replyFun(text?) {
    if(this.textareaContentLength>0){
      this.isLoaded =false
    }
    if(text) {
      this.replyText =text.replyText;
    }
    if(!this.isMobile) {
      this.replyService.setPropertyValue(null);

    }
      this.showComment = false;
    this.showReplies =false;
    
    let formatedText;
    if (this.replyText.length == 0) {
      this.toastrService.error(this.i18n.t('Please enter a comment'), this.i18n.t('Error'))
      return false;
    }

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText, this.complexItems)
    mixpanel.track('Community-web-Comment reply', {'Comment post': this.replyText, 'post id': this.repliesTree[0]?.id, 'post text': this.repliesTree[0]?.text, 'post userId': this.repliesTree[0]?.user?.id, 'post type': this.repliesTree[0]?.type, 'post vote': this.repliesTree[0]?.hasVoted })
    console.log("Array of tags ", list_of_tags_ids);

    // if (this.isPreview) {
    //   return false
    // }
    // if (this.isLoading) {
    //   return false
    // }
    this.isLoading = true
    this.complexItems?.forEach(element => {
      let matchText = `@${element.username.toUpperCase()}`;
      if (this.replyText.includes(matchText)) {
        let replaceValue = `<span id="${element.id}">${element.username.toUpperCase()} </span>`;
        formatedText = this.replyText.replace(RegExp(matchText, 'g'), replaceValue);
      }
    });
    this.unloadReply();
    if (formatedText) {
      this.cachedReplyText = formatedText;
    }
    if (this.replyTo) {
      this.isLoaded =true;
      this.replyService
        .replyRoutine(this.singleRoutineAnswer, this.replyTo.id, this.cachedReplyText, this.attachmentUrl, list_of_tags_ids, 'routineInfo', this.singleRoutineAnswer.id)
        .subscribe(reply => {
           if (!this.isMobile) {
             this.select(false);
           }
          this.ishideComment = true;
         
          this.reset()
          this.isLoading = false
          this.appState.set('isReplyAnimationApplied',true);
          this.toastrService.success(this.i18n.t('Comment posted!'))
          this.replyTo = null;
          this.replyToId = null;
          this.orderingSubReplies(reply);
          //  this.repliesTree = this.repliesTree.concat(reply);
          // this.getRoutineDetails()
          this.isLoading = true;
          this.replyComponent.replyText = '';
          // this.loadReply()
          this.cdRef.detectChanges()
          if (this.repliesContainer != undefined) {
              this.postContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
              this.postContainer.nativeElement.scrollTop = 0;
            }
          // this.postContainer.nativeElement.scrollTop = 130;
        },
          (error) => {

            this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'), this.i18n.t('Error'))
            this.isLoading = false
            this.loadReply()
            this.cdRef.detectChanges()
          })
    } else {
      this.questionService
        .replyRoutineAnswer(this.singleRoutineAnswer, this.cachedReplyText, this.attachmentUrl, list_of_tags_ids, "routine")
        .subscribe(reply => {
          this.appState.set('currentActionPoints', reply?.data?.replyQuestion?.actionPoints)
          this.ishideComment = true;
          this.reset()
          this.isLoading = false
          this.appState.set('isAnimationApplied',true);
          this.replyTo = null;
          this.replyToId = null;
          this.loadReply()
          this.orderingSubReplies(reply.data.replyQuestion.reply);
          //  this.repliesTree = [reply.data.replyQuestion.reply, ...this.repliesTree];
           this.isLoading = true;
          // this.getRoutineDetails()
          this.cdRef.detectChanges()
          this.toastrService.success(this.i18n.t('Comment posted!'))
          
        }, error => {
          console.log(error)
          this.loadReply()
          this.cdRef.detectChanges()
          this.isLoaded = true;
          this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'), this.i18n.t('Error'))
          this.isLoading = false

        })
    }
  }

  translate($event){
    let toggleEnabled = $event.detail.checked

    if (toggleEnabled === true) {
      this.isTranslated =true;
      this.flag =false;
      this.path = this.appState.get("currentPath");
      const repliesIds = this.repliesTree.map((reply) => reply.id);
      this.translationService
        .loadRepliesTranslations(repliesIds, this.path.language)
        .subscribe((translations) => {
          this.repliesTree.forEach((reply) => {
            const key = `Reply:${reply.id}`;
            reply.text = translations[key].text;
            this.isTranslated =false;
            this.flag = true
            this.cdRef.detectChanges()
          });
        });
    }

    else {
      this.flag = false;
      this.appState.set('commentDeleted', true)
      this.cdRef.detectChanges()
    }
  }

  reset() {
    this.replyText = "";
    this.attachmentUrl = null;
    if (this.uploader) {
      this.uploader.reset();
    }
    this.cachedReplyText = "";
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText;
      this.cachedReplyText = "";
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText;
    this.replyText = "";
  }
  checkTextareaLength() {
    let len = this.replyText?.trim();
   this.textareaContentLength =len?.length;
}
  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset();
    }
    this.showReplies =true ;
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
      //this.replyToComponent.selected = false;
    }
    this.showComment=false
    if(replyComponent?.selected){
    
      if(this.showReplies) {
        setTimeout(()=>
        {this.txtarea_post?.nativeElement.focus();
        },500);    }
    }
    else {
      this.showReplies =false;
    }
    this.replyToComponent = replyComponent;
    if (replyComponent) {
      this.replyTo = replyComponent.reply;
      this.replyToId = this.replyTo.id;
    } else {
      this.replyTo = null;
      this.replyToId = null;
    }
  }

  refresh() {
    console.log(this.router.url);
    this.apollo.getClient().clearStore();
    this.appState.refreshMainComponent.emit();
  }

  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      console.log("click event");
      console.log(this.replyBlock?.nativeElement.offsetTop);

      setTimeout(() => {
        window.scrollTo({
          top: this.replyBlock?.nativeElement.offsetTop - 160,
          behavior: "smooth",
        });
      }, 10);
    }
  }

  close() {
    this.rightState=true;
    this.dialog.closeAll();

  }

  totalLikes(items) {
    return items.reduce((a, b) => +a + +b.votescount, 0);
  }

  clickOnInput() {
    this.txtarea_post?.nativeElement.focus();
  }

  toggleVote(reply) {

    this.bounceAnimation()
    if (this.isHomeRoute) {
      this.doLikeUnlike();
    }
    else {
      this.mobileService.setIsLikeList(true)
      this.mobileService.setPostId(reply?.id)
      this.mobileService.setMode(this.mode)
      this.voteLoading = true;
      reply.hasVoted = !reply.hasVoted;
      reply.votescount += reply.hasVoted ? 1 : -1;
      if (reply.hasVoted) {
        reply.likes?.push(this.user._data);
        this.data.likes = reply.likes;
        this.singleRoutineAnswer.likes = reply.likes;

      }
      else {
        reply.likes?.forEach((user, index) => {
          if (user.id === this.user?._data.id)
            reply.likes?.splice(index, 1);
        });
        this.data.likes = reply.likes;
        this.singleRoutineAnswer.likes = reply.likes;
      }
      this.cdRef.detectChanges();
      this.replyService.voteReply(reply, "Routine").subscribe(
        (status) => {
          this.voteLoading = false;
          this.cdRef.detectChanges();
        },
        (error) => { },
        () => {
          this.voteLoading = false;
          this.cdRef.detectChanges();
        }
      );
    }  
  }

  complexItems = this.appState.get("userlist")

  tempValue = 0
  index = 0

  format(item: any) {
    var data = item['id']
    setTimeout(() => {
      SessionService.setData(data)
      console.log("Format data ", SessionService.getData());
    }, 100);
    return `@${item['username'].toUpperCase()}`;
  }

  mentionConfig: any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp: true
      }
    ]
  };


  focusComment() {
    if (this.appState.get('commentClicked')) {
      this.appState.set('commentClicked', false)
        this.txtarea_post?.nativeElement.focus();
     
    }
    else {
      this.txtarea_post?.nativeElement.blur();
    }
  }

  bounceAnimation() {
    this.render.addClass(this.btn?.nativeElement, 'bounce');
    if(this.btn?.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
      this.render.setAttribute(this.btn?.nativeElement.querySelector('ion-icon'),"name", 'star')
    }
    else {
      this.render.setAttribute(this.btn?.nativeElement.querySelector('ion-icon'),"name", 'star-outline')

    }
    setTimeout(() => {
      this.render.removeClass(this.btn?.nativeElement, 'bounce');
    }, 200);
  }

  showLikeList(postId: number, mode: string) {
    this.mobileService.setIsLikeList(true)
    this.mobileService.setPostId(postId)
    this.mobileService.setMode(mode)
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');   
  }
  doLikeUnlike() {
    mixpanel.track('Community-web-Habit post', {'post discription': this.singleRoutineAnswer.routine_description, 'post title': this.singleRoutineAnswer.title, 'post type': this.singleRoutineAnswer.type, 'post id': this.singleRoutineAnswer.id, 'post userid': this.singleRoutineAnswer.user_id, 'post vote': this.singleRoutineAnswer.hasVoted })
      this.render.addClass(this.btn?.nativeElement, 'bounce');
      if (this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
        this.render.setAttribute(this.btn?.nativeElement.querySelector('ion-icon'), "name", 'star')
      }
      else {
        this.render.setAttribute(this.btn?.nativeElement.querySelector('ion-icon'), "name", 'star-outline')

      }
      setTimeout(() => {
        this.render.removeClass(this.btn?.nativeElement, 'bounce');
      }, 200);
    
    this.onToggleLike.emit({
      item: this.singleRoutineAnswer
    })
  }
  emitShowLikeList(){
    this.onShowLikeList.emit(this.singleRoutineAnswer.id);
  }

  showCommentList(mode: string, id: number) {

    const requestUserList = {
      id: id,
      path_id: this.path.id,
      mode: mode
    }
    this.mobileService.setIsLikeList(false)
    this.mobileService.setRequestUserList(requestUserList)
    this.homeUiService.openNextComponent(AlertUserLikeListComponent,'','alert-like-modal');
    // var dialog = this.dialog.open(AlertUserLikeListComponent, {
    //   panelClass: 'custom-dialog-container'
    // }
    // )
  }

  getRoutineText(routine) {
    if (routine.routineAnswer && routine.routineAnswer.routineDescription) {
      return routine.routineAnswer.routineDescription;
    } else {
      return ''
    }
  }

  getImageUrl(item) {
    if (item && item.user && item.user.image_url) {
      return item.user.image_url
    } else {
      return ''
    }
  }

  getUserName(item) {
    if (item && item.user && item.user.first_name && item.user.last_name) {
      return item.user.first_name + item.user.last_name
    } else {
      return ''
    }
  }



  @HostListener('click', ['$event'])
  onLinkClick(e) {
    console.log("Link data ", e);

    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
    if (e.target.tagName.toLowerCase() === 'span') {
      this.getUserData(e, e.target.id);
    }
  }
  getUserData(event, id) {
    this.routineService.getUserDetailsById(id).subscribe(value => {
      this.openuserInfo(event, value.data[0]);
    });
  }
  async openuserInfo(ev, userData): Promise<void> {
    if (!this.isHomeRoute) {
      const popover = await this.popoverController.create({
        component: UserPublicProfileComponent,
        event: ev,
        mode: "ios",
        translucent: false,
        componentProps: { user: userData },
      });
      await popover.present();
    }     
  }

  onClick(): void {
    this.h1?.nativeElement.scrollIntoView({
      top: this.h1?.nativeElement.offsetTop,
      behavior: 'smooth',
      block: 'nearest'
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 100) {
      this.isScrolledBottom = true
    } else {
      this.isScrolledBottom = false
    }
  }

  async getRoutineDetails(val?,isPostDeleted=false): Promise<any> {
    if (!this.singleRoutineAnswer) {
      this.spinnerService.off()
      return
    }
   return new Promise( async (resolve, reject) => {
    try {
      const data = {
        id: this.singleRoutineAnswer.routine_info_id,
        routine_answer_id: this.singleRoutineAnswer.id,
        mode: this.singleRoutineAnswer.type,
        path_id: this.path.id,
        page:this.page
      };
      await this.routineV2Service.getAllPostsRepliyDetails(data).subscribe(
        (response) => {
          if(this.appState.get('isAnimationApplied'))
          {
            this.isLoaded =true;
          }
          var level1;
             if(this.page ==1 || isPostDeleted) {
                this.apiReplyCount = 0;
               this.repliesTree = [];
             }
            if (this.page == 1 && response.data.replies?.length > 0) {
              this.repliesTree = [];
              level1 = response.data.replies;
            }
            else if (response.data.replies?.length > 0) {
              level1 = this.repliesTree.concat(response.data.replies);
            }          
          this.userDetails = response.data.user;
          this.replyCount = response.data.reply_count;
          this.data.votescount = response.data?.voteCount;
          this.data.reply_count = response.data?.reply_count;
          this.apiReplyCount = this.apiReplyCount + response.data.replies?.length;
          this.singleRoutineAnswer.votescount= response.data?.voteCount;
          this.singleRoutineAnswer.reply_count = response.data?.reply_count;
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1?.forEach(reply => {
              subReply?.forEach(element => {
                if (element?.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });

            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            this.cdRef.detectChanges();
            // if (this.page == 1 && repliesTree?.length) {
            //         this.repliesTree = [];
            //   this.repliesTree = this.repliesTree.concat(repliesTree);
            // } else if (repliesTree?.length > 0) {
            //   this.repliesTree = this.repliesTree.concat(repliesTree);
            // }
            this.page = this.page + 1
            if (val == false) {
              this.select(val)
            }
            else if (val == true) {
              this.showRecords();
            }
            this.singleRoutineAnswer.replies = this.repliesTree;
            this.data.replies =this.repliesTree;
            console.log("repliescount", this.repliesTree)
            this.appState.set('repliesLength', this.repliesTree?.length)
            let isCommentDeleted = this.appState.get('commentDeleted')
            if (isCommentDeleted) {
              this.appState.set('commentDeleted', false)
            }
          }
         
          this.spinnerService.off()
        },
        (error) => {
          console.log('error', error)
          if(this.appState.get('isAnimationApplied'))
          {
            this.isLoaded =true;
          }
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
          this.spinnerService.off()
        }
      )
      // this.cdRef.detectChanges();
      resolve(true);
    } catch (error) {
      console.log('error', error)
      if(this.appState.get('isAnimationApplied'))
      {
        this.isLoaded =true;
      }
      reject(error);
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      this.spinnerService.off()
    }

  });
  }

  sortRepliesBy(key = 'timestamp') {
    this.repliesTree.sort((a, b) => a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0)
    this.cdRef.detectChanges();
  }

  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        if(response.status){
          this.replyText = response.data.text
          this.cdRef.detectChanges()
          this.toastrService.success(this.i18n.t('Post translated successfully!'))
        }
      },
    (error) => {
      this.toastrService.error(this.i18n.t('Text translation failed!'))
      console.log("TranslationService data ",error);
    }
    )
  }
}
