import { ChangeDetectorRef,Component, Input } from '@angular/core'

import { Document } from '../document/document.model'
import { DocumentService } from '../document/document.service'
import { CordovaService } from '../cordova.service'
import {TooltipPosition} from '@angular/material/tooltip';
import {FormControl} from '@angular/forms';
import { environment } from "src/environments/environment";
import { AppState } from '../app.service'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Platform } from '@ionic/angular'
import { YoutubeVideoPlayer } from '@awesome-cordova-plugins/youtube-video-player/ngx'
import { SessionService } from '../session/session.service'
import mixpanel from 'mixpanel-browser'
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SpinnerService } from '../spinner.service';
import { HomeUiService } from '../new-design/home-ui.service';


@Component({
  selector: 'first90-document-v2',
  templateUrl: './document-v2.component.html',
  styleUrls: ['./document-v2.component.scss']
})
export class DocumentV2Component {
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  @Input() document: Document
  @Input() isPreview: boolean

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[3]);

  presignedUrl: any
  isMobile: any
  pdfSrc: any;

  constructor(
    private socialSharing: SocialSharing,
    private appState: AppState,
    private documentService: DocumentService,
    private cordovaService: CordovaService,
    private platform: Platform,
    private youtubePlayer: YoutubeVideoPlayer,
    private sessionService: SessionService,
    private transfer: FileTransfer, 
    private file: File,
    private spinnerService: SpinnerService,
    public homeUIService: HomeUiService,
    private cdRef: ChangeDetectorRef,) {
    this.isMobile = this.appState.get('isMobile');
    this.presignedUrl = environment.preSignedUrl
   }

  view (url: string) {
    mixpanel.track('In Steps-video url', {'url': url})
    if (this.cordovaService.onCordova) {
      if (this.sessionService.confirmYoutubeUrl(url)) {
        console.log('Youtube video :');
        if (this.platform.is('ios')) {
          try {
            console.log("in try block");
            this.youtubePlayer.openVideo(this.sessionService.getYouTubeVideoIdFromUrl(url));
          }
          catch (err) {
            console.log("catch_block", err);
          }
        } else {
          console.log('in else block');
          this.cordovaService.openBrowserLinkVideo(url);
        }
      } else {
        console.log('Last else :',);
        if (this.videoFormats.some(el => url.includes(el))) {
          this.cordovaService.openBrowserLinkVideo(url);
        }else{
          this.cordovaService.openLinkInBrowser(url)
        }
      }

    } else {
      window.open(url, '_blank')
    }
    if ( !this.isPreview ) {
      this.documentService.viewDocument(
        this.document.id
      ).subscribe(
        () => {
        }
      )
      this.document.isCompleted = true
    }
  }

  openShareDropdown(event) {
    event.stopPropagation()
    event.target.closest('.dropdown').classList.toggle('open')
  }

  share(event, document) {
    event.stopPropagation()
    let isPresignedURL = document.link.includes(this.presignedUrl)
    // if (this.isMobile && isPresignedURL) {
    //   this.sharingSocialApp(document.title, document.link, document.link + ' \n \n Note: This link will expire in 7 days.')
    // } else if (this.isMobile && !isPresignedURL) {
    //   this.sharingSocialApp(document.title, document.link, document.link)
    // } else {
    //   this.openMailTo(isPresignedURL, document.title, document.link)
    // }
    if(this.isMobile){
      try {
        this.downloadFile(document.link)
      } catch (error) {
        console.log('error :', error);
      }
    }else{
      this.openMailTo(isPresignedURL, document.title, document.link)
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  downloadFile(url) {
    console.log('document url :', url);
    if (url.includes(".pdf")) {
      const transfer = this.transfer.create();
      let path = null;
      const name = url.substr(url.lastIndexOf("/") + 1);
      const fileName = name.split("?")[0];
      if (this.platform.is("ios")) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.dataDirectory;
      }
      transfer.download(url, this.file.dataDirectory + fileName).then((entry) => {
        this.pdfSrc = entry.toURL()
        console.log('this.pdfSrc :', this.pdfSrc);
        if (this.pdfSrc) {
          this.socialSharing.share("", "", this.pdfSrc, "")
            .then((entries) => {
              console.log('success ' + JSON.stringify(entries));
            })
            .catch((error) => {
              console.log('error ' + JSON.stringify(error));
            });
        }
      }, (error) => {
        console.log('download failed: ', error);
      });
    } else {
      this.socialSharing.share("", "", "", url)
        .then((entries) => {
          console.log('success ' + JSON.stringify(entries));
        })
        .catch((error) => {
          console.log('error ' + JSON.stringify(error));
        });
    }
  }

  // sharingSocialApp(subject, body) {
  //   // this.socialSharing.share(body, subject, "", "")
  //   //   .then((entries) => {
  //   //     console.log('success ' + JSON.stringify(entries));
  //   //   })
  //   //   .catch((error) => {
  //   //     alert('error ' + JSON.stringify(error));
  //   //   });
  //   try {
  //     if (this.platform.is('android')) {
  //       const email = {
  //         to: '',
  //         subject: subject,
  //         body: body,
  //         isHtml: false
  //       };
  //       this.emailComposer.open(email)
  //         .then(() => console.log('Shared via Gmail'))
  //         .catch((error) => console.error('Error sharing via Gmail', error));
  //     }
  //     if (this.platform.is('ios')) {
  //       this.socialSharing.shareViaEmail(body, subject, []).then(() => {
  //         console.log('Shared successfully')
  //       }).catch((error) => {
  //         console.log('error Sharing ', error)
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error inside catch ', error)
  //   }
  // }

  openMailTo(isPresignedURL,subject, body) {
   if (isPresignedURL) {
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.')

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body + ' \n \n Note: This link will expire in 7 days.'))

      return true
    }else{
      location.href = 'mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body)

      this.cordovaService.openMail('mailto:%20' +
        '?subject=' + encodeURIComponent(subject) +
        '&body=' + encodeURIComponent(body))

      return true
    }

    
  }
}
