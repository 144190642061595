import gql from 'graphql-tag';


export const routineFields = gql`
  fragment routineFields on RoutineInfo {
    id
    title
    enabled
    startDay
    endDay
    screenOrder
    actionPoints
    votesCounts
    user
    isMandatory
    routineAnswerList
    levels{
      id
      text
      reps
      title
      level
      enabled
      repsData
      isLevelCompleted
      isNotificationSent
    }
    routineAnswer{
      id
      routineInfoId
      routineType
      day
      typeValue
      typeLevel
      isUnlocked
      isMandatory
      routineTime
      activityTime
      commitCount
      routineDescription
      routineDate
    }
  }`

  export const updateRoutine = gql`
  mutation updateRoutineInfo($routineInfo:RoutineInfoInput!) {
    updateRoutineInfo(routineInfoInput:$routineInfo) {
      ...routineFields
    }
  }
  ${routineFields}
  `
  export const findRoutineInfo = gql`
  query findRoutineInfo($id: Int!) {
    routineInfo(id: $id) {
      ...routineFields
    }
  }
  ${routineFields}
  `

export const listRoutineInfos = gql`
query listRoutineInfo($filter: FilterInput) {
  routinesInfo(filter: $filter) {
    ...routineFields
  }
}
${routineFields}
`

export const answerRoutine = gql`
mutation answerRoutine($pathId: Int!, $routineInfoId: Int!, $userId: Int!, $isMandatory: Boolean!,
    $enabled: Boolean!, $routineType: String!, $typeValue: String!, $typeLevel: Int!, $isUnlocked: Boolean!,
    $routineTime: [String!], $activityTime: String!, $day: [String!], $routineDescription: String!, $routineColor: String!,
    $scheduleDay: Int!, $isOpted: Boolean!,$routineDate: [String!],$levelIds: [Int!]){
      answerRoutine(pathId: $pathId, userId: $userId, routineInfoId: $routineInfoId, isMandatory: $isMandatory,
        enabled: $enabled, routineType: $routineType, typeValue: $typeValue, typeLevel: $typeLevel, isUnlocked: $isUnlocked,
        routineTime: $routineTime, activityTime: $activityTime, day: $day, routineDescription: $routineDescription, routineColor: $routineColor,
        scheduleDay: $scheduleDay, isOpted: $isOpted, routineDate: $routineDate,levelIds: $levelIds) {
        status
        routineAnswer{
          routineInfoId
          day
          routineDescription
        }
      }
}
`