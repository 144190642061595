import { DataProxy } from 'apollo-cache'
import { get, del } from 'object-path'
import { InMemoryCache } from 'apollo-cache-inmemory'

export function newOrNull(klass, data) {
  return data === null ? null : new klass(data)
}

// Decorator for mixins https://www.typescriptlang.org/docs/handbook/mixins.html
export function Mixin(baseCtors: any[]) {
  return function (derivedCtor: any) {
    baseCtors.forEach(baseCtor => {
      Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
        derivedCtor.prototype[name] = baseCtor.prototype[name]
      })
    })
  }
}

export function hexToRgb(hex, alpha = null) {
  hex = hex.replace('#', '')
  var r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16)
  var g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16)
  var b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16)
  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

/**
 * CREDITS:
 * https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors/13542669#13542669
 *
 * @param color #f0cd61
 * @param percent -O.2
 */
export function shadeColor(color, percent) {
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = f >> 8 & 0x00FF, B = f & 0x0000FF

  return '#' +
    (0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    ).toString(16).slice(1)
}

/**
 * Apollo cache invalidator based on key.
 *
 * @param {DataProxy} data The data object (e.g. apollo cache store).
 * @param {String} path Path to the field needed to invalidate, dot separated (e.g. `ROOT_QUERY.someInerestingQuery`)
 * @param {RegExp} keyRegExp Regular expression to match all keys under selected path e.g. /^Client:[\d+]/
 * @return {Boolean} Key was existed and invalidated
 */
export function invalidateCache({data: {data}}: any, path: any, keyRegExp: RegExp = null) {
  if (!keyRegExp) {
    del(data, path)
  } else {
    let pointer = get(data, path)
    if (typeof pointer === 'object') {
      for (let key in pointer) {
        if (keyRegExp.test(key)) {
          del(data, [path, key])
        }
      }
    }
  }
}

// LUMBA-1325
export function detectIE() {
  const ua = window.navigator.userAgent

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}
