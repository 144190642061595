import { ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertAskRoutineQuestionComponent } from 'src/app/alert-ask-routine-question/alert-ask-routine-question.component';
import { AlertGiveFeedbackCommitmentComponent } from 'src/app/alert-give-feedback-commitment/alert-give-feedback-commitment.component';
import { AlertRepeatRoutineComponent } from 'src/app/alert-repeat-routine/alert-repeat-routine.component';
import { AppState } from 'src/app/app.service';
import { RoutineService } from 'src/app/daily-content-v2/routine.service';
import { Habit } from 'src/app/habit/habit.model';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { Question } from 'src/app/question/question.model';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToasterService } from 'src/app/toaster.service';
import * as _ from 'underscore';
import * as confetti from 'canvas-confetti';
import { AlertReviewRoutineComponent } from 'src/app/alert-review-routine/alert-review-routine.component';
import { AlertNotificationUpdateComponent } from 'src/app/alert-notification-update/alert-notification-update.component';
import * as moment from 'moment';
import { AlertAnimationComponent } from 'src/app/alert-animation/alert-animation.component';
import { WeekendCalendarComponent } from 'src/app/routine-v2/weekend-calendar.component';
import { AlertConfirmNotificationComponent } from 'src/app/alert-confirm-notification/alert-confirm-notification.component';
import { DailyContentV2Component } from 'src/app/daily-content-v2/daily-content-v2.component';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import mixpanel from 'mixpanel-browser';
import { slideInLeftAnimation, slideInRightAnimation } from 'angular-animations';
import { UpdatedHomeComponent } from '../updated-home/updated-home.component';
import { StatService } from 'src/app/stat/stat.service';
import { Tag } from 'src/app/inout-screen/inout-screen.model';
import { AllPathsComponent } from 'src/app/all-paths/all-paths.component';
import { AnimationsService } from 'src/app/shared/animations.service';
import { AlertController, ModalController } from '@ionic/angular';
import { YourPathComponent } from '../your-path/your-path.component';
import { I18n } from 'src/app/i18n.service';
import { HomeUiService } from '../home-ui.service';
import { browserRefresh } from 'src/app/app.component';
import { UtilityService } from 'src/app/common-services/utility.service';
import { SessionService } from 'src/app/session/session.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-daily-steps-tracker-new',
  templateUrl: './daily-steps-tracker-new.component.html',
  styleUrls: ['./daily-steps-tracker-new.component.scss'],
  animations: [
    slideInLeftAnimation({ duration: 400 }),
    slideInRightAnimation({ duration: 400 }),
  ],
})
export class DailyStepsTrackerNewComponent implements OnInit,DoCheck {
  @ViewChild("updatedHome") updatedHome:UpdatedHomeComponent
  @ViewChild("stepTitle") stepTitle: ElementRef;
currentDay;
scheduleDay;
currentUser;
allPathsComponent = AllPathsComponent;
yourPathComponent = YourPathComponent;
currentPath;
allPathsCount;
pathRegistration;
activeDay;
scheduledStep;
isDoneButton: boolean = false;
isMobile
currentStep;
totalSteps;
titleTag: Tag;
path;
canvas;
stepsArray;
client;
inoutScreenData;
durationOfTask;
enabledAttributes;
thisPathUserRank;
thisPathUser
pathGroupId: any;
routineVersion: boolean;
scheduleDays: number[];
currentDayStarted: boolean;
currentDayCompleted: boolean;
allContentCompleted: boolean;
dayHasIncompleteMandatory;
showCloseoutWelcomeScreen;
dayHasContent;
selectedPath;
isLoading=false;
allLevelsCompleted;
isFullCardView
subPathName;
isSlideLeft;
task;
questionData: Question[];
documentData: Document[];
pollData: any[];
surveyData: any[];
hadbitData: Habit[];
maxOneNumbers;
maxTwoNumbers;
lengthOfSnoozed;
routineList;
maxData;
lastStep=0;
timezone;
selectedTimings;
showContinueButton: boolean;
  showReplayButton: boolean;
  showBeginButton: boolean;
  showCompletedMessage: boolean;
  showUnlockButton: boolean = false;
  showPrev: boolean = false;
  activeCloseoutWelcomeScreen
  dayNumber;
  maxAllowedScheduleDay;
  previousDayCompleted
  rightState;
  state;
  isTodayTask;
  makeHabit;
  counterId;
  stats: any;
  browserRefresh:boolean;
  badgesCount
  dailyStepsArray;
  destroyed$ = new Subject<boolean>();
  
constructor(private appState: AppState, private homev5Service: HomeV5Service, 
  private router: Router, private dialog: MatDialog,private spinnerService: SpinnerService, 
  private routineService: RoutineV2Service,private cdRef: ChangeDetectorRef, private statService: StatService,
  private elementRef: ElementRef, private renderer: Renderer2,
  private dailyStepService: DailyStepsService,
  private animationService: AnimationsService,
  private alertController: AlertController,
  private i18n: I18n,
  private render: Renderer2,
  private utilService: UtilityService,
  private sessionService: SessionService,
  public homeUIService: HomeUiService,
  private modalController: ModalController,
  private toasterService: ToasterService) {
    this.currentUser = this.appState.get('currentUser')
    this.isMobile = this.appState.get('isMobile')
    this.init();
    this.browserRefresh = browserRefresh;
    if(this.browserRefresh){
      
      let currentScreenIndex
      if (localStorage.getItem("currentScreenIndexAfterScreenView")) {
        currentScreenIndex = JSON.parse( localStorage.getItem('currentScreenIndexAfterScreenView'));
      }
      let scheduleDay ;
      if (localStorage.getItem("currentDayAfterScreenView")) {
        scheduleDay = JSON.parse(localStorage.getItem('currentDayAfterScreenView'));
      }
       let currentDay;
      if (this.appState.get("currentScheduleDay")) {
        currentDay = JSON.parse(this.appState.get("currentScheduleDay"));
      }
      let screensIds 
      if (localStorage.getItem("screensIdsAfterScreenView")) {
        screensIds = JSON.parse(localStorage.getItem('screensIdsAfterScreenView'));
      }
    let allowScreenCount =true;
    let screenStatus =localStorage.getItem('screenStatus');
    this.appState.set('screenStatus', screenStatus);
    let dailyStepsArray= this.appState.get("DailyStepsData");
      if (currentScreenIndex && scheduleDay ==currentDay && dailyStepsArray[scheduleDay-1]?.stepStatus!='Review') {
        this.utilService.setScreenData(currentScreenIndex, scheduleDay, screensIds)
        this.utilService.setScreenCount()
      }

      this.appState.set('allowScreenCount',true);

      if (this.appState.get('routine_schedule_day')) {
        this.appState.set('routine_schedule_day', null)
      }
    }

    this.sessionService.afterRefreshEvent.subscribe(() => {
      this.maxAllowedScheduleDay = this.appState.get("daysOfContent"); // LUMBA-1379
      this.scheduleDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
      this.switchScheduleDay(this.scheduleDay);
    });
   }
init() {
   this.allPathsCount =  this.appState.get('allPathCount')
   this.dailyStepsArray= this.appState.get("DailyStepsData");
  this.path = this.appState.get("currentPath");
  this.lastStep = this.appState.get("lastStep");
   this.currentPath = this.appState.get('currentPath')
    this.currentDay = this.appState.get('currentScheduleDay')
    this.maxAllowedScheduleDay = this.appState.get("daysOfContent");
  // this.scheduleDay = +this.appState.get('currentScheduleDay')
    this.pathRegistration = this.appState.get('pathRegistration')
 
    this.inoutScreenData = this.findForCurrentDay(this.currentPath.inoutScreens)
    this.client = this.appState.get("currentClient");
    this.currentUser = this.appState.get("currentUser");
    this.scheduleDay = +this.appState.get("currentScheduleDay");
    this.activeDay = this.scheduleDay;
    this.enabledAttributes = this.appState.get("routineVersion");
    this.pathGroupId = localStorage.getItem("pathGroupId");
    if (this.enabledAttributes.routineVersion) {
      this.routineVersion = this.enabledAttributes.routineVersion;
    } else {
      this.routineVersion = false;
    }
    this.appState.set("clientImage", this.client.imageUrl);
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.scheduleDays = new Array(this.path.daysOfContent)
      .fill(0)
      .map((item, index) => index + 1);
    this.currentDayStarted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${this.scheduleDay}`
    );
    this.currentDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${this.scheduleDay}`
    );
    this.allContentCompleted = this.appState.get("allContentCompleted");
    this.dayHasIncompleteMandatory = this.path.checkMandatoryForDay(
      this.scheduleDay
    ); // LUMBA-1317
    this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;

    this.dayHasContent = this.path.checkContentForDay(this.scheduleDay);
    // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content
    // Check whether localdata is present or not if not then check how many steps are completed
    if (!this.currentDayStarted || !this.currentDayCompleted) {
      let stepsArray = this.homev5Service.removeDuplicates(
        this.pathRegistration?.screensViewed,
        "step"
      );
      stepsArray.forEach((element) => {
        if (
          element.step == this.scheduleDay &&
          element.visitedScreens < element.totalScreens
        ) {
          this.currentDayStarted = true;
        } else if (
          element.step == this.scheduleDay &&
          element.visitedScreens == element.totalScreens
        ) {
          this.currentDayStarted = true;
          this.currentDayCompleted = true;
        }
      });
    }

    this.homev5Service.getDailySteps(this.scheduleDay, this.path);

    if (this.scheduleDay === this.scheduledStep) {
     
      this.showCompletedMessage =
        !this.dayHasIncompleteMandatory && this.dayHasContent;

      this.showContinueButton = this.homev5Service.checkScreenCount(
        this.pathRegistration,
        this.scheduleDay
      )
      this.showReplayButton = this.homev5Service.checkReplayForNonMand(
        this.pathRegistration,
        this.scheduleDay
      )
      this.showBeginButton = this.homev5Service.checkPrevDayCompletedForNonMand(
        this.pathRegistration,
        this.scheduleDay,
        this.currentUser,
        this.path,
        this.currentDayStarted
      )
      
    } else {
      this.showCompletedMessage = this.dayHasContent;
      this.showContinueButton = false;
      this.showReplayButton = this.dayHasContent;
      this.showBeginButton = false;
    }
 
    this.isDayLocked(this.activeDay);
    this.todayTask();
    this.setDataToAllTask();
    this.sortArrayObject();

    this.isFullCardView = new Array(this.highestLengthAllTaskObject());
    this.setAllTaskToMinimize(this.highestLengthAllTaskObject());
    this.subPathName = this.path?.name;
   
  }
  async openComponent(comp){
    console.log("comp", comp)
    if (comp == this.allPathsComponent) {
      this.appState.set("isAllPaths", true);
    }
    const modal = await this.modalController.create({
    component: comp,
    // animated: false,
    cssClass: 'custom-modal',
    enterAnimation: this.animationService.slideoutRightEnterAnimation,
    leaveAnimation: this.animationService.SlideInLeftLeaveAnimation
  });
   await modal.present()  
}  
changeFont() {
  
    const element = this.stepTitle?.nativeElement;
    const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
    if(specificElement) {
      this.render.setStyle(specificElement, 'font-size', '20px');
      this.render.setStyle(specificElement, 'font-weight', '500');
      this.render.setStyle(specificElement, 'font-style', 'normal');
      this.render.setStyle(specificElement, 'line-height', 'normal');
      this.render.setStyle(specificElement, 'font-family', 'Roboto');
     this.render.setStyle(specificElement, 'word-break', 'break-word');
     this.render.setStyle(specificElement, 'overflow', 'hidden');
     this.render.setStyle(specificElement, ' text-overflow', 'ellipsis');
     this.render.setStyle(specificElement, 'display', '-webkit-box');
     this.render.setStyle(specificElement, '-webkit-line-clamp', '2');
     this.render.setStyle(specificElement, '-webkit-box-orient', 'vertical');

    }
  return true;
  }
  switchStep(){
    if (!this.showUnlockButton && this.showBeginButton) {
      if((this.currentDayStarted && !this.showReplayButton) || this.showBeginButton){
        this.activeDay= this.scheduledStep-1
        this.switchNextScheduleDay();
        this.appState.set('isHomeScreen', false);
      }
    }
    if (
      this.showReplayButton &&
      (this.appState.get("isHomeScreen") ||
        this.activeDay <= this.scheduledStep)
    ) {
      this.isDayLocked(this.scheduledStep);
      this.activeDay = this.scheduledStep;
      this.scheduleDay = this.activeDay;

      this.dayHasIncompleteMandatory = this.path.checkMandatoryForDay(
        this.activeDay
      );
      this.showContinueButton = this.homev5Service.checkAllNonMandatory()
        ? this.homev5Service.checkScreenCount(
            this.pathRegistration,
            this.activeDay
          )
        : (!this.dayHasIncompleteMandatory && this.currentDayStarted)?this.homev5Service.checkScreenCount(
          this.pathRegistration,
          this.activeDay
        ):(this.dayHasIncompleteMandatory && this.currentDayStarted);
      if (this.showContinueButton) {
        this.activeDay = this.scheduledStep - 1;
      }
      this.switchNextScheduleDay();

      this.appState.set("isHomeScreen", false);
    }
    if (this.showCloseoutWelcomeScreen) {
      if (this.scheduleDay < this.path.daysOfContent) {
        // LUMBA-1435
        this.activeDay = this.path.daysOfContent + 1;
      } else {
        this.activeDay = this.scheduleDay + 1;
      }
      this.closeOutOn();
    }

    let levelUpdateData = this.appState.get("levelUpdateData");
    this.allLevelsCompleted = this.appState.get("allLevelsCompleted");
    if (levelUpdateData?.levelUpdated === true) {
      this.appState.set("LevelNumber", levelUpdateData?.level.level + 1);
      const last =
        levelUpdateData?.routine.routine[
          levelUpdateData?.routine.routine.length - 1
        ];
      var isLastRepLogged = levelUpdateData?.level.reps_commit.filter(
        (item) => item.is_completed === true
      );

      if (
        levelUpdateData &&
        levelUpdateData.level.reps_commit.length ==
          isLastRepLogged.length + 1 &&
        last.id === levelUpdateData.level.id
      ) {
        if (this.allLevelsCompleted === true) {
          console.log("do nothing :");
        } else {
          this.checkLastRep(levelUpdateData.routine);
        }
      } else {
        this.appState.set("levelUpdated", true);
        setTimeout(()=>{
          this.router.navigate(["/alert-unlock-component"], {
            state: {
              data: levelUpdateData.routine.schedule_day,
            },
          });
        },600);
        
        this.appState.set("levelUpdateData", undefined);
      }
    }

    let levelSaveData = this.appState.get("levelSaveData");
    if (levelSaveData && this.showReplayButton) {
      localStorage.setItem(
        `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
        "true"
      );
      this.appState.set("showUpdatedScreens", undefined);
      this.appState.set("levelSaveData", undefined);
    }
  }
  giveFeedback(routine: any) {
    var step_rating = localStorage.getItem("commitmentRating1");
    var step_comment = localStorage.getItem("commitmentFeedback");
    try {
      const requestData = {
        user_id: this.currentUser.id,
        path_id: this.path.id,
        step_score: step_rating,
        step_comment: step_comment,
        routine_info_id: routine.routine_info_id,
      };
      this.spinnerService.on();
      if (requestData.step_comment == "" && requestData.step_score == "") {
        this.spinnerService.off();
      } else {
        this.routineService.routineFeedback(requestData).subscribe(
          async (response) => {
            console.log("respose", response);
            this.getAllRoutines(this.currentUser.id, this.path.id);
            this.cdRef.detectChanges();
            this.spinnerService.off();
            if (this.makeHabit === false) {

              const alert = await this.alertController.create({
                 cssClass: 'custom-alert-action',
                 message: this.i18n.t('Do you want to repeat all or part of this habit to continue building the habit of')+" "+routine?.routine_info?.title,
                 
                  buttons: [
                    {
                      text: this.i18n.t("No"),
                      cssClass:'custom-alert-textcase',
                      handler:()=>{
                       
                      }
                    },
                    {
                      text: this.i18n.t("Yes"),
                      cssClass:'custom-alert',
                      handler:()=>{
                        this.appState.set('levelEdit', true)
                        this.onSubmit(routine);
                      }
                    }
                   ],
                });
                await alert.present();
            }
          },
          (error) => {
            this.spinnerService.off();
            this.toasterService.error(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
          }
        );
      }
    } catch (o_O) {
      console.log(o_O);
    }
  }
  switchToLeft() {
    this.closeOutOff();
    this.switchScheduleDay(this.activeDay);
  }
  onSwipeLeft() {
    this.switchNextScheduleDay();
  }

  onSwipeRight() {
    this.switchPrevScheduleDay();
  }
  allowScreenCount(status) {
    this.appState.set("screenStatus", status);
    if (status === 1) {
      this.appState.set("allowScreenCount", true);
      mixpanel.track("In Steps-Instep Begin", {"Step": (this.lastStep == 0 ? 1 : this.lastStep), 'step_number': this.currentDay})
      localStorage.setItem('isBeginClicked','true')      
    }
    if (status === 2) {
      this.appState.set("allowScreenCount", true);
      mixpanel.track("In Steps-Instep Resume", {"Step": this.lastStep == 0 ? 1 : this.lastStep, 'step_number': this.currentDay
    })
    }
  }

  unlockReplay(stepData) {
    console.log('index :', stepData);
    let index= stepData?.step;
    this.appState.set("currentScheduleDay", index);
    this.appState.set("allowScreenCount", true);
    if(stepData?.stepStatus =='Review'){
      this.replay(stepData?.step)
    }
    else if(stepData?.stepStatus =='Resume'){
      this.router.navigate(['/content', index])
    }else{
      var i = index - 1;
      if (i == 0) {
        localStorage.setItem('isBeginClicked','true')
        this.router.navigate(['/content', index])
        return;
      }
    //  let previousDayCompleted;
      let updateScheduleDay = i + 1;
      // for (let s = index - 1; s >= 0; s--) {
      //   let dayHasContent = this.path.checkContentForDay(s);
      //   if (dayHasContent) {
      //     previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
      //       this.pathRegistration,
      //       s,
      //       this.currentUser,
      //       this.path
      //     )
      //     break;
      //   }
      // }
      // let showPrev = previousDayCompleted;
      let data = {
        schedule_day: updateScheduleDay,
        user_id: this.currentUser.id,
      };
      // if (previousDayCompleted) {
        this.homev5Service.updateScheduleDay(data).subscribe(
          (response) => {
            if (response.success == true) {
              localStorage.setItem('isBeginClicked','true')
              this.appState.set("allowScreenCount", true);
              this.appState.set("LevelNumber", 1);
              this.appState.set("maxAllowedScheduleDay", updateScheduleDay);
              this.appState.set("currentScheduleDay", updateScheduleDay);
              this.appState.set("scheduleDayForCurrentStep", updateScheduleDay);
              this.appState.set("indexOfLevel", 0);
              this.appState.set("levelUpdated", undefined);
              this.currentDay = this.appState.get("currentScheduleDay"); // LUMBA-1379
              this.scheduleDay = this.appState.get("scheduleDayForCurrentStep");
              localStorage.removeItem(
                `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
                  !(i == undefined) ? i + 1 : this.scheduleDay
                }`
              );
              this.router.navigate([
                "/content",
                !(i == undefined) ? i + 1 : this.scheduleDay,
              ]);
              // this.homeUIService.openDailyStepsComponent(DailyStepsContentModalComponent)

              return false;
            } else {
              this.toasterService.warning(this.i18n.t(
                "Server or connection issue. Please check your internet connection."
              ));
            }
          },
          (error) => {
            this.toasterService.warning(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
            console.log("Response of update  error", error);
          }
        );
      // } else {
      //   this.toasterService.warning(this.i18n.t("Please complete previous step"));
      // }
    }
    
  }
  get isLastCurrentDayCompleted(): boolean {
    const lastDayOfContent = this.path.lastDayOfContent();
    var value = !!this.homev5Service.checkPrevDayCompleted(
      this.pathRegistration,
      lastDayOfContent,
      this.currentUser,
      this.path
    );
    return value;
  }

  switchPrevScheduleDay() {
    this.isSlideLeft = "";
    setTimeout(() => {
      if (this.activeDay > this.scheduleDays.length) {
        this.activeDay = this.scheduleDays.length;
      }
      // this.switchScheduleDay(this.activeDay -1)
      //** SKIP THE STEP WHICH HAS NO CONTENT **//

      for (let i = this.activeDay - 1; i >= 0; i--) {
        this.dayHasContent = this.path.checkContentForDay(i);
        if (this.dayHasContent) {
          localStorage.setItem(
            `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.path.id}:${i}`,
            "yes"
          );

          this.switchScheduleDay(i);
          break;
        }
      }

      this.leftSwipeAnimation();
      this.isSlideLeft = "animate__slideInLeft";
    }, 50);
    for (let i = this.activeDay - 1; i > 0; i--) {
      this.dayHasContent = this.path.checkContentForDay(i);
      if (this.dayHasContent) {

        this.previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
          this.pathRegistration,
          i,
          this.currentUser,
          this.path
        )
        break;
      }

    }

  }

  findLevelId(routine: any, checkedLevel: any) {
    let someArray = []
    let level_ids =[];
    routine.routine.forEach(element => {
      if (element.id != checkedLevel.id) {
        someArray.push(element)
        level_ids.push(element.id)
      }
    });
    this.appState.set('LevelNumber', someArray[0].level)
    this.appState.set('indexOfLevel', undefined)    
    this.appState.set('currentLevel', someArray[0])
    this.appState.set('levelUpdated', undefined)
    const repsRequestData= {  
      user_id:routine.user_id,
      is_reset:true,
      level_id:JSON.stringify(level_ids),
      routine_info_id:routine.routine_info_id
    }
   
    if(repsRequestData.user_id==0){
      return true;
    }else{
      this.routineService.appendRepsCount(repsRequestData).subscribe((res)=>{
        console.log("response for reps", res);
      })
      this.routineService.editRoutine(repsRequestData).subscribe(
        (response) => {
          console.log('status for edit routines', response);
          this.appState.set('levelUpdated', undefined)
          this.cdRef.detectChanges();
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }

  }
  openRoutineCalendar(
    isSingleEvent: boolean,
    subRoutineAnswer: any,
    level: any,
    notificationSentArrayLevelFinal: any,
    routineLevel: any
  ) {
    var data = {
      text: level.text,
      title: level.title,
      routineAnswer: subRoutineAnswer,
      level: level,
      isSingleEvent: isSingleEvent,
      routineTime: level.routine_time,
      selectedDays: level.day,
    };

    this.appState.set("routineCalendarData", data);
    this.appState.set("isRoutineCalender", true);

    var differentTimes = level.routine_time;
    var differentDays = level.day;

    const dialogRef = this.dialog.open(WeekendCalendarComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.appState.set("isRoutineCalender", false);

      const dialogAnim = this.dialog.open(AlertAnimationComponent);
      dialogAnim.afterClosed().subscribe((result) => {
        this.appState.set("counterId", this.counterId++);

        differentTimes.forEach((element) => {
          const date = moment().format("YYYY/MM/DD");
          const time = element;
          const timeAndDate = moment(date + " " + time);
          timeAndDate.format("YYYY/MM/DD hh:mm A");
          console.log(timeAndDate.format("YYYY/MM/DD hh:mm A"));
          let newUtcDate = moment(timeAndDate).utc().format("hh:mm A");
          this.selectedTimings.push(newUtcDate);
        });

        let day = "{" + level.day + "}";
        let routineTime = "{" + this.selectedTimings + "}";

        const data = {
          user_id: notificationSentArrayLevelFinal[routineLevel].user_id,
          path_id: notificationSentArrayLevelFinal[routineLevel].path_id,
          routine_info_id: level.routine_info_id,
          type_level: notificationSentArrayLevelFinal[routineLevel].type_level,
          routine_time: routineTime,
          day: day,
        };

        this.homev5Service.routineUpdateData(data).subscribe(
          (response) => {
            console.log("commit response", response);
          },
          (error) => {
            console.log("error", error);
            this.toasterService.error(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
          }
        );

        this.selectedTimings = [];
        this.showDialog(notificationSentArrayLevelFinal, this.counterId); //Recursively open next dialog
      });
    });
  }
  showDialog(notificationSentArrayLevelFinal: any[], routineLevel) {
    try {
      if (routineLevel >= notificationSentArrayLevelFinal.length) return; //finished processing

      var lastLevel =
        notificationSentArrayLevelFinal[
          notificationSentArrayLevelFinal.length - 1
        ].routine[0];
      var firstLevel = notificationSentArrayLevelFinal[routineLevel].routine[0];

      const isAfterDay = moment().isAfter(firstLevel.end_date);
      if (isAfterDay) {
        const dialogRef = this.dialog.open(AlertNotificationUpdateComponent, {
          data: {
            dataKey: { routine: firstLevel },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === "confirm") {
            const dialogRef = this.dialog.open(AlertReviewRoutineComponent, {
              data: {
                dataKey: { routine: firstLevel },
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (lastLevel.id === firstLevel.id) {
                this.appState.set("isNotificationViewed", true);
              }
              //this.appState.set('isNotificationViewed',true)
              var routineAnswer = firstLevel.routine_description;
              if (result === "confirm") {
                //yes
                if (firstLevel) {
                  if (firstLevel.isSingleEvent === null) {
                    return;
                  }
                  if (firstLevel.isSingleEvent) {
                    this.openRoutineCalendar(
                      false,
                      routineAnswer,
                      firstLevel,
                      notificationSentArrayLevelFinal,
                      routineLevel
                    ); //false
                  } else {
                    this.openRoutineCalendar(
                      true,
                      routineAnswer,
                      firstLevel,
                      notificationSentArrayLevelFinal,
                      routineLevel
                    ); //TODO ;  true
                  }
                  this.appState.set("isConfirmed", true);
                }
              } else if (result === "change") {
                //No, change cue
                this.appState.set("changeCue", true);
                this.appState.set("isRoutineEdited", true);
                this.counterId++;
                this.appState.set("counterId", this.counterId);
                this.router.navigate(
                  [
                    "/content",
                    notificationSentArrayLevelFinal[routineLevel].schedule_day,
                  ],
                  {
                    queryParams: {
                      startFromScreen: "daily-content-screen-meet-cue",
                    },
                  }
                );
              } else if (result === "turnOff") {
                //No, turn off reminders
                this.turnOffNotifications(
                  notificationSentArrayLevelFinal[routineLevel],
                  firstLevel,
                  notificationSentArrayLevelFinal,
                  routineLevel
                );
              }
            });
          }
        });
      }
    } catch (error) {
      this.toasterService.error(this.i18n.t(
        "Server or connection issue. Please check your internet connection."
      ));
      console.log("Error for routines ", error);
    }
  }
  turnOffNotifications(
    routine: any,
    level: any,
    notificationSentArrayLevelFinal: any,
    routineLevel: any
  ) {
    const requestData = {
      user_id: this.currentUser.id,
      path_id: this.path.id,
      notification_sent: false,
      routine_info_id: routine.routine_info_id,
    };
    this.homev5Service.disableNotification(requestData).subscribe(
      (response) => {
        const dialogRef = this.dialog.open(AlertConfirmNotificationComponent, {
          data: {
            dataKey: { level: level },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.appState.set("counterId", this.counterId++);
          this.showDialog(notificationSentArrayLevelFinal, this.counterId); //Recursively open next dialog
        });
      },
      (error) => {
        this.toasterService.error(this.i18n.t(
          "Server or connection issue. Please check your internet connection."
        ));
      }
    );
  }
  getAllRoutines(userId: number, pathId: number) {
    const requestData = {
      user_id: userId,
      path_id: pathId,
    };
    this.dailyStepService.getActionsList(requestData).subscribe(
      (response) => {

        this.appState.set('todoRoutines', this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
        this.appState.set('todoActions', response.data[0].action)

        this.routineList = this.homev5Service.removeDuplicates(
          response.data[0].routine,
          "routine_info_id"
        );
        this.cdRef.detectChanges();
        var notificationSentArrayLevel = this.routineList.map((item) => {
          return {
            ...item,
            routine: item.routine.filter(
              (routine) => routine.notification_sent === true
            ),
          };
        });
        var notificationSentArrayLevelFinal = notificationSentArrayLevel.filter(
          (item) => item.routine.length > 0
        );
        this.timezone = this.currentUser.timezone;
        if (
          Array.isArray(notificationSentArrayLevelFinal) &&
          notificationSentArrayLevelFinal.length > 0
        ) {
          this.counterId = this.appState.get("counterId");
          this.showDialog(
            notificationSentArrayLevelFinal,
            this.appState.get("counterId")
          );
        }
      },
      (error) => {
        this.toasterService.error(this.i18n.t(
          "Server or connection issue. Please check your internet connection."
        ));
        console.log("error :", error);
      }
    );
  }
  showAnimation() {
    const myConfetti = confetti.create(this.canvas, {
      resize: true, // will fit all screen sizes
    });
    myConfetti();
  }

  onSubmit(routine) {
    this.appState.set('showUpdatedScreens', true)
      this.appState.set('LevelNumber', 1) 
      this.findLevelId(routine,1)
      this.appState.set('hidePrevScreen', true);
      this.appState.set("allowScreenCount", undefined)
      this.homeUIService.dismissAllModals().then(()=>{
        this.router.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })

      })
  }
  checkLastRep(routine?: any) {
    this.showAnimation()
    this.toasterService.success(this.i18n.t('Habit Complete!'))
    setTimeout(async () => {
      const alert = await this.alertController.create({
         cssClass: 'custom-alert-action',
         message: this.i18n.t('Has this routine,')+" "+ routine?.routine_info?.title + " "+ this.i18n.t(", become a habit for you?"),
         
          buttons: [
            {
              text: this.i18n.t("No"),
              cssClass:'custom-alert-textcase',
              handler:()=>{
                this.makeHabit = false
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            },
            {
              text: this.i18n.t("Yes"),
              cssClass:'custom-alert',
              handler:()=>{
                this.makeHabit = true
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            }
           ],
        });
        await alert.present();
    }, 1000);

  }

  async openAlertGiveFeedbackCommitmentComponent(routine) {
    const modal= await this.modalController.create({
      component: AlertGiveFeedbackCommitmentComponent,
      cssClass: this.isMobile ? 'custom-modal-size' : 'web_routine_feedback_modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
    })
    this.homeUIService.setModalStack(modal);
    await modal.present();
   modal.onDidDismiss().then((res)=>{
     if(res){
      this.giveFeedback(routine)
     }
   })
  }

  sortArrayObject() {
    this.pollData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );
    this.hadbitData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );
    // this.documentData?.sort((a, b) =>
    //   a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    // );
    this.questionData.sort((a, b) =>
      a.startDay < b.startDay ? -1 : a.startDay > b.startDay ? 1 : 0
    );

    this.surveyData.sort((a, b) =>
      a.startDay > b.startDay ? -1 : a.startDay < b.startDay ? 1 : 0
    );
  }

  // find the length of all task object
  highestLengthAllTaskObject() {
    this.maxOneNumbers = Math.max(
      this.documentData.length,
      this.pollData.length
    );
    this.maxTwoNumbers = Math.max(
      this.hadbitData.length,
      this.questionData.length
    );

    const finalMaxvalue = Math.max(this.maxOneNumbers, this.maxTwoNumbers);

    if (finalMaxvalue > this.surveyData.length) {
      this.maxData = new Array(finalMaxvalue);
    } else {
      this.maxData = new Array(this.surveyData.length);
    }
    return this.maxData.length;
  }

  setAllTaskToMinimize(length: number) {
    var i = 0;
    while (i < length) {
      this.isFullCardView.push(false);
      i++;
    }
  }
  getSnoozedSteps() {
    return this.dailyStepService.getSnoozedSteps()
  }
  setDataToAllTask() {

    this.questionData = this.path.gridQuestions;
    this.hadbitData = this.path.habits;
    this.documentData = this.path.gridDocuments;
    this.pollData = this.path.polls;
    this.surveyData = this.path.contentSurveys;
  }
  switchScheduleDay(day_number: number) {
    if (
      this.getSnoozedSteps()
    ) {
      this.lengthOfSnoozed = this.getSnoozedSteps().length;
    }
    this.dayNumber = day_number;
    this.isDayLocked(day_number);

    this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;
    if (day_number <= this.maxAllowedScheduleDay && day_number > 0) {
      this.appState.set("currentScheduleDay", day_number);
      this.init();
      this.scheduleDay = day_number;
      this.activeDay = day_number;
      this.currentDayStarted = !!localStorage.getItem(
        `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${this.scheduleDay}`
      );
      this.dayHasContent = this.path.checkContentForDay(this.scheduleDay);
      this.dayHasIncompleteMandatory = this.path.checkMandatoryForDay(
        this.scheduleDay
      ); // LUMBA-1317

      // LUMBA-1301
      // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content

      // Check whether localdata is present or not if not then check how many steps are completed
      if (!this.currentDayStarted || !this.currentDayCompleted) {
        let stepsArray = this.homev5Service.removeDuplicates(
          this.pathRegistration?.screensViewed,
          "step"
        );
        stepsArray.forEach((element) => {
          if (
            element.step == this.scheduleDay &&
            element.visitedScreens < element.totalScreens
          ) {
            this.currentDayStarted = true;
          } else if (
            element.step == this.scheduleDay &&
            element.visitedScreens == element.totalScreens
          ) {
            this.currentDayStarted = true;
            this.currentDayCompleted = true;
          }
        });
      }

      this.homev5Service.getDailySteps(this.scheduleDay, this.path);
      
      if (day_number === this.scheduledStep) {
        this.currentDayCompleted = !!localStorage.getItem(
          `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${this.scheduleDay}`
        );
        this.showCompletedMessage =
          !this.dayHasIncompleteMandatory && this.dayHasContent;
        this.showContinueButton = this.homev5Service.checkScreenCount(
          this.pathRegistration,
          this.scheduleDay
        )
        this.showReplayButton = this.homev5Service.checkReplayForNonMand(
          this.pathRegistration,
          this.scheduleDay
        )
        this.showBeginButton = this.homev5Service.checkPrevDayCompletedForNonMand(
          this.pathRegistration,
          this.scheduleDay,
          this.currentUser,
          this.path,
          this.currentDayStarted
        )
      } else {
        this.currentDayCompleted = false;
        this.showCompletedMessage = this.dayHasContent;
        this.showContinueButton = false;
        this.showReplayButton = this.dayHasContent;
        this.showBeginButton = false;
      }
      this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;
      
    } else if (
      day_number > this.maxAllowedScheduleDay &&
      day_number <= this.path.daysOfContent
    ) {
      // can see next day, since its now unlocked // LUMBA-1435
      this.activeDay = day_number;
      this.dayHasContent = this.path.checkContentForDay(this.activeDay);
      this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted;
      this.closeOutOff();
      this.showCompletedMessage = false;
      this.showContinueButton = false;
      this.showReplayButton = false;
      this.showBeginButton = false;
    } else if (
      day_number === this.path.daysOfContent + 1 &&
      this.showCloseoutWelcomeScreen
    ) {
      // need to render closeout screen // LUMBA-1312
      this.activeDay = day_number;
      this.closeOutOn();
      this.showCompletedMessage = false;
      this.showContinueButton = false;
      this.showReplayButton = true;
      this.showBeginButton = false;
    }
     this.init();
    this.appState.set(
      "isLastCurrentDayCompleted",
      this.isLastCurrentDayCompleted
    );

    const lastDayOfContent = this.path.lastDayOfContent();
    const lastDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.currentUser.id}:DayCompleted:${this.path.id}:${lastDayOfContent}`
    );
    if (lastDayCompleted) {
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${this.path.id}:${lastDayOfContent}`,
        "yes"
      );
    }
  }

  closeOutOff() {
    if (this.activeCloseoutWelcomeScreen) {
      this.activeCloseoutWelcomeScreen = false;
      console.log("activeCloseoutWelcomeScreen", this.activeCloseoutWelcomeScreen);
      this.appState.set("activeCloseoutWelcomeScreen", false);
    }
  }
  closeOutOn() {
    if (!this.activeCloseoutWelcomeScreen) {
      this.activeCloseoutWelcomeScreen = true;
      console.log("activeCloseoutWelcomeScreen", this.activeCloseoutWelcomeScreen);

      this.appState.set("activeCloseoutWelcomeScreen", true);
      this.appState.set("hideCloseout",true);
    }

  }
  todayTask() {
    this.isTodayTask = true;
    this.task = "today";
  }
  switchNextScheduleDay() {
    this.isSlideLeft = "";
    setTimeout(() => {
      if (this.activeDay > this.scheduleDays.length) {
        this.activeDay = this.scheduleDays.length;
      }
      let currentDay = this.activeDay;
      this.switchScheduleDay(this.activeDay + 1);
      if (!this.dayHasContent) {
        for (let i = this.activeDay + 1; i <= this.maxAllowedScheduleDay; i++) {
          this.dayHasContent = this.path.checkContentForDay(i);
          if (this.dayHasContent) {
            if (
              !!this.homev5Service.checkPrevDayCompleted(
                this.pathRegistration,
                currentDay,
                this.currentUser,
                this.path
              ) || this.dailyStepsArray[currentDay-1]?.stepStatus =='Review'
            ) {
              localStorage.setItem(
                `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${
                  this.path.id
                }:${i - 1}`,
                "yes"
              );
            }
            this.switchScheduleDay(i);

            break;
          } else {
            localStorage.setItem(
              `Toggle:User:${this.currentUser.id}:DayUnlockCompleted:${
                this.path.id
              }:${i - 1}`,
              "yes"
            );

            this.switchScheduleDay(i);
          }
        }
      }
      this.rightSwipeAnimation();
      this.isSlideLeft = "animate__slideInRight";
      for (let j = this.activeDay - 1; j > 0; j--) {
        this.dayHasContent = this.path.checkContentForDay(j);
        if (this.dayHasContent) {
          this.previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
            this.pathRegistration,
            j,
            this.currentUser,
            this.path
          )
          break;
        }
      }

    }, 50);
    this.appState.set("swipeNext", undefined);
  }
  leftSwipeAnimation() {
    if (this.dayNumber >= 1) {
      this.rightState = false;
      setTimeout(() => {
        this.rightState = true;
      }, 50);
    }
  }

  rightSwipeAnimation() {
    if (this.dayNumber <= this.scheduleDays.length) {
      this.state = false;
      setTimeout(() => {
        this.state = true;
      }, 50);
    }
  }
  replay(i) {
    this.appState.set("isNewStepUnlocked", undefined);
    this.appState.set('allowScreenCount', undefined)
    let screensViewed = this.appState.get('screensViewed')
    let stepsArray;
    if (this.pathRegistration?.screensViewed) {
      stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration?.screensViewed,"step");
    }else{
      stepsArray = screensViewed
    }
    stepsArray?.forEach((element) => {
      if (
        element?.step == this.scheduleDay &&
        element?.visitedScreens === element?.totalScreens
      ) {
      }
    });    

    localStorage.removeItem(
      `Toggle:User:${this.currentUser.id}:DayProgress:${this.path.id}:${
        i
      }`
    ); 
      this.router.navigate([
        "/content",
        !(i == undefined) ? i : this.scheduleDay,
      ]);
    return false;
  }

  ngOnInit() {
    const elements = this.elementRef.nativeElement.querySelectorAll('.sc-ion-label-md-s > p');
    this.dailyStepsArray= this.appState.get("DailyStepsData");
    // Loop through the elements and change the color property
    elements.forEach(element => {
      this.renderer.setStyle(element, 'color', 'red'); // Change the color to your desired value
    });
    this.homeUIService.refreshStepApiData.pipe(takeUntil(this.destroyed$)).subscribe( (data) => {
     if(data){
       this.dailyStepsArray = data;
     }   
   });
   this.getStepArrayInfo();
    this.init();
    this.switchStep()
    
  }
  async getStepArrayInfo(){
    let stepBegin= false;
    this.dailyStepsArray?.forEach((step)=>{
     if(step?.stepStatus =='Review' && step?.step < this.dailyStepsArray?.length)
     {
       this.appState.set("currentScheduleDay",step?.step+1)
       this.appState.set('scheduleDayForCurrentStep',  step?.step+1);
       stepBegin =true;
     }
     else if(step?.stepStatus=='Resume') {
       this.appState.set("currentScheduleDay",step?.step)
       this.appState.set('scheduleDayForCurrentStep',  step?.step);
       stepBegin =true
     }
     else if(!step?.stepStatus && !stepBegin) {
       this.appState.set("currentScheduleDay",step?.step)
       this.appState.set('scheduleDayForCurrentStep',  step?.step);
       stepBegin =true
     }
   })
  }
  findForCurrentDay(collection: any): any {
    return collection.find(item => item.startDay <= this.currentDay && this.currentDay <= item.endDay)
  }
  ngDoCheck(): void {
   // this.init();
   this.allPathsCount =  this.appState.get('allPathCount')
   this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
  // console.log("this.scheduled step", this.scheduledStep);
    let screensViewed = this.appState.get('screensViewed')
    if (screensViewed) {
      screensViewed = this.appState.get('screensViewed')
      let steps = screensViewed?.screensViewed
      this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      this.calculateSteps(this.stepsArray)
    } else {
      let steps = this.pathRegistration?.screensViewed
      this.stepsArray = this.homev5Service.removeDuplicates(steps, "step");
      this.calculateSteps(this.stepsArray)
    }
      //this.dailyStepsArray= this.appState.get("DailyStepsData");
      this.currentDay = this.appState.get('currentScheduleDay')
    
   
  }
  calculateSteps(stepsArray: any[]) {
    if (stepsArray && stepsArray.length >= 1) {
      try {
        stepsArray.forEach(element => {
          if (element.step === this.currentDay) {
            this.currentStep = element.visitedScreens
            this.totalSteps = element.totalScreens
            throw new Error("steps error");
          } else {
            this.currentStep = 0
            this.totalSteps = 0
          }
        });
      } catch (error) { }
    }
  }
  isDayLocked(day_number) {
    for (let i = day_number - 1; i > 0; i--) {
      this.dayHasContent = this.path.checkContentForDay(i);
      if (this.dayHasContent) {
        let previousDayCompleted = this.homev5Service.checkPrevDayCompleted(
          this.pathRegistration,
          i,
          this.currentUser,
          this.path
        )
        this.previousDayCompleted = previousDayCompleted;
        this.showPrev = previousDayCompleted;
        break;
      }
    }

    if (
      day_number > this.scheduledStep &&
      this.scheduledStep != this.maxAllowedScheduleDay
    ) {
      this.showUnlockButton = true;
    } else {
      this.showUnlockButton = false;
    }
  }
  getTaskDurations() {
    if (this.inoutScreenData&&!_.isUndefined(this.inoutScreenData)) {

      if (this.inoutScreenData.stime && this.inoutScreenData.etime) {
        this.durationOfTask = this.inoutScreenData.stime + " - " + this.inoutScreenData.etime;
        return this.durationOfTask;
      } else if (!this.inoutScreenData.stime && this.inoutScreenData.etime) {
        this.durationOfTask = this.inoutScreenData.etime;
        return this.durationOfTask;
      } else {
        return "5 - 10";
      }
    } else {
      return "5 - 10";
    }
  }
  getTagName(tag) {
 
    if(tag === 0){
      tag = this.i18n.t('Action');
    }else if(tag === 1){
      tag = this.i18n.t('Story');
    }else if(tag === 2){
      tag = this.i18n.t('Reflection');
    }
    return tag;
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
