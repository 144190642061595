import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { env } from 'process';
import { AppState } from 'src/app/app.service';
import { ReplyComponent } from 'src/app/reply/reply.component';
import { Reply } from 'src/app/reply/reply.model';
import { RoutineV2Service } from 'src/app/routine-v2/routine-v2.service';
import { SessionService } from 'src/app/session/session.service';
import { SpinnerService } from 'src/app/spinner.service';
import { ToasterService } from 'src/app/toaster.service';
import { UserPublicProfileComponent } from '../../user-public-profile/user-public-profile.component';
import { I18n } from 'src/app/i18n.service';
import { HomeUiService } from 'src/app/new-design/home-ui.service';

@Component({
  selector: 'app-action-community',
  templateUrl: './action-community.component.html',
  styleUrls: ['./action-community.component.scss'],
})
export class ActionCommunityComponent implements OnInit {
  @Input() item: any;
  @Output() onOpenRoutine = new EventEmitter<any>();
  @Output() onShowCommentList = new EventEmitter<any>();
  @Output() onTextClick = new EventEmitter<any>();
  @Output() onToggleLike = new EventEmitter<any>();
  @Output() onShowLikeList = new EventEmitter<any>();
  @Output() onComment = new EventEmitter<any>();
  @ViewChild('btn') btn: ElementRef;
  @ViewChild("repliesContainer") repliesContainer: ElementRef;
  @Output() isProfileClicked = new EventEmitter<any>() ;
  singleRoutineAnswer;
  replyToComponent: ReplyComponent;
  commentCount=0;
  isMobile: boolean;
  userDetails;

  public repliesTree: Reply[]

  constructor(
    public sessionService: SessionService,
    public appState: AppState,
    private popoverController:PopoverController,
    private routineV2Service: RoutineV2Service,
    private toasterService: ToasterService,
    private i18n:I18n,
    private render: Renderer2,
    private spinnerService: SpinnerService,
    public homeUiService: HomeUiService

  ) { 
    this.isMobile = this.appState.get('isMobile')
    this.singleRoutineAnswer = this.item;
  }

  ngOnInit() { 
    this.singleRoutineAnswer = this.item;
  }
  
  ngAfterViewInit(){
    try {
      if (!this.isMobile) {
        document.getElementById('actionHeader').classList.add('desktop');
      }
    } catch (error) { }
  }
  async openUserInfo(ev, userData): Promise<void> {
    console.log('userData', userData);
    this.isProfileClicked.emit({isClicked:true})
    const popover = await this.popoverController.create({
      component: UserPublicProfileComponent,
      event: ev,
      mode:'ios',
      translucent: false,
      componentProps: {'user': userData}
    });
    await popover.present();
   
  }
  showCommentList(clickItem) {
    this.onShowCommentList.emit({
      id: clickItem.id
    })
  }
  clickOnText(event, clickItem) {
    this.onTextClick.emit({
      item: clickItem,
      evn: event
    })
  }
  doLikeUnlike() {
    if (this.isMobile) {
      this.render.addClass(this.btn.nativeElement, 'bounce');
      if (this.btn.nativeElement.querySelector('ion-icon').getAttribute("name") == 'star-outline') {
        this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'), "name", 'star')
      }
      else {
        this.render.setAttribute(this.btn.nativeElement.querySelector('ion-icon'), "name", 'star-outline')

      }
      setTimeout(() => {
        this.render.removeClass(this.btn.nativeElement, 'bounce');
      }, 200);
    }
    this.onToggleLike.emit({
      item: this.item
    })
  }
  emitShowLikeList(){
    this.onShowLikeList.emit(this.item.id);
  }
  emitComment(event){
    this.onComment.emit({
      item: this.item,
      evn: event
    })
  }
  openRoutine(event,flag?){
    this.onOpenRoutine.emit({
      evn:event,
      flag:flag,
      item:this.item
    });
  }

  getRoutineDetails() {
    if (!this.singleRoutineAnswer) {
      this.spinnerService.off()
      return
    }

    try {
      const data = {
        id: this.singleRoutineAnswer.routine_info_id,
        routine_answer_id: this.singleRoutineAnswer.id,
        mode: this.singleRoutineAnswer.type
      };
      this.routineV2Service.getRoutineDetails(data).subscribe(
        (response) => {
          this.userDetails=response.data.user;
          var level1 = response.data.replies
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
            level1.forEach(reply => {
              subReply.forEach(element => {
                if (element.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            });

            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            this.appState.set('repliesLength', this.repliesTree.length)
            let isCommentDeleted = this.appState.get('commentDeleted')
            if (isCommentDeleted) {
              this.appState.set('commentDeleted', false)
            }
          }

          this.spinnerService.off()
        },
        (error) => {
          console.log('error', error)
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
          this.spinnerService.off()
        }
      )
    } catch (error) {
      console.log('error', error)
      this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      this.spinnerService.off()
    }


  }
}
