import { Component, OnInit } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';
import { SessionService } from '../session/session.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-give-feedback-commitment',
  templateUrl: './alert-give-feedback-commitment.component.html',
  styleUrls: ['./alert-give-feedback-commitment.component.scss']
})
export class AlertGiveFeedbackCommitmentComponent implements OnInit {

  postText:any = ''
  formRating1:any = 0
  formRating2:any = 0
  complexItems = this.appState.get("userlist")

  namesData : any[] = new Array()  
  tempValue = 0
  index = 0
  
  constructor(private appState: AppState,private modalController: ModalController, private sessionService: SessionService) { }

  ngOnInit() {
  }

  onSumbit(){
    this.modalController.dismiss();
    mixpanel.track('Commitments-Habit Complete Post', {value: this.postText})
    localStorage.setItem('commitmentFeedback',this.postText);
    localStorage.setItem('commitmentRating1',JSON.stringify(this.formRating1));
    localStorage.setItem('commitmentRating2',JSON.stringify(this.formRating2));
  }

  
  
  format(item:any) {
    return item['username'].toUpperCase()
  }

}
