import { Component, Inject, OnInit, Optional } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppState } from '../app.service';

@Component({
  selector: 'app-alert-habit-loop-info',
  templateUrl: './alert-habit-loop-info.component.html',
  styleUrls: ['./alert-habit-loop-info.component.scss']
})
export class AlertHabitLoopInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertHabitLoopInfoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appState: AppState) { }


  ngOnInit() {
    console.log(this.data)
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
}
