import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import * as query from './home-page.query'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { AppState } from '../../app.service'
import { Habit } from '../../habit/habit.model'
import { Document } from '../../document/document.model'
import { Question } from '../../question/question.model'
import { Poll } from '../../poll/poll.model'
import { newOrNull } from '../../utils.service'
import { Path } from '../../path/path.model'
import { DataProxy } from 'apollo-cache'
import { set } from 'object-path'

@Injectable()
export class HomePageService {
  path: Path
  constructor(
    private apollo: Apollo,
    private appState: AppState
  ) {
    this.path = appState.get('currentPath')
  }

  getContent(): Observable<any> {
    return this.apollo
      .watchQuery<any>({ query: query.homePageQuery })
      .valueChanges
      .pipe(
        map(response => {
          const completions = response.data.content.completions || {}
          return {
            poll: newOrNull(Poll, response.data.content.poll),
            document: newOrNull(Document, response.data.content.document),
            habit: newOrNull(Habit, response.data.content.habit),
            question: newOrNull(Question, response.data.content.question),
            scheduleDay: response.data.content.scheduleDay,
            completions: completions
          }
        })
      )
  }

  updateCompletions(proxy: DataProxy, completionId: string) {
    return true
    const data: any = proxy.readQuery({ query: query.homePageQuery })
    const day = `Day:${data.content.scheduleDay}`
    set(data.content.completions, ['ByDay', day, completionId], true)
    set(data.content.completions, ['ByType', completionId], true)
    proxy.writeQuery({ query: query.homePageQuery, data })
  }
}
