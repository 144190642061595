import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ModalController, NavController } from "@ionic/angular";
import { ToastrService } from "ngx-toastr";
import { AlertActionCompleteComponent } from "../alert-action-complete/alert-action-complete.component";
import { AppState } from "../app.service";
import { HabitListItem } from "../habit-list-modal/habit-list-modal.model";
import { HabitService } from "../habit/habit.service";
import { HomeV5Service } from "../pages/home-v5/home-v5.service";
import { Path } from "../path/path.model";
import { RatingAlertBoxComponent } from "../rating-alert-box/rating-alert-box.component";
import { RoutineV2Service } from "../routine-v2/routine-v2.service";
import { SpinnerService } from "../spinner.service";
import { ToasterService } from "../toaster.service";
import { User } from "../user/user.model";
import { Location } from '@angular/common';
import { CompletedReviewModalComponent } from "../completed-review-modal/completed-review-modal.component";
import * as moment from 'moment'
import { CordovaService } from "../cordova.service";
import { AlertWebactionCalendarComponent } from "../alert-webaction-calendar/alert-webaction-calendar.component";
import { CalendarService } from "../calendar.service";
import { InoutScreen } from "../inout-screen/inout-screen.model";
import mixpanel from "mixpanel-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { RoutineService } from "../daily-content-v2/routine.service";
import { I18n } from "../i18n.service";

export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}

@Component({
  selector: 'single-habit-info',
  templateUrl: './single-habit-info.component.html',
  styleUrls: ['./single-habit-info.component.scss']
})
export class SingleHabitInfoComponent implements OnInit {
  habit: any
  currentPath: Path;
  currentUser: User;
  habits: HabitListItem[];
  currentDay: number
  routineList: any
  isCheckedAll: boolean = false
  routineLevels: any
  isExpanded: boolean = false
  routineId: number
  scheduledDay: number
  isMobile: boolean
  calendarParams: CalendarParams;
  hasNativeCalendar: boolean;
  inoutScreen: InoutScreen;
  screensIds = []
  screensEnabled = {}
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  audioFormats = [".mp3"];
  @Output() closeItemEvent = new EventEmitter<boolean>();
  homeUIService: any;
  constructor(private appState: AppState,
    private routineService: RoutineService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private homeService: HomeV5Service,
    private tostrService: ToastrService,
    private i18n: I18n,
    private habitService: HabitService,
    private spinnerService: SpinnerService,
    private nav: NavController,
    private location: Location,
    private modalCtrl: ModalController,
    private toasterService: ToasterService,
    private cordovaService: CordovaService,
    public route: ActivatedRoute,
    public router: Router,
   // @Inject(MAT_DIALOG_DATA) public data: any,
    private calendarService: CalendarService,) {
    this.habit = this.appState.get('singleHabit')
    console.log("open", this.habit)
    this.currentPath = this.appState.get("currentPath");
    this.currentUser = this.appState.get("currentUser");
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
    this.isMobile = this.appState.get('isMobile')
    const w: any = window
    this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    this.currentDay = +this.habit.dayNum
  }

  ngOnInit() {
    console.log("this.currentDay", this.currentDay)
    this.setInScreen();
    // this.data= this.data;
    // console.log("data insinglr", this.data);
  }

  addNewItem(value: boolean) {
    this.closeItemEvent.emit(value);
  }

  setInScreen() {
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    if (
      this.inoutScreen &&
      this.inoutScreen.preScreens &&
      this.inoutScreen.preScreens.length > 0
    ) {
      this.inoutScreen.preScreens.forEach((screen, i) =>
        this.addScreen('daily-content-screen-intro-' + i)
      )
    }
  }

  addScreen(id) {
    this.screensIds.push(id)
    this.screensEnabled[id] = true
  }

  findForCurrentDay(collection: any): any {
    console.log(collection)
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }
  openDocument(post) {
    let posts;
    post?.documents?.forEach((doc)=>{
      if(doc?.id == post?.casualDocumentId) {
        posts =doc;
      }
    })
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    this.appState.set('journalPost', undefined)
    if (posts.isrecommended && posts.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
    this.dialog.closeAll();
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', posts.startDay)
    const url = posts.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      mixpanel.track('Commitments-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
      this.router.navigate(['/audio-library'], { state: { data: posts } })
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      mixpanel.track('Commitments-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
      this.router.navigate(['/video-library'], { state: { data: posts } })
    } else {
      console.log('Document');
      mixpanel.track('Commitments-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
      this.router.navigate(['/detail-document'])
     
    }
  }

  goToBack() {
    this.location.back();
    //this.nav.back();
  }

  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }

  async initModal(habit) {
    // this.appState.set('currentDay', habit.dayNum)
    habit.isCompleted = true;
    habit.scheduledLater = false;
    const modal = await this.modalCtrl.create({
      component: CompletedReviewModalComponent,
       componentProps: { currentHabit: habit },
      cssClass: this.homeUIService.isDark() ? 'dark-theme-review-modal mobile-custom-to_do_list-forms' : 'light-theme mobile-custom-to_do_list-forms',
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.cdRef.detectChanges()
        this.setActionsList()
      }
    });

    return await modal.present();
  }

  setActionsList() {
    this.habits = new Array(this.currentPath.daysOfContent)
      .fill(0)
      .map((value, index) => {
        const dayNum = index + 1;
        const habit = this.currentPath.habits.find(
          item => item.startDay <= dayNum && dayNum <= item.endDay
        );
        if (habit) {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.title,
            text: habit.text,
            casualDocumentId: habit?.casualDocumentId,
            documents: habit?.documents,
            scheduledLater: habit.confirmation < 1 && habit.scheduledLater > 0,
            confirmed: habit.confirmation > 0,
            isConvertedToRoutine: habit.isConvertedToRoutine,
            isCompleted: habit.isCompleted,
            screenId: "daily-content-screen-habit"
          };
        }
        return {
          id: null,
          dayNum: dayNum,
          title: null,
          text: null,
          casualDocumentId: null,
          documents: null,
          scheduledLater: false,
          confirmed: false,
          isConvertedToRoutine: false,
          isCompleted: false,
          screenId: ""
        };
      });

    this.habits.filter(
      item => item.isCompleted !== true
    )

  }

  async markAsDone(habits) {
    mixpanel.track("Commitments-Mark Habit Complete", {'title': habits.title, 'startDay': habits.startDay, 'screenId': habits.screenId,'pathName': habits.pathName,'isConvertedToRoutine': habits.isConvertedToRoutine, 'id': habits.id,'casualDocumentId': habits.casualDocumentId})
    if (this.scheduledDay < habits.dayNum) {
      this.tostrService.warning(this.i18n.t("Please unlock step from homepage!"))
    } else {
      this.addNewItem(false)
      var habit = this.findHabitbyId(this.currentPath.habits, habits.id)
      if (!habit.isCompleted) {
        this.habitService.toggleHabit(habit.id).subscribe(
          response => {
            console.log('commit response', response)
          },
          error => {
            console.log('commit error', error)
          },
          () => { }
        )
      }

      try {
        this.habitService.confirm(habit.id).subscribe(res => {
          // LUMBA-1344
          console.log("Habit confirmed ", res);
        })
      } catch (o_0) {
        console.log('Errors ', o_0)
      }

      try {
        if (this.currentPath.enabledAttributes.feedback && habit.isCompleted) {
          this.initModal(habits);
          console.log("initModal")
        } else {
          console.log("not-initModal")
          this.setActionsList();
          this.cdRef.detectChanges()
          this.spinnerService.off()
          // this.goToBack()
        }
      } catch (o_O) {
        console.log(o_O);
      }
    }

  }

  async reschedule(habits) {
    let posts, docLink;
    habits?.documents?.forEach((doc)=>{
      if(doc?.id == habits?.casualDocumentId) {
        posts =doc;
      }
    })
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    mixpanel.track("Commitments-Reschedule Habit", {'id': habits.id, 'pathName': habits.pathName, 'isConvertedToRoutine': habits.isConvertedToRoutine, 'screenId': habits.screenId, 'title': habits.title, 'startDay': habits.startDay})
    this.prepareCalendarParams(this.innerHTMLToMainTitle(), this.routineService.innerHtmlToMobileAction(habits.text,docLink))
    if (this.hasNativeCalendar) {
      this.createCalendarEvent()
    } else {
      this.calendarParams.notes = this.routineService.innerHtmlToWebAction(habits.text,docLink)
      this.homeService.setCalendarData(this.calendarParams)
      const dialogRef = this.dialog.open(AlertWebactionCalendarComponent)
      dialogRef.afterClosed().subscribe((result) => {
      })
    }

    // localStorage.setItem(
    //   `Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`,
    //   'yes'
    // )

    // // this.spinnerService.on()
    // this.habitService.scheduleLater(this.yesterdayHabit.id).subscribe(res => {
    //   // LUMBA-1344
    //   // this.spinnerService.off()
    //   this.cdRef.detectChanges()

    // })
  }
  innerHtmlToWebAction(habit: any) {
    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);
    return cleanText + "\n\n After you have completed this action, mark it complete in the Comet Pro app by clicking here: https://cometpro.page.link/rM3L";
  }

  createCalendarEvent() {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);
    const showAlertPrompt=true
    const daysCount = this.currentPath.daysOfContent * 2  // LUMBA-1399
    // calOptions.recurrence = 'daily' // supported are: daily, weekly, monthly, yearly
    calOptions.recurrenceInterval = 1
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()

    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error,
      showAlertPrompt
    );
  }

  innerHTMLToMainTitle() {
    // habits.text
    var divMainTitle = document.createElement("DIV");
    divMainTitle.innerHTML = this.inoutScreen?.homepageSubtext.text;
    let tv_mainTitle = divMainTitle.innerText;
    return tv_mainTitle;
  }

  prepareCalendarParams(title: any, msg: String, routinelen: any = null, eventlen: string = null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    var titleOfEvent = "Daily Learning Reminder"

    if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
      titleOfEvent = this.currentPath.names.titleOfEvent;
    } else {
      titleOfEvent = "Daily Learning Reminder"
    }

    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }

  innerHtmlToNotesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    return cleanText + "\n\n Here is the tool you saw in the Comet Pro app to help you complete this action: " + arrayOfUrls + "\n\n After you have completed this action, mark it complete in the Comet Pro app by clicking here: https://cometpro.page.link/rM3L";
  }
}