
const DENONESCOPE = 'denone_state'
const LULULEMONSCOPE = 'lululemon_state'
const EXXONSCOPE = 'exxon_state'
const SSO_REDIRECT_URL = 'https://comet.1st90.com/login'
const exxon_redirecturi = "com1st90://login";
export const environment = {
  production: true,
  development: false,
  // graphQLUrl: 'https://api-stage.1st90.com/graphql',
  // apiBaseUrl: 'https://api-stage.1st90.com/api/v1',
  // fooziBaseUrl: 'https://api-stage.1st90.com/chat/api',
  // backendUrl: 'https://api-stage.1st90.com',
  // faqUrl: 'https://api-stage.1st90.com/required_docs/universal_faq.pdf',
  // // graphQLUrl: "http://localhost:3000/graphql",
  // // apiBaseUrl: "http://localhost:3000/api/v1",
  // // backendUrl: 'http://localhost:3000',
  // // faqUrl: 'https://localhost:3000/required_docs/universal_faq.pdf',
  // termsUrl: 'https://1st90-api-images.s3.amazonaws.com/1st90/1st90_Terms+and+Conditions.pdf',
  // privacyUrl: 'https://1st90-api-images.s3.amazonaws.com/1st90/1st90_Privacy+Policy_2023.pdf',
  // openidSAP: `https://accounts.sap.com/oauth2/authorize?scope=openid%20profile%20email&redirect_uri=${SSO_REDIRECT_URL}&response_type=code&client_id=b8137510-de15-4696-8770-944c6980995a&state=state`,
  // defaultPathGroupName: '1st90',
  // preSignedUrl: 'https://1st90-stage-images.s3.amazonaws.com/',
  // firebaseConfig: {
  //   apiKey: "AIzaSyBQzYDLpqjVGBi_iGV_RrhoWfBaJmZZlhM",
  //   authDomain: "st90-31f90.firebaseapp.com",
  //   databaseURL: "https://st90-31f90.firebaseio.com",
  //   projectId: "st90-31f90",
  //   storageBucket: "st90-31f90.appspot.com",
  //   messagingSenderId: "1038070742879",
  //   appId: "1:1038070742879:web:b80b464c46779203fdc0ea",
  //   measurementId: "G-XZR7N01BM3"
  // },

  // // sap
  // userSSOSAP: "b8137510-de15-4696-8770-944c6980995a",
  // secretSSOSAP: "Sn:JCZEK4Yhl[6xc_B6ZyTkiYWTJ1S]1",
  // clientIdSAP: 'b8137510-de15-4696-8770-944c6980995a',
  // GET_TOKEN_SAP: " https://m308gnl9x7.execute-api.us-east-1.amazonaws.com/dev/?url=https://accounts.sap.com/oauth2/token",
  // GET_USER_INFO_SAP: "https://m308gnl9x7.execute-api.us-east-1.amazonaws.com/dev/?url=https://accounts.sap.com/oauth2/userinfo",
  // SAP_CODE: 'sap_code',

  // // Danone
  // GET_TOKEN_DENONE: "https://login.microsoftonline.com/danone.onmicrosoft.com/oauth2/v2.0/token",
  // GET_USER_INFO_DENONE: "https://graph.microsoft.com/oidc/userinfo",
  // SECREATDENONE: "M6H7Q~KUT-ZKy9aLnEeTkK5w3TEScIGi7dk88",
  // CLIENTIDDENONE: "795fcbd7-9a42-418f-930e-b64034793130",
  // SSODENONEURL: `https://login.microsoftonline.com/danone.onmicrosoft.com/oauth2/v2.0/authorize?client_id=07df9c4c-adab-49f6-a511-2496936f3a66&response_type=id_token+token&redirect_uri=${SSO_REDIRECT_URL}&scope=openid&response_mode=fragment&state=${DENONESCOPE}&nonce=678910`,
  // SSO_REDIRECT_URL: SSO_REDIRECT_URL,
  // DENONE: 'DENONE',
  // DENONE_CODE: 'denone_code',
  
  // // Okta
  // SSO_OKTA_URL: `https://cohesity.okta.com/oauth2/v1/authorize?client_id=0oapftvn1i431hGOg2p7&response_type=id_token+token&response_mode=fragment&scope=openid%20profile%20email&redirect_uri=${SSO_REDIRECT_URL}&state=123&nonce=123`,
  // OKTA_CODE: 'okta_code',
  // CLIENT_ID_OKTA: '0oapftvn1i431hGOg2p7',
  // GET_USER_INFO_OKTA: "https://m308gnl9x7.execute-api.us-east-1.amazonaws.com/dev/?url=https://cohesity.okta.com/oauth2/v1/userinfo",
  // OKTA: 'OKTA',

  // // LULULEMON
  // GET_TOKEN_LULULEMON: "https://login.microsoftonline.com/59762c14-55e8-4b58-806e-f6cc47d75b19/oauth2/v2.0/token",
  // GET_USER_INFO_LULULEMON: "https://graph.microsoft.com/oidc/userinfo",
  // CLIENT_ID_LULULEMON: "3e8c291b-51ae-4538-84f8-84f49034aba5",
  // SSO_LULULEMON_URL: `https://login.microsoftonline.com/59762c14-55e8-4b58-806e-f6cc47d75b19/oauth2/v2.0/authorize?client_id=3e8c291b-51ae-4538-84f8-84f49034aba5&response_type=id_token+token&redirect_uri=${SSO_REDIRECT_URL}&scope=openid&response_mode=fragment&state=${LULULEMONSCOPE}&nonce=678910`,
  // LULULEMON: 'LULULEMON',
  // LULULEMON_CODE: 'lululemon_code',

  // //EXXON
  // GET_TOKEN_EXXON: "https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/oauth2/v2.0/token",
  // GET_USER_INFO_EXXON: "https://graph.microsoft.com/oidc/userinfo",
  // SECRET_EXXON: "hGt8Q~djknZUpwMJXiYqolhKu9mMQoff_IiqdasL",
  // CLIENT_ID_EXXON: "ecc28546-5c10-4e1b-a1e2-1020744f3373",
  // SSO_EXXON_URL: `https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/oauth2/v2.0/authorize?client_id=ecc28546-5c10-4e1b-a1e2-1020744f3373&response_type=id_token+token&redirect_uri=${SSO_REDIRECT_URL}&scope=openid&response_mode=fragment&state=${EXXONSCOPE}&nonce=678910`,
  // IOS_SSO_EXXON_URL: `https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/oauth2/v2.0/authorize?client_id=ecc28546-5c10-4e1b-a1e2-1020744f3373&response_type=id_token+token&redirect_uri=${exxon_redirecturi}&scope=openid&response_mode=fragment&state=${EXXONSCOPE}&nonce=678910`,
  // EXXON: 'EXXON',
  // EXXON_CODE: 'exxon_code',
  // SSO_EXXON_CALLBACK_URL: exxon_redirecturi,
  // mixPanelToken: '8dd8929d2c63ffd74ecb7a9b2378bee0',


  // PROD ENV
  graphQLUrl: 'https://api.1st90.com/graphql',
  apiBaseUrl: 'https://api.1st90.com/api/v1',
  backendUrl: 'https://api.1st90.com/',
  fooziBaseUrl: 'https://api.1st90.com/chat/api',
  faqUrl: 'https://api.1st90.com/required_docs/universal_faq.pdf',
  termsUrl: 'https://api.1st90.com/required_docs/terms.pdf',
  privacyUrl: 'https://api.1st90.com/required_docs/1st90_Privacy%20Policy_2023.pdf',
  defaultPathGroupName: '1st90',
  preSignedUrl: 'https://1st90-api-images.s3.amazonaws.com/',
  openidSAP: `https://accounts.sap.com/oauth2/authorize?scope=openid%20profile%20email&redirect_uri=${'https://comet.1st90.com/login'}&response_type=code&client_id=b8137510-de15-4696-8770-944c6980995a&state=state`,
  openidDenone: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=6731de76-14a6-49ae-97bc-6eba6914391e&response_type=code&redirect_uri=http://localhost/myapp/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=state`,
  firebaseConfig: {
    apiKey: "AIzaSyBQzYDLpqjVGBi_iGV_RrhoWfBaJmZZlhM",
    authDomain: "st90-31f90.firebaseapp.com",
    databaseURL: "https://st90-31f90.firebaseio.com",
    projectId: "st90-31f90",
    storageBucket: "st90-31f90.appspot.com",
    messagingSenderId: "1038070742879",
    appId: "1:1038070742879:web:b80b464c46779203fdc0ea",
    measurementId: "G-XZR7N01BM3",
  },
   // sap
  userSSOSAP: "b8137510-de15-4696-8770-944c6980995a",
  secretSSOSAP: "Sn:JCZEK4Yhl[6xc_B6ZyTkiYWTJ1S]1",
  clientIdSAP: 'b8137510-de15-4696-8770-944c6980995a',
  GET_TOKEN_SAP: " https://m308gnl9x7.execute-api.us-east-1.amazonaws.com/dev/?url=https://accounts.sap.com/oauth2/token",
  GET_USER_INFO_SAP: "https://m308gnl9x7.execute-api.us-east-1.amazonaws.com/dev/?url=https://accounts.sap.com/oauth2/userinfo",
  SAP_CODE: 'sap_code',

  // Danone
  GET_TOKEN_DENONE: "https://login.microsoftonline.com/danone.onmicrosoft.com/oauth2/v2.0/token",
  GET_USER_INFO_DENONE: "https://graph.microsoft.com/oidc/userinfo",
  SECREATDENONE: "M6H7Q~KUT-ZKy9aLnEeTkK5w3TEScIGi7dk88",
  CLIENTIDDENONE: "795fcbd7-9a42-418f-930e-b64034793130",
  SSODENONEURL: `https://login.microsoftonline.com/danone.onmicrosoft.com/oauth2/v2.0/authorize?client_id=07df9c4c-adab-49f6-a511-2496936f3a66&response_type=id_token+token&redirect_uri=${SSO_REDIRECT_URL}&scope=openid&response_mode=fragment&state=${DENONESCOPE}&nonce=678910`,
  SSO_REDIRECT_URL: 'https://comet.1st90.com/login',
  DENONE: 'DENONE',
  DENONE_CODE: 'denone_code',

  //OKTA
  SSO_OKTA_URL: `https://cohesity.okta.com/oauth2/v1/authorize?client_id=0oapftvn1i431hGOg2p7&response_type=id_token+token&response_mode=fragment&scope=openid%20profile%20email&redirect_uri=${SSO_REDIRECT_URL}&state=123&nonce=123`,
  OKTA_CODE: 'okta_code',
  CLIENT_ID_OKTA: '0oapftvn1i431hGOg2p7',
  GET_USER_INFO_OKTA: "https://m308gnl9x7.execute-api.us-east-1.amazonaws.com/dev/?url=https://cohesity.okta.com/oauth2/v1/userinfo",
  OKTA: 'OKTA',

  // LULULEMON
  GET_TOKEN_LULULEMON: "https://login.microsoftonline.com/59762c14-55e8-4b58-806e-f6cc47d75b19/oauth2/v2.0/token",
  GET_USER_INFO_LULULEMON: "https://graph.microsoft.com/oidc/userinfo",
  CLIENT_ID_LULULEMON: "a050126e-70a4-4357-a25d-e9730a00c287",
  SSO_LULULEMON_URL: `https://login.microsoftonline.com/59762c14-55e8-4b58-806e-f6cc47d75b19/oauth2/v2.0/authorize?client_id=a050126e-70a4-4357-a25d-e9730a00c287&response_type=id_token+token&redirect_uri=${SSO_REDIRECT_URL}&scope=openid&response_mode=fragment&state=${LULULEMONSCOPE}&nonce=678910`,
  LULULEMON: 'LULULEMON',
  LULULEMON_CODE: 'lululemon_code',

  //EXXON
  GET_TOKEN_EXXON: "https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/oauth2/v2.0/token",
  GET_USER_INFO_EXXON: "https://graph.microsoft.com/oidc/userinfo",
  SECRET_EXXON: "hGt8Q~djknZUpwMJXiYqolhKu9mMQoff_IiqdasL",
  CLIENT_ID_EXXON: "ecc28546-5c10-4e1b-a1e2-1020744f3373",
  SSO_EXXON_URL: `https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/oauth2/v2.0/authorize?client_id=ecc28546-5c10-4e1b-a1e2-1020744f3373&response_type=id_token+token&redirect_uri=${SSO_REDIRECT_URL}&scope=openid&response_mode=fragment&state=${EXXONSCOPE}&nonce=678910`,
  IOS_SSO_EXXON_URL: `https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/oauth2/v2.0/authorize?client_id=ecc28546-5c10-4e1b-a1e2-1020744f3373&response_type=id_token+token&redirect_uri=${exxon_redirecturi}&scope=openid&response_mode=fragment&state=${EXXONSCOPE}&nonce=678910`,
  EXXON: 'EXXON',
  EXXON_CODE: 'exxon_code',
  SSO_EXXON_CALLBACK_URL: exxon_redirecturi,

  mixPanelToken: '3a59bb7db2c44f1d86fd4dc5e859fa81'


};

