import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import mixpanel from 'mixpanel-browser';
import { AppState } from '../app.service';
import { CordovaService } from '../cordova.service';
import { Option } from '../option/option.model';
import { Path } from '../path/path.model';
import { Poll, PollStats } from '../poll/poll.model';
import { PollService } from '../poll/poll.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { expandOnEnterAnimation, slideInDownAnimation } from 'angular-animations';
import { HomeUiService } from '../new-design/home-ui.service';
import { I18n } from '../i18n.service';

// export interface MatRangeSliderChange<T> {
//   value: T;
// }
@Component({
  selector: 'first90-linear-scale',
  templateUrl: './linear-scale.component.html',
  styleUrls: ['./linear-scale.component.scss'],
  animations:[
    expandOnEnterAnimation({ duration: 400 }),
    slideInDownAnimation({duration:300})
  ],
})
export class LinearScaleComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() parentComponentName: string;
  @Input() poll: Poll
  @Input() isPreview: boolean
  @Input() inverted = false
  @Output() blurCard= new EventEmitter<any>();
  @Output() scaleEvent = new EventEmitter<any>();
  @Input() enable= false;
  @Input()   pathAverage;

  enabled = true // TODO: Get it from the path
  nameTQ: string // TODO: Get it from the path
  nameHYPA: string // TODO: Get it from the path
  path: Path
  stats: PollStats
  status: string
  chart = []
  loading: boolean = false;
  statsPer: number[]
  currentUser: User
  enableFinal: boolean =false;
  lowerScale: number
  isMobileWebIos = false;
  isMobileWeb=false;
  higherScale: number
  scaleValue: any
  answerList: any
  linearScale = 0
  isMobile: boolean
  constructor(
    private pollService: PollService,
    private appState: AppState,
    private cordovaService: CordovaService,
    public tostrService: ToasterService,
    private i18n: I18n,
    private cdRef: ChangeDetectorRef,// LUMBA-1308
    public homeUIService: HomeUiService,
  ) {
    this.currentUser = this.appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    this.isEnabled()
    this.initNames()
    this.isMobile = this.appState.get('isMobile')
  }

  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    console.log("this.isMobileWeb value", this.isMobileWeb);
    this.loading = false;
    // if (this.poll && this.poll.isCompleted) {
    //   this.stats = this.poll.answer.stats
    // }
    if (this.poll.lowerscale && this.poll.higherscale || this.poll.lowerscale == 0) {
      this.lowerScale = this.poll.lowerscale
      this.higherScale = this.poll.higherscale
    }else{
      this.lowerScale = 1
      this.higherScale = 10
    }
    

    // if (this.poll.isCompleted) {
    //   this.getScaleAnswers(this.currentUser.id,this.path.id,this.poll.id)
    // }
   if(this.poll?.ptype =='linear') {
    this.getScaleAnswers(this.currentUser.id,this.path.id,this.poll.id)
   }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngAfterViewInit() {
    if (this.poll.isCompleted && this.poll?.ptype =='linear') this.setStats()
  }
  enableBlur() {
    this.enable=!this.enable;
   // if(this.enable)
   let value,scale, pathAverage ;
   value =this.linearScale?.toString()
   scale=this.higherScale.toString()
   pathAverage=this.pathAverage;
      this.blurCard?.emit({
        enable: this.enable,
        linearScale: value+"/"+scale,
        pathAverage: pathAverage+'/'+scale,
        poll: this.poll
      })
      
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!changes['poll'] || changes['poll'].firstChange) { return false }
    if (this.poll && this.poll.isCompleted) {
      this.stats = this.poll.answer.stats
      console.log("this.stats", this.stats)
      window.requestAnimationFrame(() => {
        this.setStats()
      })
    }
  }

  // onOptionClicked(option: Option) {
  //   if (this.poll.isCompleted) { return false }
  //   if (this.isPreview) { return false }
  //   this.loading = true
  //   //this.spinnerService.on()
  //   this.poll.isCompleted = true;
  //   option.isSelected = true
  //   this.cdRef.detectChanges()
  //     this.pollService.answerPoll(this.poll, option.id).subscribe(response => {
  //     this.stats = response.answer.stats
  //     this.setStats()
  //     this.loading = false
  //     this.cdRef.detectChanges()
  //    //this.spinnerService.off()
  //   }, error => {
  //     option.isSelected = false
  //     this.loading = false
  //     this.cdRef.detectChanges() // LUMBA-1308
  //   })  
  // }

  setStats() {
    if (!this.stats) {
      return
    }
    if (!this.stats.data) {
      return
    }
    if (!this.stats.data.length) {
      return
    }

    const total = this.stats.data.reduce((sum, n) => sum + n)
    if (!total) {
      return
    }

    this.statsPer = this.stats.data.map(n => Math.floor(n * 100 / total))
    console.log("statsper", this.statsPer);
  }

  private isEnabled() {
    this.enabled = this.path.enabledAttributes['polls']
  }

  private initNames() {
    this.nameTQ = this.path.getCustomText('p_tq')
    this.nameHYPA = this.path.getCustomText('p_hypa')
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        console.log('cordova click')
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

  // formatLabel(value: number) {
  //   if (value >= 1000) {
  //     return Math.round(value / 1000) + 'k';
  //   }
  //   console.log(value);
  //   // this.scaleValue = value
  //   // this.scaleEvent.emit(this.scaleValue);
  //   return value;
  // }

  onInputChange(event: MatSliderChange) {
    this.scaleValue =event.value;
    console.log("on input chnge", this.scaleValue, event.value)
    this.enableFinal =true;
    
  }

  finalize(){
    this.poll.isCompleted = true;
    console.log("on input chnge", this.scaleValue, this.scaleValue.toString())
    this.enableFinal =false;
    this.enable =true;
    mixpanel.track('linear scale event value', {'value': this.scaleValue})
    this.scaleEvent.emit(
      {
        scaleValue:this.scaleValue.toString(),
        question: this.poll,
        higherScale: this.higherScale.toString()
      });
  }

  getScaleAnswers(userId: number, pathId: number, questionId: number){
    const requestData = {
      'user_id': userId,
      'path_id': pathId,
      'linear_question_id': questionId
    }

    if(!requestData){
      // this.spinnerService.off();
    }else{
      this.pollService.getLinearScaleAnswers(requestData).subscribe(
        (response) => {
          this.answerList = response.data
          for (let linear of this.answerList ) {
            this.linearScale = linear.linear_rank.linear_rating;
            this.pathAverage =linear.path_average;
            localStorage.setItem('pathAverage',linear.path_average)
          }
          this.cdRef.detectChanges()
        },
        (error) => {
          this.tostrService.error(this.i18n.t('Something went wrong..! Try Again'))
        }
      )
    }
  }

}
