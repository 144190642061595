import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AppState } from '../app.service';
import { ClientService } from '../client/client.service'
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { CohortService } from '../cohorts/cohort.service'
import { Location } from '@angular/common';
import { slideInLeftOnEnterAnimation, slideOutLeftOnLeaveAnimation, slideOutRightAnimation, slideOutRightOnLeaveAnimation } from 'angular-animations';
import { ModalController,Platform } from '@ionic/angular';
import { I18n } from '../i18n.service';
import Swiper from 'swiper';
import { CordovaService } from '../cordova.service';
import { ThemeService } from '../theme/theme.service';
import { UserService } from '../user/user.service';
import { SessionService } from '../session/session.service';
import { HomeUiService } from '../new-design/home-ui.service';
export interface Collapse {
  isCollapsable: boolean;
  isCollapsed: boolean;
}

@Component({
  selector: 'app-all-paths',
  templateUrl: './all-paths.component.html',
  styleUrls: ['./all-paths.component.scss'],
  animations:[slideOutRightAnimation({duration:500}),
  slideOutRightOnLeaveAnimation({duration:100}),
  slideOutLeftOnLeaveAnimation({duration:150}),
  slideInLeftOnEnterAnimation({duration:500}),
  slideOutRightAnimation({duration:500})
]
})
export class AllPathsComponent implements OnInit {

  // @Input() 'showReplayButton': boolean // LUMBA-1312
  isMobile;
  animationState;
  client: any
  @Output() animationStateChange = new EventEmitter()
  collapse: Array<Collapse>=[];
  isExpanded: boolean[] = []
  alldata: any
  allpath: any
  swiperInitialized = true;
  inoutScreens: any = []
  clientId: any
  currentpathId: any
  user: any;
  isonboardingcompleted: any;
  isActivePath: any;
  currentPath: Path
 @ViewChild('swiperContainer') swiperContainer: any;
  swiper: Swiper;
  dayHasContent: boolean
  activeDay: number
  scheduleDays: number[]
  lengthOfSnoozed: number
  dayNumber: number
  showCloseoutWelcomeScreen: boolean
  activeCloseoutWelcomeScreen: boolean // LUMBA-1312
  scheduleDay: number
  currentDayStarted: boolean
  dayHasIncompleteMandatory: boolean
  currentDayCompleted: boolean
  showContinueButton: boolean
  showBeginButton: boolean
  showCompletedMessage: boolean
  currentDayTitle: string
  currentDaySubtext: string
  allContentCompleted: boolean
  scheduledStep: any
  showReplayButton: boolean
  showUnlockButton: boolean = false
  maxAllowedScheduleDay: number 
  cohorts: any
  selectedPathsList = []
  pathRegistration;
  enabledAttributes: any
  pathVersion: boolean
  userEmail: string
  onCordova: boolean = false // LUMBA-982
  pathGroups: any
  rowHovered: boolean[] = [];
  filteredPathsGroups: any[] = [];
  expandedPaths: { [key: string]: boolean } = {};
  reviewPaths= [];
  resumePaths = []
  slidesPerView;
  showSwiperBtn = false;
  @ViewChild('textCount') textCount: ElementRef;
  @ViewChildren('cardElement') cardElement: ElementRef[];
  maxHeight: number = 80;
  swiperInstances: { [key: string]: Swiper } = {};
  dailyStepsArray;
  // textareaContentLength=0;
   //set these to false to get the height of the expended container 
  constructor(
    private apollo: Apollo,
    private clientService: ClientService,
    private homeService: HomeV5Service,
    private appState: AppState,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private render: Renderer2,
    private modalController: ModalController,
    public cohortService: CohortService,
    public location: Location,
    public i18n: I18n,
    public homeUIService: HomeUiService,
    public platform: Platform,
    public cordovaService: CordovaService,
    private themeService: ThemeService,
    private homeUiService: HomeUiService,
    private userService: UserService,
    public sessionService: SessionService
  ) {
    this.onCordova = this.cordovaService.onCordova
    this.user = this.appState.get('currentUser')
    this.userEmail = this.user.email
    this.currentPath = this.appState.get('currentPath')
    const clientId = localStorage.getItem('clientId')
    this.allpath =this.appState.get("allPathsData")
    if (clientId) {
      this.clientId = clientId
    }
    
    this.setPathVersion()

    this.pathRegistration = this.appState.get('pathRegistration')
    this.scheduleDay = +this.appState.get('currentScheduleDay')
    this.activeDay = this.scheduleDay
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')
    this.maxAllowedScheduleDay = this.appState.get('daysOfContent')
    this.dailyStepsArray = this.appState.get("DailyStepsData");  
    this.checksignupcode(this.userEmail, this.clientId, this.onCordova); 
    let isPathCompleted = this.allpath?.filter((path) => path?.id == this.currentPath?.id && path.isPathCompleted == 2)
    const activePathId = localStorage.getItem('activePathId')
    if (activePathId) {
      this.currentpathId = parseInt(activePathId, 10)
    }
    this.cohorts =this.appState.get("cohortsData")
    if(this.dailyStepsArray[this.dailyStepsArray?.length-1]?.stepStatus=='Review' && !this.currentPath?.dailySteps[this.dailyStepsArray?.length-1]['stepStatus']){
         this.allpaths('no-cache')
    }
    else {
       this.allpaths();
    }
  }

  setPathVersion(){
    this.enabledAttributes = this.appState.get("routineVersion")
    if (this.enabledAttributes.pathVersion) {
      this.pathVersion = this.enabledAttributes.pathVersion
    } else {
      this.pathVersion = false
    }
  }

  closePage(){
    this.appState.set('isAllPaths', false);
    this.homeUIService.getLastModal();
    this.modalController.dismiss();
  
  }
  ngOnInit() { 
    this.isMobile = this.appState.get('isMobile')
  }
  ngAfterViewInit() {
    //setTimeout(() => {
             //   
      //this.initSwipers();
   // }, 200);
  }

getCollapsedText() {

  if(this.selectedPathsList?.length>0 && this.selectedPathsList?.length==this.collapse?.length) {
    return true;
  }
 else if(this.appState.get("isAllPaths") && this.selectedPathsList?.length>this.collapse?.length){
  setTimeout(() => {
    this.cardElement?.forEach((cardElement, index) => {
   const cardHeight = cardElement.nativeElement.offsetHeight;
   if (cardHeight > this.maxHeight) {
     this.collapse.push({isCollapsable :true,isCollapsed :true})
     this.render.addClass(this.textCount.nativeElement, 'reply-texts');
   
 }else {
   this.collapse.push({isCollapsable :false,isCollapsed :false})

 }

    })
  
 },100);
return true;

  }
  else {
    return true;
  }
}

  isDayLocked(day_number){
    if (day_number > this.scheduledStep && this.scheduledStep != this.maxAllowedScheduleDay ) {
      this.showUnlockButton = true
    } else {
      this.showUnlockButton = false
    }
  }

   async allpaths(type?) {
    await this.clientService.getallpath(Number(this.clientId),type)
      .subscribe(async (client) => {
        this.client = client
        this.alldata = client._data
        this.allpath = this.alldata.paths
        this.appState.set('allPathCount', client._data['paths']?.length);
        this.appState.set("allPathsData", client._data['paths']);
        if (this.allpath?.length > 0) {
          this.reviewPaths = this.allpath.filter(path => path.isPathCompleted === 2);          
        }      
        console.log("this.cohorts?.length", this.cohorts?.length);
        if (this.cohorts?.length == 0) {
          setTimeout(() => {
           this.getCohortPaths(this.user.cohortId, this.cohorts, this.allpath)
           this.getPathsFromCohortForWeb(this.user.cohortId, this.allpath)
          },700)
        }
        else {
          this.getCohortPaths(this.user.cohortId, this.cohorts, this.allpath)
          this.getPathsFromCohortForWeb(this.user.cohortId, this.allpath);
        }
        this.steps()
      })
  }

  steps() {
    for (let index = 0; index < this.allpath.length; index++) {
      const element = this.allpath[index].inoutScreens;
      this.inoutScreens.push(element)
    }
  }

  textExpand(path,collpase) {
    if(collpase){
      path.isCollapsed = false;
      path.isCollapsable = true;
    }
    else {
      path.isCollapsed = true;
      path.isCollapsable = true;
    }
    this.cdRef.detectChanges();
  }

  public changePath(active_path_id) {
    const request = {
      'user_id': this.user.id,
      'active_path_id': active_path_id
    }
    // this.spinnerService.on();
    this.homeService.subpathchange(request).subscribe(
      (response) => {
        // this.spinnerService.off();
        this.appState.set('isAllPaths', false)
        this.appState.set("screensViewed", undefined);
        this.appState.set('stepsArray', undefined)
        this.appState.set('stepObject', undefined)
        this.appState.set('levelUpdateData', undefined)
        this.appState.set('allLevelsCompleted', undefined)
        this.appState.set('levelUpdated', undefined)
        this.appState.set('showUpdatedScreens', undefined)
        this.appState.set('levelSaveData', undefined)
        this.appState.set('weekDays', undefined)
        this.appState.set("activeCloseoutWelcomeScreen", undefined);
        localStorage.removeItem('stepCompleted')
        let data = response["data"];
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
            } else if (this.isonboardingcompleted === true) {
              this.router.navigate(['/main']);
            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.router.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            this.homeUiService.checkSubpathStatus();
            this.router.navigate(['/main']);
          }
      }catch (error) {
          this.router.navigate(['/main']);
          //this.toasterService.error('Server or connection issue. Please check your internet connection.')
        }
        this.refresh()
      }, (error) => {
        console.log('error for path change:', error);
        // this.spinnerService.off();
        this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
      }
    )
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  showChangePath(pathId){
    if(pathId!=this.currentpathId){
      // this.getCollapsedText()
      return true
    } 

    else false
  }

  get isLastCurrentDayCompleted(): boolean {
    
    const lastDayOfContent = this.currentPath.lastDayOfContent()
    var value = !!this.homeService.checkPrevDayCompleted(
      this.pathRegistration,
      lastDayOfContent,
      this.user,
      this.currentPath
    )
    return value
  }

  showCompletionStatus(index){
    this.scheduleDay = index
    this.isDayLocked(this.scheduleDay)
    this.scheduleDays = new Array(this.currentPath.daysOfContent)
      .fill(0)
      .map((item, index) => index + 1)
      
    this.currentDayStarted = !!localStorage.getItem(
      `Toggle:User:${this.user.id}:DayProgress:${this.currentPath.id}:${this.scheduleDay}`
    )
    this.currentDayCompleted = !!localStorage.getItem(
      `Toggle:User:${this.user.id}:DayCompleted:${this.currentPath.id}:${this.scheduleDay}`
    )
    this.allContentCompleted = this.appState.get('allContentCompleted')
    this.dayHasIncompleteMandatory = this.currentPath.checkMandatoryForDay(
      this.scheduleDay
    ) // LUMBA-1317
    this.showCloseoutWelcomeScreen = this.isLastCurrentDayCompleted

    this.currentDayTitle = this.currentPath.getHomepageTitleForDay(this.scheduleDay)
    this.currentDaySubtext = this.currentPath.getHomepageSubtextForDay(
      this.scheduleDay
    )
    this.dayHasContent = this.currentPath.checkContentForDay(this.scheduleDay)
    // Instead of maxAllowedScheduled we added scheduledStep cause maxAllowedScheduleDay is nothing but total day of content
    if (this.scheduleDay === this.scheduledStep) {
      if (this.showCloseoutWelcomeScreen) {
        if (this.scheduleDay < this.currentPath.daysOfContent) {
          // LUMBA-1435
          this.activeDay = this.currentPath.daysOfContent + 1
        } else {
          this.activeDay = this.scheduleDay + 1
        }
        this.activeCloseoutWelcomeScreen = true
      }
      this.showCompletedMessage =
        !this.dayHasIncompleteMandatory && this.dayHasContent
      this.showContinueButton =
        this.dayHasIncompleteMandatory && this.currentDayStarted // LUMBA-1339
      this.showReplayButton =
        this.dayHasContent &&
        !this.dayHasIncompleteMandatory &&
        (this.currentDayCompleted || this.currentDayStarted) // LUMBA-1339
      this.showBeginButton =
        !this.currentDayCompleted &&
        this.dayHasContent &&
        !this.currentDayStarted
    } else {
      this.showCompletedMessage = this.dayHasContent
      this.showContinueButton = false
      this.showReplayButton = this.dayHasContent
      this.showBeginButton = false
    }

    if (!this.showUnlockButton) {
      if((this.currentDayStarted && !this.showReplayButton) || this.showBeginButton){
        return 'In-progress'
      } 
      if(this.showReplayButton){
        return 'Completed'
      } 
    }
    
    if(this.showUnlockButton){
      return 'Locked'
    }

  }
  receiveState(event: any){
    setTimeout(() => {
      this.animationState = event
    }, 10);
  }
  

  getCohortPaths(cohortId: number, cohorts: any, allpaths: any) {
    this.selectedPathsList = [];
    let cohortPathList = []
    cohorts?.forEach(element => {
      if (element.id == cohortId) {
        cohortPathList = element?.paths
      }
    });

    allpaths?.forEach(allpathsElement => {
      cohortPathList?.forEach(cohortElement => {
        if (allpathsElement.id == cohortElement.id) {
          this.selectedPathsList.push(allpathsElement)
        }
      });
    });
    
    if (this.selectedPathsList.length <= 0) {
      this.selectedPathsList = allpaths
    }
    let activepath =[]
    activepath= this.selectedPathsList?.filter(path => path?.id == this.currentpathId)
    if(activepath && activepath?.length>0 && this.selectedPathsList?.length>0) {
      this.selectedPathsList =[activepath[0], ... this.selectedPathsList.filter(path => path != activepath[0])];
      console.log("this.selected path list", this.selectedPathsList);
    }

  }
  checksignupcode(email, clientId, onCordova){
    const clientSubDomain = this.themeService.getSubDomain() 
     this.userService.pathGroupsByToken(localStorage.getItem('token'))
    .subscribe(result => {
      this.pathGroups = result
      console.log("result", result)
      // this.getPathsFromCohortForWeb(this.user.cohortId, this.allpath);
    },
    error => {
      const messages = error.graphQLErrors.map(e => e.message)
    })
  }
  getPathsFromCohortForWeb(cohortId, allpath) {
    const cohortPaths = [];
    this.filteredPathsGroups = [];
    if (allpath?.length > 0) {
      this.reviewPaths = this.allpath.filter(path => path.isPathCompleted === 2);
      this.resumePaths = allpath.filter(path => path.isPathCompleted === 1 && this.showChangePath(path.id));
      console.log("resume paths", this.resumePaths);
    }  
    if (!cohortId || !this.cohorts?.find(cohort => cohort.id === cohortId)) {
      this.pathGroups?.forEach(group => {
        const matchingPaths = group.paths.map(path => ({
          ...path,
          category: allpath.find(p => p.id === path.id).isPathCompleted == 1 ? 'Resumed' : allpath.find(p => p.id === path.id).isPathCompleted == 2 ? 'Review' : 'Normal'
        }));
    matchingPaths?.forEach((itemA) => {
      const matchedItem = group?.userGroups?.find((itemB) => itemB.pathId === itemA.id);
      if (matchedItem) {
        itemA.name = matchedItem.name;
      }
    });
        if (matchingPaths.length > 0) {
          this.filteredPathsGroups.push({
            groupName: group.name,
            paths: matchingPaths
          });
        }
      });
    } else {
      const cohort = this.cohorts?.find(cohort => cohort.id === cohortId);
      if (cohort) {
        cohortPaths.push(...cohort.paths.map(path => path.id));
        cohortPaths.push(...this.user.adhocPaths);
      } 
      console.log('cohortPaths :', cohortPaths);
      this.resumePaths = this.resumePaths.filter(path => cohortPaths.includes(path.id));
      let reviewPaths = this.resumePaths.filter(path => cohortPaths.includes(path.id));
      console.log('resumePaths :', this.resumePaths);
      this.pathGroups?.forEach(group => {
        const matchingPaths = group.paths.filter(path => cohortPaths.includes(path.id));
        if (matchingPaths.length > 0) {
          const pathsWithCategory = matchingPaths.map(path => ({
            ...path,
            category: allpath.find(p => p.id === path.id).isPathCompleted == 1 ? 'Resumed' : allpath.find(p => p.id === path.id).isPathCompleted == 2 ? 'Review' : 'Normal'
            
          }));
            pathsWithCategory?.forEach((itemA) => {
      const matchedItem = group?.userGroups?.find((itemB) => itemB.pathId === itemA.id);
      if (matchedItem) {
        itemA.name = matchedItem.name;
      }
    });
          this.filteredPathsGroups.push({
            groupName: group.name,
            paths: pathsWithCategory
          });
        }
      });
    }
    setTimeout(() => {
      this.initSwipers();
    }, 10);
  }
  showSwiperButtons(val) {
    this.showSwiperBtn = val;
  }
  
  setRowHovered(index: number | string, hovered: boolean): void {
    this.rowHovered[index] = hovered;
  }
  
  initSwipers(): void {
   if (this.platform.is('desktop')) {
      this.slidesPerView = 3;
    }else if (this.platform.is('tablet')) {
      this.slidesPerView = 2;
    } 
    else {
      this.slidesPerView = 1.15;
   }
     if (this.swiperContainer?.nativeElement) {

         this.swiper = new Swiper(this.swiperContainer.nativeElement, {
          slidesPerView: this.slidesPerView,
          centeredSlides: false,
          spaceBetween: 5,
           freeMode: true,
          allowTouchMove:true,
           loop: false,
     
      navigation: {
        prevEl: '.custom-prev-icon',
        nextEl: '.custom-next-icon',
      },
    });
      }
    this.pathGroups.forEach((pathGroup, index) => {
      const swiperContainer = document.getElementById('swiperContainer_' + index);
  
      if (swiperContainer) {
        const swiperInstance = new Swiper(swiperContainer, {
          slidesPerView: this.slidesPerView,
          centeredSlides: false,
          spaceBetween: 5,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          loop: false, // Disable looping here
        });
  
        swiperInstance.on('slideChange', () => {
          // You can add custom logic if needed
        });
  
        this.swiperInstances['swiperContainer_' + index] = swiperInstance;
      }
    });
    this.swiperInitialized = true;
  }
  scrollToNext(index: number): void {
    const swiperContainerId = 'swiperContainer_' + index
    const swiperContainer = document.getElementById(swiperContainerId)
    if (swiperContainer) {
    this.swiperInstances[swiperContainerId].slideTo(this.swiperInstances[swiperContainerId]?.activeIndex+ (+this.swiperInstances[swiperContainerId]?.params?.slidesPerView));
    }
  }
  
  scrollToPrev(index: number): void {
    const swiperContainerId = 'swiperContainer_' + index
    const swiperContainer = document.getElementById(swiperContainerId)
    if (swiperContainer) {
      this.swiperInstances[swiperContainerId].slideTo(this.swiperInstances[swiperContainerId]?.activeIndex- (+this.swiperInstances[swiperContainerId]?.params?.slidesPerView));
    }
  }


  expandCollapse(index) {
    this.isExpanded[index] = !this.isExpanded[index]
  }
  toggleDescription(path: any): void {
    this.expandedPaths[path.id] = !this.expandedPaths[path.id];
  }
  
}
